.d-essent {
  display: none;
}

.d-ed {
  display: none;
}

.d-ew {
  display: none;
}

.d-essent-block {
  display: none;
}

.d-ed-block {
  display: none;
}

.d-ew-block {
  display: none;
}

@each $index, $value in $grid-breakpoints {
  .d-#{$index}-ed {
    display: none;
  }

  .d-#{$index}-essent {
    display: none;
  }

  .d-#{$index}-ew {
    display: none;
  }
}
