ngb-datepicker {
  &.dropdown-menu {
    border-radius: $border-radius-sm;
  }

  ngb-datepicker-navigation {
    padding: $spacing-xs;
    border-bottom: 1px solid $gray-200;
  }

  .ngb-dp-content {
    padding: $spacing-xs;
  }

  .ngb-dp-month-name {
    color: $black;
    font-size: $font-size-microcopy;
  }

  .ngb-dp-arrow-btn {
    font-size: $font-size-microcopy;
  }

  .ngb-dp-months {
    justify-content: center;
  }

  .ngb-dp-weekdays .ngb-dp-weekday {
    font-size: $font-size-microcopy;
    font-weight: $font-weight-light !important;
    text-transform: capitalize;
  }

  .ngb-dp-day,
  .ngb-dp-weekday,
  .ngb-dp-week-number {
    margin: 0.125rem;
  }

  .custom-day {
    padding: 0;
    border-radius: $border-radius-sm;
    color: $black !important;
    font-size: 16px;
    font-weight: 400;

    &:hover {
      outline: 1px solid $blue-500;
    }

    &:active {
      padding: 0 !important;
    }

    //this is used to mark selected items
    &.bg-primary {
      background: $blue-500 !important;
      color: $white !important;
    }

    &.hidden {
      color: $gray-500 !important;
    }

    // this is used to mark unselectable items
    &.text-body-secondary {
      color: $gray-200 !important;
      text-decoration: none;
    }
  }
}
