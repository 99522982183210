$shadow-sm:
  0 4px 14px rgba(0, 0, 0, 0.12),
  0 0.5px 0.5px rgba(0, 0, 0, 0.12);
$shadow-md:
  0 8px 28px rgba(0, 0, 0, 0.12),
  0 0.5px 0.5px rgba(0, 0, 0, 0.12);
$shadow-lg:
  0 16px 56px rgba(0, 0, 0, 0.12),
  0 0.5px 0.5px rgba(0, 0, 0, 0.12);
$shadow-default: $shadow-md;

$box-shadow-focus: 0 0 0 6px $color-focus;
