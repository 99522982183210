.hyphenate {
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
}

a,
.btn-link {
  text-underline-position: under;
}

.sidenote {
  color: $gray-500;
  font-size: 0.875rem;
}

.font-size-lg {
  font-size: $font-size-lg;
}

.font-size-sm {
  font-size: $font-size-sm;
}

p {
  overflow-wrap: break-word;
}

.text-brand-alt {
  color: $brand;
}
