@use 'sass:map';

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--headings-color);
}

@each $number, $fontSize in $display-mobile-font-sizes {
  .display-#{$number} {
    font-size: $fontSize;

    @include media-breakpoint-up(md) {
      font-size: map.get($display-font-sizes, $number);
    }
  }
}

@include media-breakpoint-down(sm) {
  h1,
  .h1 {
    font-size: $font-size-heading-xl-mobile;
  }
}

.h1-md {
  @extend %heading;
  @include media-breakpoint-up(md) {
    @include font-size($h1-font-size);
  }
}

.h2-md {
  @extend %heading;
  @include media-breakpoint-up(md) {
    @include font-size($h2-font-size);
  }
}

.h3-md {
  @extend %heading;
  @include media-breakpoint-up(md) {
    @include font-size($h3-font-size);
  }
}

.h4-md {
  @extend %heading;
  @include media-breakpoint-up(md) {
    @include font-size($h3-font-size);
  }
}

h4,
.h4,
h5,
.h5,
h6,
.h6 {
  margin-bottom: 0;
  line-height: $line-height-body;
}

h5,
h6,
.h5,
.h6 {
  color: var(--headings-color);
  line-height: $line-height-body;
}

// small
h5,
.h5 {
  font-size: $font-size-heading-small;
}

.lead,
.intro {
  @extend %intro;
}

.sidenote {
  color: $font-color-caption;
  line-height: $line-height-microcopy;
  font-size: $font-size-microcopy;
}

.microcopy {
  color: $font-body-color;
  line-height: $line-height-microcopy;
  font-size: $font-size-microcopy;
}

.caption {
  color: $font-color-caption;
  font-style: $font-style-caption;
  font-size: $font-size-caption;
}

// All display classes will be uppercase and have the FilsonSoft font
// (this font is set in stages in styles/core-scss/webfonts/_filson-soft.scss)
[class*=' display-'],
[class^='display-'] {
  text-transform: uppercase;
}

%a,
a:not(.btn:not(.btn-link)),
.btn-link {
  position: relative;
  border-radius: $border-radius-xs;
  font-weight: $font-weight-bold;
  text-decoration: underline;
  text-underline-position: auto;
  text-underline-offset: $link-underline-offset;

  &:focus {
    @include focus-ring($color-focus);
  }
}

@include media-breakpoint-down(sm) {
  .btn-link {
    @include btn-sm;
    padding: $spacing-sm;
  }
}

@include apply-inside-richtext() {
  .a,
  %a,
  a:not(.btn:not(.btn-link)),
  .btn-link {
    &:focus {
      @include focus-ring($color-focus);
    }
  }
}
