@use 'sass:list';

// Components
//
// Define common padding and border radius sizes and more.

$line-height-lg: 1.875 !default;
$line-height-sm: $line-height-base * 0.75 !default;

$border-width: 2px !default;
$border-color: $gray-200 !default;

$border-radius: $border-radius-default;

$rounded-pill: 50rem !default;

$box-shadow-sm: 0 0.125rem 0.25rem rgba($black, 0.075) !default;
$box-shadow: 0 2px 4px 0 rgba($black, 0.2) !default;
$box-shadow-lg: 0 1rem 3rem rgba($black, 0.175) !default;

$component-active-color: $white !default;
$component-active-bg: $brand !default;

$caret-width: 0.3em !default;
$caret-vertical-align: $caret-width * 0.85 !default;
$caret-spacing: $caret-width * 0.85 !default;

$transition-base: all 0.2s ease-in-out !default;
$transition-fade: opacity 0.15s linear !default;
$transition-collapse: height 0.35s ease !default;

$embed-responsive-aspect-ratios: () !default;
$embed-responsive-aspect-ratios: list.join(
  ((21 9), (16 9), (4 3), (1 1)),
  $embed-responsive-aspect-ratios
);

// Moved from Typography to solve circular reference
$hr-border-color: $border-color !default;
$hr-border-width: $border-width !default;
$hr-opacity: 0.9 !default;

$offer-highlight-height: 4rem;
