$accordion-padding-y: $spacing-md;
$accordion-padding-x: $spacing-lg;
$accordion-color: $brand-purple;
$accordion-bg: $body-bg;
$accordion-border-width: 0;
$accordion-border-color: rgba($black, 0.125);
$accordion-border-radius: $border-radius-default;
$accordion-inner-border-radius: subtract(
  $accordion-border-radius,
  $accordion-border-width
);

$accordion-body-padding-y: $accordion-padding-y;
$accordion-body-padding-x: $accordion-padding-x;

$accordion-button-padding-y: $accordion-padding-y;
$accordion-button-padding-x: $accordion-padding-x;
$accordion-button-color: $accordion-color;
$accordion-button-bg: $accordion-bg;
$accordion-transition:
  $btn-transition,
  border-radius 0.15s ease;
$accordion-button-active-bg: $accordion-bg;
$accordion-button-active-color: $accordion-color;

$accordion-button-focus-border-color: none;
$accordion-button-focus-box-shadow: none;

$accordion-icon-width: 1.25rem;
$accordion-icon-color: $primary;
$accordion-icon-color-light: $white !default;
$accordion-icon-active-color: $primary;
$accordion-icon-active-color-light: $white !default;
$accordion-icon-transition: transform 0.2s ease-in-out;
$accordion-icon-transform: rotate(-180deg);

$accordion-button-icon: escape-svg(
  url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='#{$accordion-icon-color}'><path d='M14 3.75V10.5H20.75C21.5469 10.5 22.25 11.2031 22.25 12C22.25 12.8438 21.5469 13.5 20.75 13.5H14V20.25C14 21.0938 13.2969 21.75 12.5 21.75C11.6562 21.75 11 21.0938 11 20.25V13.5H4.25C3.40625 13.5 2.75 12.8438 2.75 12C2.75 11.2031 3.40625 10.5 4.25 10.5H11V3.75C11 2.95312 11.6562 2.25 12.5 2.25C13.2969 2.25 14 2.95312 14 3.75Z'/></svg>")
) !default;
$accordion-button-active-icon: escape-svg(
  url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='#{$accordion-icon-active-color}'><path d='M22.25 12C22.25 12.8438 21.5469 13.5 20.75 13.5H4.25C3.40625 13.5 2.75 12.8438 2.75 12C2.75 11.2031 3.40625 10.5 4.25 10.5H20.75C21.5469 10.5 22.25 11.2031 22.25 12Z'/></svg>")
) !default;

// Added this light icon because 'fill' via CSS does not work as svg is set via background-image... It is part of the .accordion-header--dark class
$accordion-button-icon-light: escape-svg(
  url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='#{$accordion-icon-color-light}'><path d='M14 3.75V10.5H20.75C21.5469 10.5 22.25 11.2031 22.25 12C22.25 12.8438 21.5469 13.5 20.75 13.5H14V20.25C14 21.0938 13.2969 21.75 12.5 21.75C11.6562 21.75 11 21.0938 11 20.25V13.5H4.25C3.40625 13.5 2.75 12.8438 2.75 12C2.75 11.2031 3.40625 10.5 4.25 10.5H11V3.75C11 2.95312 11.6562 2.25 12.5 2.25C13.2969 2.25 14 2.95312 14 3.75Z'/></svg>")
) !default;
$accordion-button-active-icon-light: escape-svg(
  url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='#{$accordion-icon-active-color-light}'><path d='M22.25 12C22.25 12.8438 21.5469 13.5 20.75 13.5H4.25C3.40625 13.5 2.75 12.8438 2.75 12C2.75 11.2031 3.40625 10.5 4.25 10.5H20.75C21.5469 10.5 22.25 11.2031 22.25 12Z'/></svg>")
) !default;
