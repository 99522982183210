.mijn {
  background: $light;
}

.body-disable-scrolling {
  overflow: hidden;
}

.body-disable-scrolling-md {
  @include media-breakpoint-down(md) {
    overflow: hidden;
  }
}

.body-disable-scrolling-sm {
  @include media-breakpoint-down(sm) {
    overflow: hidden;
  }
}

body {
  overflow-x: hidden;
}
