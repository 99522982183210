@mixin filson-soft-font($base-url: '') {
  @font-face {
    font-family: FilsonSoftSubset;
    font-style: normal;
    font-weight: 800;
    src:
      url('#{$base-url}/assets/core/webfonts/filson-soft/FilsonSoft-Black-subset.woff2')
        format('woff2'),
      url('#{$base-url}/assets/core/webfonts/filson-soft/FilsonSoft-Black-subset.zopfli.woff')
        format('woff');
    unicode-range:
      U+A, U+20-23, U+25-3B, U+3D, U+3F-50, U+52-57, U+59-5B, U+5D, U+61-70,
      U+72-7B, U+7D, U+A0, U+A9, U+B3, U+C9, U+D3, U+E9, U+EB, U+EF, U+F3,
      U+2018, U+2019, U+20AC, U+E800;
  }

  @font-face {
    font-family: FilsonSoft;
    font-style: normal;
    font-weight: 800;
    font-display: swap;
    src: url('#{$base-url}/assets/webfonts/filson-soft/FilsonSoft-Black.woff2')
      format('woff2');
  }

  .fonts-stage-1 {
    [class*=' display-'],
    [class^='display-'] {
      font-family: FilsonSoftSubset, Tahoma, Verdana, sans-serif;
    }
  }

  .fonts-stage-2 {
    [class*=' display-'],
    [class^='display-'] {
      font-family: FilsonSoft, Tahoma, Verdana, sans-serif;
    }
  }
}
