.ng-failed-lazyloaded {
  display: none;
}

figure.image-cover > img,
img.image-cover {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

@include apply-inside-richtext() {
  img {
    max-width: 100%;
  }
}
