.icon--danger {
  color: $danger;
}

/*
  This class is used to show an icon as a button. The class has to be placed on the
  wrapper around a fa-icon component, e.g.:

  <button class="icon-button">
    <fa-icon icon="fa-close" />
  </button>

  or

  <a href="" class="icon-button">
    <fa-icon icon="fa-close" />
  </a>
*/
.icon-button {
  width: $spacer * 2;
  height: $spacer * 2;
  background-color: $primary;
  border-radius: 50%;
  color: $white;
  cursor: pointer;
}

.icon-sm {
  font-size: $icon-size-sm;
}

.icon-md {
  font-size: $icon-size-md;
}

.icon-lg {
  font-size: $icon-size-lg;
}

.icon-md-md {
  @include media-breakpoint-up(md) {
    font-size: $icon-size-md;
  }
}

.icon-lg-md {
  @include media-breakpoint-up(md) {
    font-size: $icon-size-lg;
  }
}
