/* stylelint-disable selector-no-qualifying-type,property-no-vendor-prefix */
/* Disable number input spinners on desktop */
input[type='number'] {
  -moz-appearance: textfield; /* Firefox */

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &::-ms-clear {
    display: none;
  }
}

ol,
ul {
  padding-inline-start: 40px; // override bootstrap padding left and set to browser default
}
