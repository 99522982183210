$box-shadow-width: 2px;

%btn,
.btn {
  @include btn;

  &:not(:disabled):not(.disabled) {
    cursor: if($enable-button-pointers, pointer, null);
  }

  &.btn-link {
    color: $link-color;
    font-size: $font-size-base;
    font-weight: $font-weight-bold;
    text-decoration: underline;

    &.back-toggle {
      padding: $spacer * 0.5 $spacer;
      background-color: $white;
      border-width: $border-width;
      border-radius: $border-radius;
      box-shadow:
        0 $box-shadow-width 4px 0 rgba($black, 0.2),
        0 3px 0 0 rgba($black, 0.05);
      color: $black;

      text-decoration: none;

      &:hover {
        top: 0;
      }
    }

    &:hover {
      color: $link-hover-color;
    }

    &:active {
      box-shadow: none !important;
    }
  }
}

@each $theme, $theme-map in $button-theme-colors {
  .btn-#{$theme} {
    @include ed-button($theme-map);
  }
}

.btn.btn-icon {
  display: flex;
  width: 2.875rem;
  height: 2.875rem;
  padding: $spacing-sm;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
}

.btn.btn-sm {
  @include btn-sm;
}

.btn-block {
  display: block;
  width: 100%;
}

.ed-header-button {
  @include ed-header-button;
}
