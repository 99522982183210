// Modals

// Padding applied to the modal body
$modal-inner-padding: 0 ($spacer * 2) ($spacer * 2) !default;

// Margin between elements in footer, must be lower than or equal to 2 * $modal-inner-padding
$modal-footer-margin-between: 0.5rem !default;

$modal-dialog-margin: $spacer * 0.5 !default;
$modal-dialog-margin-y-sm-up: $spacer * 11 !default;

$modal-title-line-height: 1.5 !default;

$modal-content-color: $gray-700 !default;
$modal-content-bg: $white !default;
$modal-content-border-color: rgba($black, 0.2) !default;
$modal-content-border-width: 0 !default;
$modal-content-border-radius: $border-radius-default !default;
$modal-content-inner-border-radius: subtract(
  $modal-content-border-radius,
  $modal-content-border-width
) !default;
$modal-content-box-shadow-xs: none !default;
$modal-content-box-shadow-sm-up: none !default;

$modal-backdrop-bg: $black !default;
$modal-backdrop-opacity: 0.6 !default;
$modal-header-border-color: $gray-200 !default;
$modal-footer-border-color: $modal-header-border-color !default;
$modal-header-border-width: 0 !default;
$modal-footer-border-width: $modal-header-border-width !default;
$modal-header-padding-y: $spacer * 2 !default;
$modal-header-padding-x: $spacer * 2 !default;
$modal-header-padding: $modal-header-padding-y $modal-header-padding-x
  ($spacer * 1.35) !default; // Keep this for backwards compatibility

$modal-xl: 1140px !default;
$modal-lg: 800px !default;
$modal-md: 500px !default;
$modal-sm: 300px !default;

$modal-fade-transform: translate(0, -50px) !default;
$modal-show-transform: none !default;
$modal-transition: transform 0.3s ease-out !default;
$modal-scale-transform: scale(1.02) !default;
