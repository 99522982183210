.d-ed {
  display: inline-block;
}

.d-ed-block {
  display: block;
}

@each $index, $value in $grid-breakpoints {
  @media (min-width: $value) {
    .d-#{$index}-ed {
      display: inline-block;
    }
  }
}
