// Progress

$progress-height: 1rem;
$progress-font-size: $font-size-base * 0.75;
$progress-bg: $white;
$progress-border-radius: $border-radius-lg;
$progress-box-shadow: none;
$progress-bar-color: $white;
$progress-bar-bg: $brand;
$progress-bar-animation-timing: 1s linear infinite;
$progress-bar-transition: width 0.6s ease;

.progress {
  border: $border-width solid $border-color;
}
