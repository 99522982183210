@use 'sass:map';

// Buttons
//
// For each of Bootstrap's buttons, define text, background, and border color.

$btn-padding-y: 0.75rem !default;
$btn-padding-x: 1.25rem !default;
$btn-font-family: $input-btn-font-family !default;
$btn-font-size: $font-size-base !default;
$btn-line-height: $input-btn-line-height !default;
$btn-white-space: null !default; // Set to `nowrap` to prevent text wrapping

$btn-border-width: 0 !default;

$btn-font-weight: $font-weight-bold !default;
$btn-box-shadow: none !default;
$btn-focus-width: $input-btn-focus-width !default;
$btn-focus-box-shadow: $input-btn-focus-box-shadow !default;
$btn-disabled-opacity: 1 !default;
$btn-active-box-shadow: inset 0 3px 5px rgba($black, 0.125) !default;

$btn-link-disabled-color: $gray-600 !default;

$btn-block-spacing-y: 0.5rem !default;

// Allows for customizing button radius independently from global border radius
$btn-border-radius: 50rem !default;
$btn-border-radius-lg: 50rem !default;
$btn-border-radius-sm: 50rem !default;

$btn-transition: null !default;

// Pin icon for sales buttons
$sales-btn-icon: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'><path fill='currentColor' d='M22.5312 13.0781L15.0312 20.5781C14.4688 21.1875 13.4844 21.1875 12.9219 20.5781C12.3125 20.0156 12.3125 19.0312 12.9219 18.4688L17.8438 13.5H3.5C2.65625 13.5 2 12.8438 2 12C2 11.2031 2.65625 10.5 3.5 10.5H17.8438L12.9219 5.57812C12.3125 5.01562 12.3125 4.03125 12.9219 3.46875C13.4844 2.85938 14.4688 2.85938 15.0312 3.46875L22.5312 10.9688C23.1406 11.5312 23.1406 12.5156 22.5312 13.0781Z'/></svg>") !default;

// Declare map for buttons. If you need to roll out new button styles, you can add them here.
$button-theme-colors: () !default;
$button-theme-colors: map.merge(
  (
    'primary': (
      'background': $blue,
      'background:hover': $blue-700,
      'text-color': $white,
    ),
    // Added to be backwards-compatible with Essent's 'outline-primary', actually equal to secondary.
    'outline-primary': (
        'background': $white,
        'background:hover': $blue,
        'background:disabled': $white,
        'border': $blue,
        'text-color': $blue,
      ),
    'sales-primary': (
      'background': $yellow-500,
      'background:hover': $yellow-300,
      'icon': $sales-btn-icon,
      'text-color': $black,
    ),
    'secondary': (
      'background': $white,
      'background:hover': $blue,
      'background:disabled': $white,
      'border': $blue,
      'text-color': $blue,
    ),
    // Added to be backwards-compatible with Essent's 'outline-secondary', actually secondary.
    'outline-secondary': (
        'background': $white,
        'background:hover': $blue,
        'background:disabled': $white,
        'border': $blue,
        'text-color': $blue,
      ),
    'sales-secondary': (
      'background': $white,
      'background:hover': $yellow-500,
      'background:disabled': $white,
      'border': $yellow-500,
      'icon': $sales-btn-icon,
    ),
    'tertiary': (
      'background': transparent,
      'background:hover': $btn-tertiary-hover,
      'background:disabled': transparent,
      'text-color': var(--link-color),
      'text-color:hover': var(--link-color),
      'text-decoration': underline,
    ),
    'tertiary-white': (
      'background': transparent,
      'background:hover': $btn-tertiary-white-hover,
      'background:disabled': transparent,
      'focus-ring': $btn-tertiary-white-focus-ring,
      'text-color': $white,
      'text-decoration': underline,
    ),
    'navigation': (
      'background': $white,
      'background:hover': $blue-700,
      'background:disabled': $white,
      'border': $blue,
      'text-color': $blue,
    ),
  ),
  $button-theme-colors
);
