.ng-select {
  .ng-select-container {
    padding: $spacer * 0.75 $spacer * 3 $spacer * 0.61 0;
    border-color: $input-border-color;
    border-style: solid;
    border-width: $input-border-width;
    border-radius: $input-border-radius;

    &:hover {
      cursor: pointer;
    }
  }

  .ng-value {
    color: $black;
  }

  &.ng-select--icon {
    .ng-select-container {
      padding-left: $spacer * 1.6;
    }
  }

  .ng-dropdown-panel {
    z-index: $zindex-dropdown;
  }

  .ng-dropdown-panel-items {
    background-color: $white;
    border-color: $input-border-color;
    border-style: solid;
    border-width: $input-border-width;
    border-radius: $border-radius;

    &.scroll-host {
      overflow-y: auto;
      max-height: $spacer * 20;
    }
  }

  .ng-option {
    padding: $spacer * 0.75 $spacer * 3 $spacer * 0.75 $spacer;

    &.ng-option-marked {
      background-color: $primary;
      color: $white;
      outline: 0;
      cursor: pointer;
    }

    &.ng-option-selected {
      font-weight: $font-weight-black;
    }
  }

  .ng-value,
  .ng-placeholder {
    margin-left: $spacer;
  }

  &-clearable {
    .ng-value,
    .ng-placeholder {
      max-width: calc(100% - 5 * #{$spacer});
    }
  }

  &.ng-select-opened {
    .ng-select-container {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }

    .ng-dropdown-panel-items {
      top: -$input-border-width * 2 !important;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      box-shadow: $dropdown-box-shadow;
    }
  }

  &.is-valid {
    .ng-select-container {
      border-bottom-color: $success;
      border-bottom-width: $input-border-validation-width;
    }

    &.ng-select-opened {
      .ng-dropdown-panel {
        border-top-color: $success;
        border-top-width: $input-border-validation-width;
      }
    }
  }

  &.is-invalid {
    .ng-select-container {
      border-bottom-color: $danger;
      border-bottom-width: $input-border-validation-width;
    }

    &.ng-select-opened {
      .ng-dropdown-panel {
        border-top-color: $danger;
        border-top-width: $input-border-validation-width;
      }
    }
  }
}

.form-check,
.form-radio {
  input:focus {
    border-color: $input-focus-border-color;
  }
}

.input-group input:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group input:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group :not(:first-child) {
  input:not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.input-group-text {
  justify-content: center;
}
