// Pagination

$pagination-padding-y: $spacer !default;
$pagination-padding-x: $spacer !default;
$pagination-padding-y-sm: $spacer * 0.75 !default;
$pagination-padding-x-sm: $spacer * 0.75 !default;
$pagination-padding-y-lg: $spacer * 1.25 !default;
$pagination-padding-x-lg: $spacer * 1.25 !default;
$pagination-line-height: 1.25 !default;

$pagination-color: $dark !default;
$pagination-bg: $white !default;
$pagination-border-width: $border-width !default;
$pagination-border-color: transparent !default;

$pagination-focus-box-shadow: $input-btn-focus-box-shadow !default;
$pagination-focus-outline: 0 !default;

$pagination-hover-color: $white !default;
$pagination-hover-bg: $gray-500 !default;
$pagination-hover-border-color: $gray-500 !default;

$pagination-active-color: $white !default;
$pagination-active-bg: $gray-500 !default;
$pagination-active-border-color: $pagination-active-bg !default;

$pagination-disabled-color: $gray-400 !default;
$pagination-disabled-bg: $white !default;
$pagination-disabled-border-color: $pagination-border-color !default;

$pagination-border-radius-sm: $border-radius-sm !default;
$pagination-border-radius-lg: $border-radius-lg !default;
