.btn {
  line-height: $input-btn-line-height;

  &.btn {
    &-ghost {
      padding: ($spacer * 0.5) $spacer;
      border-color: $gray-400;
      border-width: 1px;
      color: $gray-500;
      font-size: $font-size-base;
      font-weight: $font-weight-normal;

      &:focus {
        border-color: $brand;
        box-shadow: none;
      }

      &:hover,
      &:active {
        background-color: $gray-400;
        border-color: $gray-400;
        color: $white;
      }
    }
  }

  &.btn-link {
    box-shadow: none;
  }
}

.btn.toggle-button {
  border-radius: $spacer * 4;

  &.btn-outline-primary {
    &:active:not(:disabled),
    &:focus:not(:disabled),
    &:hover:not(:disabled) {
      background-color: transparent;
      border-color: $primary;
      color: $primary;
    }
  }

  &.btn-outline-primary.active {
    &:active:not(:disabled),
    &:focus:not(:disabled),
    &:hover:not(:disabled) {
      background-color: $primary;
      border-color: $primary;
      color: $white;
    }
  }
}
