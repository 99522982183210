@each $index, $value in $spacers {
  .ms-ed-#{$index} {
    margin-left: $value;
  }

  .mx-ed-#{$index} {
    margin: {
      right: $value;
      left: $value;
    }
  }

  .my-ed-#{$index} {
    margin: {
      top: $value;
      bottom: $value;
    }
  }
}
