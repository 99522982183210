.form-floating > input:focus + label {
  color: $form-floating-label-color;
  font-weight: $form-floating-label-font-weight;
}

.form-floating > :not(input:placeholder-shown) + label {
  color: $form-floating-label-color;
  font-weight: $form-floating-label-font-weight;
}

.form-floating > label {
  margin-bottom: unset;
  padding: $spacer 0 $spacer $spacer;
  color: $input-placeholder-color;
  line-height: $form-floating-line-height;
  font-weight: $font-weight-normal;
}

.form-floating > .form-control:focus {
  ~ label {
    padding: $spacer 0 $spacer $spacer;
  }
}

.form-floating > .form-control ~ label::after {
  background-color: transparent !important;
}

.form-floating > .form-control:focus {
  &::placeholder {
    color: $input-placeholder-color;
  }
}

.form-check-label {
  margin-bottom: 0;
}

label {
  margin-bottom: $label-margin-bottom;
  color: $label-color;
  font-weight: $label-font-weight;
}
