@use 'sass:math';
@use 'sass:map';

// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

$spacer: $spacing-sm !default;
$spacers: (
  0: 0,
  // 0.5rem
  1: $spacing-xs,
  // 1rem
  2: $spacing-sm,
  // 1.5rem
  3: $spacing-md,
  // 2rem
  4: $spacing-lg,
  // 2.5rem
  5: $spacing-xl,
  // 3rem
  6: $spacing-md + $spacing-md,
  // 4rem
  8: $spacing-lg + $spacing-lg,
  // 5rem
  10: $spacing-xl + $spacing-xl,
);

// This variable affects the `.h-*` and `.w-*` classes.
$sizes: () !default;
$sizes: map.merge(
  (
    25: 25%,
    30: 30%,
    50: 50%,
    75: 75%,
    90: 90%,
    100: 100%,
    auto: auto,
  ),
  $sizes
);
