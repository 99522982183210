.accordion-item {
  --accordion-color: #{$black};
  --link-color: #{$blue-500};
  background-color: $white;
  // Recreated left, right and bottom shadow to eliminate top shadow blur
  // which overflows in body of previous accordion-item
  box-shadow:
    -16px 8px 8px rgba(0, 0, 0, 0.01),
    16px 8px 8px rgba(0, 0, 0, 0.01),
    0 32px 32px rgba(0, 0, 0, 0.05),
    0 0.5px 0.5px rgba(0, 0, 0, 0.12);

  &--dark-header {
    --accordion-bg: #{$brand-purple};
    --accordion-btn-bg: #{$brand-purple};
    --accordion-btn-color: #{$white};
    --accordion-active-color: #{$white};
    --accordion-active-bg: #{$brand-purple};
  }

  // Annoying override that is the only way to ensure this styling.
  // Hope encapsulating styles will solve this sooner or later.
  &--light-header {
    .accordion-button {
      color: $brand-purple;
    }
  }

  &:first-of-type {
    border-top-left-radius: var(--accordion-border-radius) !important;
    border-top-right-radius: var(--accordion-border-radius) !important;
    box-shadow: $shadow-default;
  }

  &:last-of-type {
    border-bottom-right-radius: var(--accordion-border-radius) !important;
    border-bottom-left-radius: var(--accordion-border-radius) !important;
  }

  &:not(:last-of-type) {
    border-bottom: $border-width solid $color-item-divider;
  }

  .accordion-button {
    @extend %heading;
    margin-bottom: 0;
    line-height: $line-height-microcopy;
    font-size: $font-size-heading-small;
    text-decoration: none;

    &:focus {
      @include focus-ring($color-focus, 0);
    }

    /* stylelint-disable-next-line order/order */
    @include media-breakpoint-down(md) {
      --accordion-btn-padding-x: #{$spacing-sm};
      font-size: $font-size-base;
    }
  }

  .accordion-title {
    font-weight: bold;
    text-decoration: none;
  }

  .collapsing,
  .collapse,
  .show {
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit;
  }

  .accordion-body {
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit;
  }

  &--transparent {
    border-bottom: 0;
  }
}
