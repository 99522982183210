@charset "UTF-8";
@font-face {
  font-family: OpenSansSubset;
  font-style: normal;
  font-weight: 400;
  src: url("/dist/energiedirect/browser/assets/webfonts/open-sans/OpenSans-Regular-subset.woff2") format("woff2"), url("/dist/energiedirect/browser/assets/webfonts/open-sans/OpenSans-Regular-subset.zopfli.woff") format("woff");
  unicode-range: U+20-23, U+26-29, U+2C-2E, U+30-3A, U+3E, U+3F, U+41-50, U+52-57, U+5A, U+61-7A, U+A0, U+A9, U+E9, U+EB, U+EF, U+2018, U+2019, U+2026, U+20AC, U+2122;
}
@font-face {
  font-family: "OpenSans";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("/dist/energiedirect/browser/assets/webfonts/open-sans/OpenSans-Light.woff2") format("woff2"), url("/dist/energiedirect/browser/assets/webfonts/open-sans/OpenSans-Light.woff") format("woff");
}
@font-face {
  font-family: "OpenSans";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("/dist/energiedirect/browser/assets/webfonts/open-sans/OpenSans-Regular.woff2") format("woff2"), url("/dist/energiedirect/browser/assets/webfonts/open-sans/OpenSans-Regular.woff") format("woff");
}
@font-face {
  font-family: "OpenSans";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("/dist/energiedirect/browser/assets/webfonts/open-sans/OpenSans-Bold.woff2") format("woff2"), url("/dist/energiedirect/browser/assets/webfonts/open-sans/OpenSans-Bold.woff") format("woff");
}
@font-face {
  font-family: "OpenSans";
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url("/dist/energiedirect/browser/assets/webfonts/open-sans/OpenSans-ExtraBold.woff2") format("woff2"), url("/dist/energiedirect/browser/assets/webfonts/open-sans/OpenSans-ExtraBold.woff") format("woff");
}
.fonts-stage-1 body {
  font-family: OpenSansSubset, sans-serif;
  font-style: normal;
  font-weight: 400;
}

.fonts-stage-2 body {
  font-family: OpenSans, sans-serif;
}

@font-face {
  font-family: "fontello-light";
  font-style: normal;
  font-weight: normal;
  src: url("/dist/energiedirect/browser/assets/webfonts/fontello/fontello-light.eot");
  src: url("/dist/energiedirect/browser/assets/webfonts/fontello/fontello-light.eot#iefix") format("embedded-opentype"), url("/dist/energiedirect/browser/assets/webfonts/fontello/fontello-light.woff2") format("woff2"), url("/dist/energiedirect/browser/assets/webfonts/fontello/fontello-light.woff") format("woff"), url("/dist/energiedirect/browser/assets/webfonts/fontello/fontello-light.ttf") format("truetype"), url("/dist/energiedirect/browser/assets/webfonts/fontello/fontello-light.svg#fontello") format("svg");
}
@font-face {
  font-family: "fontello-solid";
  font-style: normal;
  font-weight: normal;
  src: url("/dist/energiedirect/browser/assets/webfonts/fontello/fontello-solid.eot");
  src: url("/dist/energiedirect/browser/assets/webfonts/fontello/fontello-solid.eot#iefix") format("embedded-opentype"), url("/dist/energiedirect/browser/assets/webfonts/fontello/fontello-solid.woff2") format("woff2"), url("/dist/energiedirect/browser/assets/webfonts/fontello/fontello-solid.woff") format("woff"), url("/dist/energiedirect/browser/assets/webfonts/fontello/fontello-solid.ttf") format("truetype"), url("/dist/energiedirect/browser/assets/webfonts/fontello/fontello-solid.svg#fontello") format("svg");
}
@font-face {
  font-family: FilsonSoftSubset;
  font-style: normal;
  font-weight: 800;
  src: url("/dist/energiedirect/browser/assets/core/webfonts/filson-soft/FilsonSoft-Black-subset.woff2") format("woff2"), url("/dist/energiedirect/browser/assets/core/webfonts/filson-soft/FilsonSoft-Black-subset.zopfli.woff") format("woff");
  unicode-range: U+A, U+20-23, U+25-3B, U+3D, U+3F-50, U+52-57, U+59-5B, U+5D, U+61-70, U+72-7B, U+7D, U+A0, U+A9, U+B3, U+C9, U+D3, U+E9, U+EB, U+EF, U+F3, U+2018, U+2019, U+20AC, U+E800;
}
@font-face {
  font-family: FilsonSoft;
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url("/dist/energiedirect/browser/assets/webfonts/filson-soft/FilsonSoft-Black.woff2") format("woff2");
}
.fonts-stage-1 [class*=" display-"],
.fonts-stage-1 [class^=display-] {
  font-family: FilsonSoftSubset, Tahoma, Verdana, sans-serif;
}

.fonts-stage-2 [class*=" display-"],
.fonts-stage-2 [class^=display-] {
  font-family: FilsonSoft, Tahoma, Verdana, sans-serif;
}

:root {
  --font-intro-color: #000;
}

:root {
  --headings-color: #410073;
}

.lead,
.intro {
  margin-bottom: 0.5rem;
  color: var(--font-intro-color);
  line-height: 2;
  font-size: 1rem;
  font-weight: 400;
}

.progress {
  border: 2px solid #c6c6c6;
}

:root,
[data-bs-theme=light] {
  --blue: #1e76ce;
  --green: #5fb624;
  --purple: #410073;
  --yellow: #ffc800;
  --red: #c41a1a;
  --gray: #c6c6c6;
  --gray-dark: #797979;
  --gray-100: #d8d8d8;
  --gray-200: #c6c6c6;
  --gray-300: #ababab;
  --gray-400: #9b9b9b;
  --gray-500: #797979;
  --gray-600: #767676;
  --gray-700: #5c5c5c;
  --gray-800: #484848;
  --gray-900: #373737;
  --brand: #5fb624;
  --brand-alt: #410073;
  --brand-purple: #410073;
  --primary: #1e76ce;
  --secondary: #c6c6c6;
  --success: #43811a;
  --info: #1e76ce;
  --warning: #ffa800;
  --danger: #c41a1a;
  --light: #f3f3f3;
  --dark: #797979;
  --brand-rgb: 95, 182, 36;
  --brand-alt-rgb: 65, 0, 115;
  --brand-purple-rgb: 65, 0, 115;
  --primary-rgb: 30, 118, 206;
  --secondary-rgb: 198, 198, 198;
  --success-rgb: 67, 129, 26;
  --info-rgb: 30, 118, 206;
  --warning-rgb: 255, 168, 0;
  --danger-rgb: 196, 26, 26;
  --light-rgb: 243, 243, 243;
  --dark-rgb: 121, 121, 121;
  --primary-text-emphasis: #0c2f52;
  --secondary-text-emphasis: #4f4f4f;
  --success-text-emphasis: #1b340a;
  --info-text-emphasis: #0c2f52;
  --warning-text-emphasis: #664300;
  --danger-text-emphasis: #4e0a0a;
  --light-text-emphasis: #5c5c5c;
  --dark-text-emphasis: #5c5c5c;
  --primary-bg-subtle: #d2e4f5;
  --secondary-bg-subtle: #f4f4f4;
  --success-bg-subtle: #d9e6d1;
  --info-bg-subtle: #d2e4f5;
  --warning-bg-subtle: #ffeecc;
  --danger-bg-subtle: #f3d1d1;
  --light-bg-subtle: #ececec;
  --dark-bg-subtle: #9b9b9b;
  --primary-border-subtle: #a5c8eb;
  --secondary-border-subtle: #e8e8e8;
  --success-border-subtle: #b4cda3;
  --info-border-subtle: #a5c8eb;
  --warning-border-subtle: #ffdc99;
  --danger-border-subtle: #e7a3a3;
  --light-border-subtle: #c6c6c6;
  --dark-border-subtle: #797979;
  --white-rgb: 255, 255, 255;
  --black-rgb: 0, 0, 0;
  --font-sans-serif: OpenSans, sans-serif;
  --font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --body-font-family: OpenSans, sans-serif;
  --body-font-size: 1rem;
  --body-font-weight: 400;
  --body-line-height: 2;
  --body-color: #000;
  --body-color-rgb: 0, 0, 0;
  --body-bg: #fff;
  --body-bg-rgb: 255, 255, 255;
  --emphasis-color: #000;
  --emphasis-color-rgb: 0, 0, 0;
  --secondary-color: #797979;
  --secondary-color-rgb: 121, 121, 121;
  --secondary-bg: #c6c6c6;
  --secondary-bg-rgb: 198, 198, 198;
  --tertiary-color: rgba(0, 0, 0, 0.5);
  --tertiary-color-rgb: 0, 0, 0;
  --tertiary-bg: #d8d8d8;
  --tertiary-bg-rgb: 216, 216, 216;
  --heading-color: #410073;
  --link-color: #1e76ce;
  --link-color-rgb: 30, 118, 206;
  --link-decoration: underline;
  --link-hover-color: #1e76ce;
  --link-hover-color-rgb: 30, 118, 206;
  --link-hover-decoration: underline;
  --code-color: #5fb624;
  --highlight-color: #000;
  --highlight-bg: #ffda54;
  --border-width: 2px;
  --border-style: solid;
  --border-color: #c6c6c6;
  --border-color-translucent: rgba(0, 0, 0, 0.175);
  --border-radius: 1.5rem;
  --border-radius-sm: 0.5rem;
  --border-radius-lg: 1.5rem;
  --border-radius-xl: 2rem;
  --border-radius-xxl: 2rem;
  --border-radius-2xl: var(--border-radius-xxl);
  --border-radius-pill: 50rem;
  --box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  --box-shadow-sm: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  --box-shadow-lg: 0 1rem 3rem rgba(0, 0, 0, 0.175);
  --box-shadow-inset: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  --focus-ring-width: 0.25rem;
  --focus-ring-opacity: 0.25;
  --focus-ring-color: rgba(30, 118, 206, 0.25);
  --form-valid-color: #43811a;
  --form-valid-border-color: #43811a;
  --form-invalid-color: #c41a1a;
  --form-invalid-border-color: #c41a1a;
}

[data-bs-theme=dark] {
  color-scheme: dark;
  --body-color: #ababab;
  --body-color-rgb: 171, 171, 171;
  --body-bg: #373737;
  --body-bg-rgb: 55, 55, 55;
  --emphasis-color: #fff;
  --emphasis-color-rgb: 255, 255, 255;
  --secondary-color: rgba(171, 171, 171, 0.75);
  --secondary-color-rgb: 171, 171, 171;
  --secondary-bg: #484848;
  --secondary-bg-rgb: 72, 72, 72;
  --tertiary-color: rgba(171, 171, 171, 0.5);
  --tertiary-color-rgb: 171, 171, 171;
  --tertiary-bg: #404040;
  --tertiary-bg-rgb: 64, 64, 64;
  --primary-text-emphasis: #78ade2;
  --secondary-text-emphasis: #dddddd;
  --success-text-emphasis: #8eb376;
  --info-text-emphasis: #78ade2;
  --warning-text-emphasis: #ffcb66;
  --danger-text-emphasis: #dc7676;
  --light-text-emphasis: #d8d8d8;
  --dark-text-emphasis: #ababab;
  --primary-bg-subtle: #061829;
  --secondary-bg-subtle: #282828;
  --success-bg-subtle: #0d1a05;
  --info-bg-subtle: #061829;
  --warning-bg-subtle: #332200;
  --danger-bg-subtle: #270505;
  --light-bg-subtle: #484848;
  --dark-bg-subtle: #242424;
  --primary-border-subtle: #12477c;
  --secondary-border-subtle: #777777;
  --success-border-subtle: #284d10;
  --info-border-subtle: #12477c;
  --warning-border-subtle: #996500;
  --danger-border-subtle: #761010;
  --light-border-subtle: #5c5c5c;
  --dark-border-subtle: #484848;
  --heading-color: inherit;
  --link-color: #78ade2;
  --link-hover-color: #93bde8;
  --link-color-rgb: 120, 173, 226;
  --link-hover-color-rgb: 147, 189, 232;
  --code-color: #9fd37c;
  --highlight-color: #ababab;
  --highlight-bg: #8c6e00;
  --border-color: #5c5c5c;
  --border-color-translucent: rgba(255, 255, 255, 0.15);
  --form-valid-color: #75b798;
  --form-valid-border-color: #75b798;
  --form-invalid-color: #d76666;
  --form-invalid-border-color: #d76666;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  font-family: var(--body-font-family);
  font-size: var(--body-font-size);
  font-weight: var(--body-font-weight);
  line-height: var(--body-line-height);
  color: var(--body-color);
  text-align: var(--body-text-align);
  background-color: var(--body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

hr {
  margin: 1rem 0;
  color: inherit;
  border: 0;
  border-top: 2px solid #c6c6c6;
  opacity: 0.9;
}

.h4-md, .h3-md, .h2-md, .h1-md, .accordion-item .accordion-button, h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 800;
  line-height: 1.25;
  color: var(--heading-color);
}

h1, .h1 {
  font-size: 3rem;
}

h2, .h2 {
  font-size: 2rem;
}

h3, .h3 {
  font-size: 1.75rem;
}

h4, .h4 {
  font-size: 1.25rem;
}

h5, .h5 {
  font-size: 1rem;
}

h6, .h6 {
  font-size: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: 700;
}

small, .small {
  font-size: 0.875rem;
}

mark, .mark {
  padding: 0.1875em;
  color: var(--highlight-color);
  background-color: var(--highlight-bg);
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: rgba(var(--link-color-rgb), var(--link-opacity, 1));
  text-decoration: underline;
}
a:hover {
  --link-color-rgb: var(--link-hover-color-rgb);
  text-decoration: underline;
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--font-monospace);
  font-size: 1em;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 87.5%;
  color: #373737;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 87.5%;
  color: var(--code-color);
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #373737;
  border-radius: 0.5rem;
}
kbd kbd {
  padding: 0;
  font-size: 1em;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #797979;
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}
select:disabled {
  opacity: 1;
}

[list]:not([type=date]):not([type=datetime-local]):not([type=month]):not([type=week]):not([type=time])::-webkit-calendar-picker-indicator {
  display: none !important;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  line-height: inherit;
  font-size: 1.5rem;
}
legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type=search] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: 1.25rem;
  font-weight: 400;
}

.display-1 {
  font-family: FilsonSoft, Tahoma, Verdana, sans-serif;
  font-weight: 800;
  line-height: 0.89285;
  font-size: 3.5rem;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 0.875rem;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}
.blockquote > :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875rem;
  color: #767676;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 2px solid #ababab;
  border-radius: 1.5rem;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.075);
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 0.875rem;
  color: #767676;
}

.container,
.container-fluid,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  --gutter-x: 2rem;
  --gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--gutter-x) * 0.5);
  padding-left: calc(var(--gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 540px) {
  .container-sm, .container {
    max-width: 576px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 768px;
  }
}
@media (min-width: 960px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 992px;
  }
}
@media (min-width: 1140px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1200px;
  }
}
:root {
  --breakpoint-xs: 0;
  --breakpoint-sm: 540px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 960px;
  --breakpoint-xl: 1140px;
}

.row {
  --gutter-x: 2rem;
  --gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--gutter-y));
  margin-right: calc(-0.5 * var(--gutter-x));
  margin-left: calc(-0.5 * var(--gutter-x));
}
.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--gutter-x) * 0.5);
  padding-left: calc(var(--gutter-x) * 0.5);
  margin-top: var(--gutter-y);
}

.col {
  flex: 1 0 0;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

.g-0,
.gx-0 {
  --gutter-x: 0;
}

.g-0,
.gy-0 {
  --gutter-y: 0;
}

.g-1,
.gx-1 {
  --gutter-x: 0.5rem;
}

.g-1,
.gy-1 {
  --gutter-y: 0.5rem;
}

.g-2,
.gx-2 {
  --gutter-x: 1rem;
}

.g-2,
.gy-2 {
  --gutter-y: 1rem;
}

.g-3,
.gx-3 {
  --gutter-x: 1.5rem;
}

.g-3,
.gy-3 {
  --gutter-y: 1.5rem;
}

.g-4,
.gx-4 {
  --gutter-x: 2rem;
}

.g-4,
.gy-4 {
  --gutter-y: 2rem;
}

.g-5,
.gx-5 {
  --gutter-x: 2.5rem;
}

.g-5,
.gy-5 {
  --gutter-y: 2.5rem;
}

.g-6,
.gx-6 {
  --gutter-x: 3rem;
}

.g-6,
.gy-6 {
  --gutter-y: 3rem;
}

.g-8,
.gx-8 {
  --gutter-x: 4rem;
}

.g-8,
.gy-8 {
  --gutter-y: 4rem;
}

.g-10,
.gx-10 {
  --gutter-x: 5rem;
}

.g-10,
.gy-10 {
  --gutter-y: 5rem;
}

@media (min-width: 540px) {
  .col-sm {
    flex: 1 0 0;
  }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
  .g-sm-0,
  .gx-sm-0 {
    --gutter-x: 0;
  }
  .g-sm-0,
  .gy-sm-0 {
    --gutter-y: 0;
  }
  .g-sm-1,
  .gx-sm-1 {
    --gutter-x: 0.5rem;
  }
  .g-sm-1,
  .gy-sm-1 {
    --gutter-y: 0.5rem;
  }
  .g-sm-2,
  .gx-sm-2 {
    --gutter-x: 1rem;
  }
  .g-sm-2,
  .gy-sm-2 {
    --gutter-y: 1rem;
  }
  .g-sm-3,
  .gx-sm-3 {
    --gutter-x: 1.5rem;
  }
  .g-sm-3,
  .gy-sm-3 {
    --gutter-y: 1.5rem;
  }
  .g-sm-4,
  .gx-sm-4 {
    --gutter-x: 2rem;
  }
  .g-sm-4,
  .gy-sm-4 {
    --gutter-y: 2rem;
  }
  .g-sm-5,
  .gx-sm-5 {
    --gutter-x: 2.5rem;
  }
  .g-sm-5,
  .gy-sm-5 {
    --gutter-y: 2.5rem;
  }
  .g-sm-6,
  .gx-sm-6 {
    --gutter-x: 3rem;
  }
  .g-sm-6,
  .gy-sm-6 {
    --gutter-y: 3rem;
  }
  .g-sm-8,
  .gx-sm-8 {
    --gutter-x: 4rem;
  }
  .g-sm-8,
  .gy-sm-8 {
    --gutter-y: 4rem;
  }
  .g-sm-10,
  .gx-sm-10 {
    --gutter-x: 5rem;
  }
  .g-sm-10,
  .gy-sm-10 {
    --gutter-y: 5rem;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0;
  }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333333%;
  }
  .offset-md-2 {
    margin-left: 16.66666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333333%;
  }
  .offset-md-5 {
    margin-left: 41.66666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333333%;
  }
  .offset-md-8 {
    margin-left: 66.66666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333333%;
  }
  .offset-md-11 {
    margin-left: 91.66666667%;
  }
  .g-md-0,
  .gx-md-0 {
    --gutter-x: 0;
  }
  .g-md-0,
  .gy-md-0 {
    --gutter-y: 0;
  }
  .g-md-1,
  .gx-md-1 {
    --gutter-x: 0.5rem;
  }
  .g-md-1,
  .gy-md-1 {
    --gutter-y: 0.5rem;
  }
  .g-md-2,
  .gx-md-2 {
    --gutter-x: 1rem;
  }
  .g-md-2,
  .gy-md-2 {
    --gutter-y: 1rem;
  }
  .g-md-3,
  .gx-md-3 {
    --gutter-x: 1.5rem;
  }
  .g-md-3,
  .gy-md-3 {
    --gutter-y: 1.5rem;
  }
  .g-md-4,
  .gx-md-4 {
    --gutter-x: 2rem;
  }
  .g-md-4,
  .gy-md-4 {
    --gutter-y: 2rem;
  }
  .g-md-5,
  .gx-md-5 {
    --gutter-x: 2.5rem;
  }
  .g-md-5,
  .gy-md-5 {
    --gutter-y: 2.5rem;
  }
  .g-md-6,
  .gx-md-6 {
    --gutter-x: 3rem;
  }
  .g-md-6,
  .gy-md-6 {
    --gutter-y: 3rem;
  }
  .g-md-8,
  .gx-md-8 {
    --gutter-x: 4rem;
  }
  .g-md-8,
  .gy-md-8 {
    --gutter-y: 4rem;
  }
  .g-md-10,
  .gx-md-10 {
    --gutter-x: 5rem;
  }
  .g-md-10,
  .gy-md-10 {
    --gutter-y: 5rem;
  }
}
@media (min-width: 960px) {
  .col-lg {
    flex: 1 0 0;
  }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
  .g-lg-0,
  .gx-lg-0 {
    --gutter-x: 0;
  }
  .g-lg-0,
  .gy-lg-0 {
    --gutter-y: 0;
  }
  .g-lg-1,
  .gx-lg-1 {
    --gutter-x: 0.5rem;
  }
  .g-lg-1,
  .gy-lg-1 {
    --gutter-y: 0.5rem;
  }
  .g-lg-2,
  .gx-lg-2 {
    --gutter-x: 1rem;
  }
  .g-lg-2,
  .gy-lg-2 {
    --gutter-y: 1rem;
  }
  .g-lg-3,
  .gx-lg-3 {
    --gutter-x: 1.5rem;
  }
  .g-lg-3,
  .gy-lg-3 {
    --gutter-y: 1.5rem;
  }
  .g-lg-4,
  .gx-lg-4 {
    --gutter-x: 2rem;
  }
  .g-lg-4,
  .gy-lg-4 {
    --gutter-y: 2rem;
  }
  .g-lg-5,
  .gx-lg-5 {
    --gutter-x: 2.5rem;
  }
  .g-lg-5,
  .gy-lg-5 {
    --gutter-y: 2.5rem;
  }
  .g-lg-6,
  .gx-lg-6 {
    --gutter-x: 3rem;
  }
  .g-lg-6,
  .gy-lg-6 {
    --gutter-y: 3rem;
  }
  .g-lg-8,
  .gx-lg-8 {
    --gutter-x: 4rem;
  }
  .g-lg-8,
  .gy-lg-8 {
    --gutter-y: 4rem;
  }
  .g-lg-10,
  .gx-lg-10 {
    --gutter-x: 5rem;
  }
  .g-lg-10,
  .gy-lg-10 {
    --gutter-y: 5rem;
  }
}
@media (min-width: 1140px) {
  .col-xl {
    flex: 1 0 0;
  }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
  .g-xl-0,
  .gx-xl-0 {
    --gutter-x: 0;
  }
  .g-xl-0,
  .gy-xl-0 {
    --gutter-y: 0;
  }
  .g-xl-1,
  .gx-xl-1 {
    --gutter-x: 0.5rem;
  }
  .g-xl-1,
  .gy-xl-1 {
    --gutter-y: 0.5rem;
  }
  .g-xl-2,
  .gx-xl-2 {
    --gutter-x: 1rem;
  }
  .g-xl-2,
  .gy-xl-2 {
    --gutter-y: 1rem;
  }
  .g-xl-3,
  .gx-xl-3 {
    --gutter-x: 1.5rem;
  }
  .g-xl-3,
  .gy-xl-3 {
    --gutter-y: 1.5rem;
  }
  .g-xl-4,
  .gx-xl-4 {
    --gutter-x: 2rem;
  }
  .g-xl-4,
  .gy-xl-4 {
    --gutter-y: 2rem;
  }
  .g-xl-5,
  .gx-xl-5 {
    --gutter-x: 2.5rem;
  }
  .g-xl-5,
  .gy-xl-5 {
    --gutter-y: 2.5rem;
  }
  .g-xl-6,
  .gx-xl-6 {
    --gutter-x: 3rem;
  }
  .g-xl-6,
  .gy-xl-6 {
    --gutter-y: 3rem;
  }
  .g-xl-8,
  .gx-xl-8 {
    --gutter-x: 4rem;
  }
  .g-xl-8,
  .gy-xl-8 {
    --gutter-y: 4rem;
  }
  .g-xl-10,
  .gx-xl-10 {
    --gutter-x: 5rem;
  }
  .g-xl-10,
  .gy-xl-10 {
    --gutter-y: 5rem;
  }
}
.table {
  --table-color-type: initial;
  --table-bg-type: initial;
  --table-color-state: initial;
  --table-bg-state: initial;
  --table-color: #000;
  --table-bg: #f3f3f3;
  --table-border-color: #9b9b9b;
  --table-accent-bg: transparent;
  --table-striped-color: #000;
  --table-striped-bg: rgba(var(--emphasis-color-rgb), 0.05);
  --table-active-color: #000;
  --table-active-bg: rgba(0, 0, 0, 0.075);
  --table-hover-color: #000;
  --table-hover-bg: rgba(0, 0, 0, 0.075);
  width: 100%;
  margin-bottom: 1rem;
  vertical-align: top;
  border-color: var(--table-border-color);
}
.table > :not(caption) > * > * {
  padding: 0.5rem 0.5rem;
  color: var(--table-color-state, var(--table-color-type, var(--table-color)));
  background-color: var(--table-bg);
  border-bottom-width: 2px;
  box-shadow: inset 0 0 0 9999px var(--table-bg-state, var(--table-bg-type, var(--table-accent-bg)));
}
.table > tbody {
  vertical-align: inherit;
}
.table > thead {
  vertical-align: bottom;
}

.table-group-divider {
  border-top: calc(2px * 2) solid currentcolor;
}

.caption-top {
  caption-side: top;
}

.table-sm > :not(caption) > * > * {
  padding: 0.25rem 0.25rem;
}

.table-bordered > :not(caption) > * {
  border-width: 2px 0;
}
.table-bordered > :not(caption) > * > * {
  border-width: 0 2px;
}

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}
.table-borderless > :not(:first-child) {
  border-top-width: 0;
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
  --table-color-type: var(--table-striped-color);
  --table-bg-type: var(--table-striped-bg);
}

.table-striped-columns > :not(caption) > tr > :nth-child(even) {
  --table-color-type: var(--table-striped-color);
  --table-bg-type: var(--table-striped-bg);
}

.table-active {
  --table-color-state: var(--table-active-color);
  --table-bg-state: var(--table-active-bg);
}

.table-hover > tbody > tr:hover > * {
  --table-color-state: var(--table-hover-color);
  --table-bg-state: var(--table-hover-bg);
}

.table-primary {
  --table-color: #373737;
  --table-bg: #d2e4f5;
  --table-border-color: #b3c1cf;
  --table-striped-bg: #cadbec;
  --table-striped-color: #373737;
  --table-active-bg: #c3d3e2;
  --table-active-color: #373737;
  --table-hover-bg: #c6d7e7;
  --table-hover-color: #373737;
  color: var(--table-color);
  border-color: var(--table-border-color);
}

.table-secondary {
  --table-color: #373737;
  --table-bg: #f4f4f4;
  --table-border-color: #cecece;
  --table-striped-bg: #ebebeb;
  --table-striped-color: #373737;
  --table-active-bg: #e1e1e1;
  --table-active-color: #373737;
  --table-hover-bg: #e6e6e6;
  --table-hover-color: #373737;
  color: var(--table-color);
  border-color: var(--table-border-color);
}

.table-success {
  --table-color: #373737;
  --table-bg: #d9e6d1;
  --table-border-color: #b9c3b2;
  --table-striped-bg: #d1ddc9;
  --table-striped-color: #373737;
  --table-active-bg: #c9d5c2;
  --table-active-color: #373737;
  --table-hover-bg: #cdd9c5;
  --table-hover-color: #373737;
  color: var(--table-color);
  border-color: var(--table-border-color);
}

.table-info {
  --table-color: #373737;
  --table-bg: #d2e4f5;
  --table-border-color: #b3c1cf;
  --table-striped-bg: #cadbec;
  --table-striped-color: #373737;
  --table-active-bg: #c3d3e2;
  --table-active-color: #373737;
  --table-hover-bg: #c6d7e7;
  --table-hover-color: #373737;
  color: var(--table-color);
  border-color: var(--table-border-color);
}

.table-warning {
  --table-color: #373737;
  --table-bg: #ffeecc;
  --table-border-color: #d7c9ae;
  --table-striped-bg: #f5e5c5;
  --table-striped-color: #373737;
  --table-active-bg: #ebdcbd;
  --table-active-color: #373737;
  --table-hover-bg: #f0e0c1;
  --table-hover-color: #373737;
  color: var(--table-color);
  border-color: var(--table-border-color);
}

.table-danger {
  --table-color: #373737;
  --table-bg: #f3d1d1;
  --table-border-color: #cdb2b2;
  --table-striped-bg: #eac9c9;
  --table-striped-color: #373737;
  --table-active-bg: #e0c2c2;
  --table-active-color: #373737;
  --table-hover-bg: #e5c5c5;
  --table-hover-color: #373737;
  color: var(--table-color);
  border-color: var(--table-border-color);
}

.table-light {
  --table-color: #373737;
  --table-bg: #f3f3f3;
  --table-border-color: #cdcdcd;
  --table-striped-bg: #eaeaea;
  --table-striped-color: #373737;
  --table-active-bg: #e0e0e0;
  --table-active-color: #373737;
  --table-hover-bg: #e5e5e5;
  --table-hover-color: #373737;
  color: var(--table-color);
  border-color: var(--table-border-color);
}

.table-dark {
  --table-color: #fff;
  --table-bg: #797979;
  --table-border-color: #949494;
  --table-striped-bg: gray;
  --table-striped-color: #fff;
  --table-active-bg: #868686;
  --table-active-color: #fff;
  --table-hover-bg: #838383;
  --table-hover-color: #fff;
  color: var(--table-color);
  border-color: var(--table-border-color);
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 539.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 959.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1139.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
.form-label {
  margin-bottom: 0.5rem;
}

.col-form-label {
  padding-top: calc(0.75rem + 2px);
  padding-bottom: calc(0.75rem + 2px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 2.22;
}

.col-form-label-lg {
  padding-top: calc(1.6725rem + 2px);
  padding-bottom: calc(1.6725rem + 2px);
  font-size: 1.25rem;
}

.col-form-label-sm {
  padding-top: calc(0.3333333333rem + 2px);
  padding-bottom: calc(0.3333333333rem + 2px);
  font-size: 0.75rem;
}

.form-text {
  margin-top: 0.3333333333rem;
  font-size: 0.875rem;
  color: var(--secondary-color);
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.75rem 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 2.22;
  color: #5c5c5c;
  appearance: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 2px solid #9b9b9b;
  border-radius: 0.5rem;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.075);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control[type=file] {
  overflow: hidden;
}
.form-control[type=file]:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control:focus {
  color: #5c5c5c;
  background-color: #fff;
  border-color: #1e76ce;
  outline: 0;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.075), none;
}
.form-control::-webkit-date-and-time-value {
  min-width: 85px;
  height: 2.22em;
  margin: 0;
}
.form-control::-webkit-datetime-edit {
  display: block;
  padding: 0;
}
.form-control::placeholder {
  color: #9b9b9b;
  opacity: 1;
}
.form-control:disabled {
  color: #c6c6c6;
  background-color: #f3f3f3;
  border-color: #9b9b9b;
  opacity: 1;
}
.form-control::file-selector-button {
  padding: 0.75rem 1rem;
  margin: -0.75rem -1rem;
  margin-inline-end: 1rem;
  color: #5c5c5c;
  background-color: var(--tertiary-bg);
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 2px;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button {
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: var(--secondary-bg);
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.75rem 0;
  margin-bottom: 0;
  line-height: 2.22;
  color: #000;
  background-color: transparent;
  border: solid transparent;
  border-width: 2px 0;
}
.form-control-plaintext:focus {
  outline: 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  min-height: calc(1.5em + 0.6666666667rem + 4px);
  padding: 0.3333333333rem 0.3333333333rem;
  font-size: 0.75rem;
  border-radius: 0.5rem;
}
.form-control-sm::file-selector-button {
  padding: 0.3333333333rem 0.3333333333rem;
  margin: -0.3333333333rem -0.3333333333rem;
  margin-inline-end: 0.3333333333rem;
}

.form-control-lg {
  min-height: calc(1.875em + 3.345rem + 4px);
  padding: 1.6725rem 1.6725rem;
  font-size: 1.25rem;
  border-radius: 1.5rem;
}
.form-control-lg::file-selector-button {
  padding: 1.6725rem 1.6725rem;
  margin: -1.6725rem -1.6725rem;
  margin-inline-end: 1.6725rem;
}

textarea.form-control {
  min-height: calc(2.22em + 1.5rem + 4px);
}
textarea.form-control-sm {
  min-height: calc(1.5em + 0.6666666667rem + 4px);
}
textarea.form-control-lg {
  min-height: calc(1.875em + 3.345rem + 4px);
}

.form-control-color {
  width: 3rem;
  height: calc(2.22em + 1.5rem + 4px);
  padding: 0.75rem;
}
.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control-color::-moz-color-swatch {
  border: 0 !important;
  border-radius: 0.5rem;
}
.form-control-color::-webkit-color-swatch {
  border: 0 !important;
  border-radius: 0.5rem;
}
.form-control-color.form-control-sm {
  height: calc(1.5em + 0.6666666667rem + 4px);
}
.form-control-color.form-control-lg {
  height: calc(1.875em + 3.345rem + 4px);
}

.form-select {
  --form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23484848' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  display: block;
  width: 100%;
  padding: 0.75rem 3rem 0.75rem 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 2.22;
  color: #5c5c5c;
  appearance: none;
  background-color: #fff;
  background-image: var(--form-select-bg-img), var(--form-select-bg-icon, none);
  background-repeat: no-repeat;
  background-position: right 1rem center;
  background-size: 16px 12px;
  border: 2px solid #9b9b9b;
  border-radius: 0.5rem;
  box-shadow: var(--box-shadow-inset);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}
.form-select:focus {
  border-color: #1e76ce;
  outline: 0;
  box-shadow: var(--box-shadow-inset), 0 0 0 0.125rem #1e76ce;
}
.form-select[multiple], .form-select[size]:not([size="1"]) {
  padding-right: 1rem;
  background-image: none;
}
.form-select:disabled {
  background-color: #f3f3f3;
  border-color: #9b9b9b;
}
.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #5c5c5c;
}

.form-select-sm {
  padding-top: 0.3333333333rem;
  padding-bottom: 0.3333333333rem;
  padding-left: 0.3333333333rem;
  font-size: 0.75rem;
  border-radius: 0.5rem;
}

.form-select-lg {
  padding-top: 1.6725rem;
  padding-bottom: 1.6725rem;
  padding-left: 1.6725rem;
  font-size: 1.25rem;
  border-radius: 1.5rem;
}

[data-bs-theme=dark] .form-select {
  --form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23ababab' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
}

.form-check {
  display: block;
  min-height: 2rem;
  padding-left: 3rem;
  margin-bottom: 0.5rem;
}
.form-check .form-check-input {
  float: left;
  margin-left: -3rem;
}

.form-check-reverse {
  padding-right: 3rem;
  padding-left: 0;
  text-align: right;
}
.form-check-reverse .form-check-input {
  float: right;
  margin-right: -3rem;
  margin-left: 0;
}

.form-check-input {
  --form-check-bg: #fff;
  flex-shrink: 0;
  width: 1.875rem;
  height: 1.875rem;
  margin-top: 0.0625rem;
  vertical-align: top;
  appearance: none;
  background-color: var(--form-check-bg);
  background-image: var(--form-check-bg-image);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 2px solid #9b9b9b;
  print-color-adjust: exact;
}
.form-check-input[type=checkbox] {
  border-radius: 0.5rem;
}
.form-check-input[type=radio] {
  border-radius: 50%;
}
.form-check-input:active {
  filter: brightness(90%);
}
.form-check-input:focus {
  border-color: #1e76ce;
  outline: 0;
  box-shadow: 0 0 0 0.125rem #fff, 0 0 0 0.25rem #1e76ce;
}
.form-check-input:checked {
  background-color: #fff;
  border-color: #9b9b9b;
}
.form-check-input:checked[type=checkbox] {
  --form-check-bg-image: url("data:image/svg+xml,%3c?xml version='1.0' encoding='UTF-8' standalone='no'?%3e%3csvg width='24' height='24' viewBox='0 0 24 24' fill='none' version='1.1' id='svg4' xmlns='http://www.w3.org/2000/svg' xmlns:svg='http://www.w3.org/2000/svg'%3e%3cdefs id='defs8' /%3e%3cpath d='m 20.607748,5.6879697 c 0.51799,0.478125 0.51799,1.3148395 0,1.7929645 L 10.407791,17.680917 c -0.4781257,0.51799 -1.3148402,0.51799 -1.7929652,0 L 3.5148216,12.580934 c -0.5179688,-0.478125 -0.5179688,-1.314839 0,-1.792964 0.4781292,-0.517965 1.3148437,-0.517965 1.7929687,0 l 4.2234375,4.183572 9.2836152,-9.2835723 c 0.478125,-0.5179688 1.314865,-0.5179688 1.792905,0 z' fill='%235fb624' style='stroke-width:0.85' /%3e%3c/svg%3e");
}
.form-check-input:checked[type=radio] {
  --form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='2.3' fill='%235fb624'/%3e%3c/svg%3e");
}
.form-check-input[type=checkbox]:indeterminate {
  background-color: #5fb624;
  border-color: #5fb624;
  --form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%235fb624' d='M0 2h4'/%3e%3c/svg%3e");
}
.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  cursor: default;
  opacity: 0.5;
}

.form-switch {
  padding-left: 2.5em;
}
.form-switch .form-check-input {
  --form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  width: 2em;
  margin-left: -2.5em;
  background-image: var(--form-switch-bg);
  background-position: left center;
  border-radius: 2em;
  transition: background-position 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}
.form-switch .form-check-input:focus {
  --form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%231e76ce'/%3e%3c/svg%3e");
}
.form-switch .form-check-input:checked {
  background-position: right center;
  --form-switch-bg: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
.form-switch.form-check-reverse {
  padding-right: 2.5em;
  padding-left: 0;
}
.form-switch.form-check-reverse .form-check-input {
  margin-right: -2.5em;
  margin-left: 0;
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.btn-check[disabled] + .btn, .btn-check:disabled + .btn {
  pointer-events: none;
  filter: none;
  opacity: 1;
}

[data-bs-theme=dark] .form-switch .form-check-input:not(:checked):not(:focus) {
  --form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%28255, 255, 255, 0.25%29'/%3e%3c/svg%3e");
}

.form-range {
  width: 100%;
  height: 1.25rem;
  padding: 0;
  appearance: none;
  background-color: transparent;
}
.form-range:focus {
  outline: 0;
}
.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, none;
}
.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, none;
}
.form-range::-moz-focus-outer {
  border: 0;
}
.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  appearance: none;
  background-color: #5fb624;
  border: 0;
  border-radius: 1rem;
  box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.1);
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    transition: none;
  }
}
.form-range::-webkit-slider-thumb:active {
  background-color: #cfe9bd;
}
.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: var(--secondary-bg);
  border-color: transparent;
  border-radius: 1rem;
  box-shadow: var(--box-shadow-inset);
}
.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  appearance: none;
  background-color: #5fb624;
  border: 0;
  border-radius: 1rem;
  box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.1);
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    transition: none;
  }
}
.form-range::-moz-range-thumb:active {
  background-color: #cfe9bd;
}
.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: var(--secondary-bg);
  border-color: transparent;
  border-radius: 1rem;
  box-shadow: var(--box-shadow-inset);
}
.form-range:disabled {
  pointer-events: none;
}
.form-range:disabled::-webkit-slider-thumb {
  background-color: var(--secondary-color);
}
.form-range:disabled::-moz-range-thumb {
  background-color: var(--secondary-color);
}

.form-floating {
  position: relative;
}
.form-floating > .form-control,
.form-floating > .form-control-plaintext,
.form-floating > .form-select {
  height: calc(2.22em + 1.5rem + 4px);
  min-height: calc(2.22em + 1.5rem + 4px);
  line-height: 1.5;
}
.form-floating > label {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  max-width: 100%;
  height: 100%;
  padding: 1rem 1rem;
  overflow: hidden;
  color: rgba(var(--body-color-rgb), 1);
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;
  pointer-events: none;
  border: 2px solid transparent;
  transform-origin: 0 0;
  transition: color 0.1s ease-in-out, transform 0.1s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}
.form-floating > .form-control,
.form-floating > .form-control-plaintext {
  padding: 1rem 1rem;
}
.form-floating > .form-control::placeholder,
.form-floating > .form-control-plaintext::placeholder {
  color: transparent;
}
.form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown),
.form-floating > .form-control-plaintext:focus,
.form-floating > .form-control-plaintext:not(:placeholder-shown) {
  padding-top: 0.625rem;
  padding-bottom: 0;
}
.form-floating > .form-control:-webkit-autofill,
.form-floating > .form-control-plaintext:-webkit-autofill {
  padding-top: 0.625rem;
  padding-bottom: 0;
}
.form-floating > .form-select {
  padding-top: 0.625rem;
  padding-bottom: 0;
  padding-left: 1rem;
}
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-control-plaintext ~ label,
.form-floating > .form-select ~ label {
  transform: scale(0.75) translateY(-0.3rem) translateX(0.25rem);
}
.form-floating > .form-control:-webkit-autofill ~ label {
  transform: scale(0.75) translateY(-0.3rem) translateX(0.25rem);
}
.form-floating > textarea:focus ~ label::after,
.form-floating > textarea:not(:placeholder-shown) ~ label::after {
  position: absolute;
  inset: 1rem 0.5rem;
  z-index: -1;
  height: 1.5em;
  content: "";
  background-color: #fff;
  border-radius: 0.5rem;
}
.form-floating > textarea:disabled ~ label::after {
  background-color: #f3f3f3;
}
.form-floating > .form-control-plaintext ~ label {
  border-width: 2px 0;
}
.form-floating > :disabled ~ label,
.form-floating > .form-control:disabled ~ label {
  color: #767676;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-select,
.input-group > .form-floating {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}
.input-group > .form-control:focus,
.input-group > .form-select:focus,
.input-group > .form-floating:focus-within {
  z-index: 5;
}
.input-group .btn {
  position: relative;
  z-index: 2;
}
.input-group .btn:focus {
  z-index: 5;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.75rem 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 2.22;
  color: #5c5c5c;
  text-align: center;
  white-space: nowrap;
  background-color: #fff;
  border: 2px solid #9b9b9b;
  border-radius: 0.5rem;
}

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  padding: 1.6725rem 1.6725rem;
  font-size: 1.25rem;
  border-radius: 1.5rem;
}

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 0.3333333333rem 0.3333333333rem;
  font-size: 0.75rem;
  border-radius: 0.5rem;
}

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 4rem;
}

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3),
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-control,
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > :nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n+4),
.input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-control,
.input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > :not(:first-child):not(.dropdown-menu):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: calc(-1 * 2px);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .form-floating:not(:first-child) > .form-control,
.input-group > .form-floating:not(:first-child) > .form-select {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.3333333333rem;
  font-size: 0.875rem;
  color: #c41a1a;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.75rem;
  line-height: 2;
  color: #fff;
  background-color: rgba(196, 26, 26, 0.9);
  border-radius: 1.5rem;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #c41a1a;
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #c41a1a;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.075), 0 0 0 0.125rem rgba(196, 26, 26, 0.25);
}

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: #c41a1a;
}
.was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
  border-color: #c41a1a;
  box-shadow: var(--box-shadow-inset), 0 0 0 0.125rem rgba(196, 26, 26, 0.25);
}

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: #c41a1a;
}
.was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
  background-color: #c41a1a;
}
.was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 0.125rem rgba(196, 26, 26, 0.25);
}
.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #c41a1a;
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group > .form-control:not(:focus):invalid, .input-group > .form-control:not(:focus).is-invalid,
.was-validated .input-group > .form-select:not(:focus):invalid,
.input-group > .form-select:not(:focus).is-invalid,
.was-validated .input-group > .form-floating:not(:focus-within):invalid,
.input-group > .form-floating:not(:focus-within).is-invalid {
  z-index: 4;
}

.btn {
  --btn-padding-x: 1.25rem;
  --btn-padding-y: 0.75rem;
  --btn-font-family: ;
  --btn-font-size: 1rem;
  --btn-font-weight: 700;
  --btn-line-height: 1.5;
  --btn-color: var(--body-color);
  --btn-bg: transparent;
  --btn-border-width: 0;
  --btn-border-color: transparent;
  --btn-border-radius: 50rem;
  --btn-hover-border-color: transparent;
  --btn-box-shadow: inset 0 4px 0 rgba(0, 0, 0, 0.48);
  --btn-disabled-opacity: 1;
  --btn-focus-box-shadow: 0 0 0 0.125rem rgba(var(--btn-focus-shadow-rgb), .5);
  display: inline-block;
  padding: var(--btn-padding-y) var(--btn-padding-x);
  font-family: var(--btn-font-family);
  font-size: var(--btn-font-size);
  font-weight: var(--btn-font-weight);
  line-height: var(--btn-line-height);
  color: var(--btn-color);
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: var(--btn-border-width) solid var(--btn-border-color);
  border-radius: var(--btn-border-radius);
  background-color: var(--btn-bg);
  box-shadow: var(--btn-box-shadow);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: var(--btn-hover-color);
  text-decoration: none;
  background-color: var(--btn-hover-bg);
  border-color: var(--btn-hover-border-color);
}
.btn-check + .btn:hover {
  color: var(--btn-color);
  background-color: var(--btn-bg);
  border-color: var(--btn-border-color);
}
.btn:focus-visible {
  color: var(--btn-hover-color);
  background-color: var(--btn-hover-bg);
  border-color: var(--btn-hover-border-color);
  outline: 0;
  box-shadow: var(--btn-box-shadow), var(--btn-focus-box-shadow);
}
.btn-check:focus-visible + .btn {
  border-color: var(--btn-hover-border-color);
  outline: 0;
  box-shadow: var(--btn-box-shadow), var(--btn-focus-box-shadow);
}
.btn-check:checked + .btn, :not(.btn-check) + .btn:active, .btn:first-child:active, .btn.active, .btn.show {
  color: var(--btn-active-color);
  background-color: var(--btn-active-bg);
  border-color: var(--btn-active-border-color);
  box-shadow: var(--btn-active-shadow);
}
.btn-check:checked + .btn:focus-visible, :not(.btn-check) + .btn:active:focus-visible, .btn:first-child:active:focus-visible, .btn.active:focus-visible, .btn.show:focus-visible {
  box-shadow: var(--btn-active-shadow), var(--btn-focus-box-shadow);
}
.btn-check:checked:focus-visible + .btn {
  box-shadow: var(--btn-active-shadow), var(--btn-focus-box-shadow);
}
.btn:disabled, .btn.disabled, fieldset:disabled .btn {
  color: var(--btn-disabled-color);
  pointer-events: none;
  background-color: var(--btn-disabled-bg);
  border-color: var(--btn-disabled-border-color);
  opacity: var(--btn-disabled-opacity);
  box-shadow: none;
}

.btn-brand {
  --btn-color: #fff;
  --btn-bg: #5fb624;
  --btn-border-color: #5fb624;
  --btn-hover-color: #fff;
  --btn-hover-bg: #519b1f;
  --btn-hover-border-color: #4c921d;
  --btn-focus-shadow-rgb: 119, 193, 69;
  --btn-active-color: #fff;
  --btn-active-bg: #4c921d;
  --btn-active-border-color: #47891b;
  --btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --btn-disabled-color: #fff;
  --btn-disabled-bg: #5fb624;
  --btn-disabled-border-color: #5fb624;
}

.btn-brand-alt {
  --btn-color: #fff;
  --btn-bg: #410073;
  --btn-border-color: #410073;
  --btn-hover-color: #fff;
  --btn-hover-bg: #370062;
  --btn-hover-border-color: #34005c;
  --btn-focus-shadow-rgb: 94, 38, 136;
  --btn-active-color: #fff;
  --btn-active-bg: #34005c;
  --btn-active-border-color: #310056;
  --btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --btn-disabled-color: #fff;
  --btn-disabled-bg: #410073;
  --btn-disabled-border-color: #410073;
}

.btn-brand-purple {
  --btn-color: #fff;
  --btn-bg: #410073;
  --btn-border-color: #410073;
  --btn-hover-color: #fff;
  --btn-hover-bg: #370062;
  --btn-hover-border-color: #34005c;
  --btn-focus-shadow-rgb: 94, 38, 136;
  --btn-active-color: #fff;
  --btn-active-bg: #34005c;
  --btn-active-border-color: #310056;
  --btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --btn-disabled-color: #fff;
  --btn-disabled-bg: #410073;
  --btn-disabled-border-color: #410073;
}

.btn-primary {
  --btn-color: #fff;
  --btn-bg: #1e76ce;
  --btn-border-color: #1e76ce;
  --btn-hover-color: #fff;
  --btn-hover-bg: #1a64af;
  --btn-hover-border-color: #185ea5;
  --btn-focus-shadow-rgb: 64, 139, 213;
  --btn-active-color: #fff;
  --btn-active-bg: #185ea5;
  --btn-active-border-color: #17599b;
  --btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --btn-disabled-color: #fff;
  --btn-disabled-bg: #1e76ce;
  --btn-disabled-border-color: #1e76ce;
}

.btn-secondary {
  --btn-color: #373737;
  --btn-bg: #c6c6c6;
  --btn-border-color: #c6c6c6;
  --btn-hover-color: #373737;
  --btn-hover-bg: #cfcfcf;
  --btn-hover-border-color: #cccccc;
  --btn-focus-shadow-rgb: 177, 177, 177;
  --btn-active-color: #373737;
  --btn-active-bg: #d1d1d1;
  --btn-active-border-color: #cccccc;
  --btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --btn-disabled-color: #373737;
  --btn-disabled-bg: #c6c6c6;
  --btn-disabled-border-color: #c6c6c6;
}

.btn-success {
  --btn-color: #fff;
  --btn-bg: #43811a;
  --btn-border-color: #43811a;
  --btn-hover-color: #fff;
  --btn-hover-bg: #396e16;
  --btn-hover-border-color: #366715;
  --btn-focus-shadow-rgb: 95, 148, 60;
  --btn-active-color: #fff;
  --btn-active-bg: #366715;
  --btn-active-border-color: #326114;
  --btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --btn-disabled-color: #fff;
  --btn-disabled-bg: #43811a;
  --btn-disabled-border-color: #43811a;
}

.btn-info {
  --btn-color: #fff;
  --btn-bg: #1e76ce;
  --btn-border-color: #1e76ce;
  --btn-hover-color: #fff;
  --btn-hover-bg: #1a64af;
  --btn-hover-border-color: #185ea5;
  --btn-focus-shadow-rgb: 64, 139, 213;
  --btn-active-color: #fff;
  --btn-active-bg: #185ea5;
  --btn-active-border-color: #17599b;
  --btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --btn-disabled-color: #fff;
  --btn-disabled-bg: #1e76ce;
  --btn-disabled-border-color: #1e76ce;
}

.btn-warning {
  --btn-color: #373737;
  --btn-bg: #ffa800;
  --btn-border-color: #ffa800;
  --btn-hover-color: #373737;
  --btn-hover-bg: #ffb526;
  --btn-hover-border-color: #ffb11a;
  --btn-focus-shadow-rgb: 225, 151, 8;
  --btn-active-color: #373737;
  --btn-active-bg: #ffb933;
  --btn-active-border-color: #ffb11a;
  --btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --btn-disabled-color: #373737;
  --btn-disabled-bg: #ffa800;
  --btn-disabled-border-color: #ffa800;
}

.btn-danger {
  --btn-color: #fff;
  --btn-bg: #c41a1a;
  --btn-border-color: #c41a1a;
  --btn-hover-color: #fff;
  --btn-hover-bg: #a71616;
  --btn-hover-border-color: #9d1515;
  --btn-focus-shadow-rgb: 205, 60, 60;
  --btn-active-color: #fff;
  --btn-active-bg: #9d1515;
  --btn-active-border-color: #931414;
  --btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --btn-disabled-color: #fff;
  --btn-disabled-bg: #c41a1a;
  --btn-disabled-border-color: #c41a1a;
}

.btn-light {
  --btn-color: #373737;
  --btn-bg: #f3f3f3;
  --btn-border-color: #f3f3f3;
  --btn-hover-color: #373737;
  --btn-hover-bg: #cfcfcf;
  --btn-hover-border-color: #c2c2c2;
  --btn-focus-shadow-rgb: 215, 215, 215;
  --btn-active-color: #373737;
  --btn-active-bg: #c2c2c2;
  --btn-active-border-color: #b6b6b6;
  --btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --btn-disabled-color: #373737;
  --btn-disabled-bg: #f3f3f3;
  --btn-disabled-border-color: #f3f3f3;
}

.btn-dark {
  --btn-color: #fff;
  --btn-bg: #797979;
  --btn-border-color: #797979;
  --btn-hover-color: #fff;
  --btn-hover-bg: #8d8d8d;
  --btn-hover-border-color: #868686;
  --btn-focus-shadow-rgb: 141, 141, 141;
  --btn-active-color: #fff;
  --btn-active-bg: #949494;
  --btn-active-border-color: #868686;
  --btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --btn-disabled-color: #fff;
  --btn-disabled-bg: #797979;
  --btn-disabled-border-color: #797979;
}

.btn-outline-brand {
  --btn-color: #5fb624;
  --btn-border-color: #5fb624;
  --btn-hover-color: #fff;
  --btn-hover-bg: #5fb624;
  --btn-hover-border-color: #5fb624;
  --btn-focus-shadow-rgb: 95, 182, 36;
  --btn-active-color: #fff;
  --btn-active-bg: #5fb624;
  --btn-active-border-color: #5fb624;
  --btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --btn-disabled-color: #5fb624;
  --btn-disabled-bg: transparent;
  --btn-disabled-border-color: #5fb624;
  --gradient: none;
}

.btn-outline-brand-alt {
  --btn-color: #410073;
  --btn-border-color: #410073;
  --btn-hover-color: #fff;
  --btn-hover-bg: #410073;
  --btn-hover-border-color: #410073;
  --btn-focus-shadow-rgb: 65, 0, 115;
  --btn-active-color: #fff;
  --btn-active-bg: #410073;
  --btn-active-border-color: #410073;
  --btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --btn-disabled-color: #410073;
  --btn-disabled-bg: transparent;
  --btn-disabled-border-color: #410073;
  --gradient: none;
}

.btn-outline-brand-purple {
  --btn-color: #410073;
  --btn-border-color: #410073;
  --btn-hover-color: #fff;
  --btn-hover-bg: #410073;
  --btn-hover-border-color: #410073;
  --btn-focus-shadow-rgb: 65, 0, 115;
  --btn-active-color: #fff;
  --btn-active-bg: #410073;
  --btn-active-border-color: #410073;
  --btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --btn-disabled-color: #410073;
  --btn-disabled-bg: transparent;
  --btn-disabled-border-color: #410073;
  --gradient: none;
}

.btn-outline-primary {
  --btn-color: #1e76ce;
  --btn-border-color: #1e76ce;
  --btn-hover-color: #fff;
  --btn-hover-bg: #1e76ce;
  --btn-hover-border-color: #1e76ce;
  --btn-focus-shadow-rgb: 30, 118, 206;
  --btn-active-color: #fff;
  --btn-active-bg: #1e76ce;
  --btn-active-border-color: #1e76ce;
  --btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --btn-disabled-color: #1e76ce;
  --btn-disabled-bg: transparent;
  --btn-disabled-border-color: #1e76ce;
  --gradient: none;
}

.btn-outline-secondary {
  --btn-color: #c6c6c6;
  --btn-border-color: #c6c6c6;
  --btn-hover-color: #373737;
  --btn-hover-bg: #c6c6c6;
  --btn-hover-border-color: #c6c6c6;
  --btn-focus-shadow-rgb: 198, 198, 198;
  --btn-active-color: #373737;
  --btn-active-bg: #c6c6c6;
  --btn-active-border-color: #c6c6c6;
  --btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --btn-disabled-color: #c6c6c6;
  --btn-disabled-bg: transparent;
  --btn-disabled-border-color: #c6c6c6;
  --gradient: none;
}

.btn-outline-success {
  --btn-color: #43811a;
  --btn-border-color: #43811a;
  --btn-hover-color: #fff;
  --btn-hover-bg: #43811a;
  --btn-hover-border-color: #43811a;
  --btn-focus-shadow-rgb: 67, 129, 26;
  --btn-active-color: #fff;
  --btn-active-bg: #43811a;
  --btn-active-border-color: #43811a;
  --btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --btn-disabled-color: #43811a;
  --btn-disabled-bg: transparent;
  --btn-disabled-border-color: #43811a;
  --gradient: none;
}

.btn-outline-info {
  --btn-color: #1e76ce;
  --btn-border-color: #1e76ce;
  --btn-hover-color: #fff;
  --btn-hover-bg: #1e76ce;
  --btn-hover-border-color: #1e76ce;
  --btn-focus-shadow-rgb: 30, 118, 206;
  --btn-active-color: #fff;
  --btn-active-bg: #1e76ce;
  --btn-active-border-color: #1e76ce;
  --btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --btn-disabled-color: #1e76ce;
  --btn-disabled-bg: transparent;
  --btn-disabled-border-color: #1e76ce;
  --gradient: none;
}

.btn-outline-warning {
  --btn-color: #ffa800;
  --btn-border-color: #ffa800;
  --btn-hover-color: #373737;
  --btn-hover-bg: #ffa800;
  --btn-hover-border-color: #ffa800;
  --btn-focus-shadow-rgb: 255, 168, 0;
  --btn-active-color: #373737;
  --btn-active-bg: #ffa800;
  --btn-active-border-color: #ffa800;
  --btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --btn-disabled-color: #ffa800;
  --btn-disabled-bg: transparent;
  --btn-disabled-border-color: #ffa800;
  --gradient: none;
}

.btn-outline-danger {
  --btn-color: #c41a1a;
  --btn-border-color: #c41a1a;
  --btn-hover-color: #fff;
  --btn-hover-bg: #c41a1a;
  --btn-hover-border-color: #c41a1a;
  --btn-focus-shadow-rgb: 196, 26, 26;
  --btn-active-color: #fff;
  --btn-active-bg: #c41a1a;
  --btn-active-border-color: #c41a1a;
  --btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --btn-disabled-color: #c41a1a;
  --btn-disabled-bg: transparent;
  --btn-disabled-border-color: #c41a1a;
  --gradient: none;
}

.btn-outline-light {
  --btn-color: #f3f3f3;
  --btn-border-color: #f3f3f3;
  --btn-hover-color: #373737;
  --btn-hover-bg: #f3f3f3;
  --btn-hover-border-color: #f3f3f3;
  --btn-focus-shadow-rgb: 243, 243, 243;
  --btn-active-color: #373737;
  --btn-active-bg: #f3f3f3;
  --btn-active-border-color: #f3f3f3;
  --btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --btn-disabled-color: #f3f3f3;
  --btn-disabled-bg: transparent;
  --btn-disabled-border-color: #f3f3f3;
  --gradient: none;
}

.btn-outline-dark {
  --btn-color: #797979;
  --btn-border-color: #797979;
  --btn-hover-color: #fff;
  --btn-hover-bg: #797979;
  --btn-hover-border-color: #797979;
  --btn-focus-shadow-rgb: 121, 121, 121;
  --btn-active-color: #fff;
  --btn-active-bg: #797979;
  --btn-active-border-color: #797979;
  --btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --btn-disabled-color: #797979;
  --btn-disabled-bg: transparent;
  --btn-disabled-border-color: #797979;
  --gradient: none;
}

.btn-link {
  --btn-font-weight: 400;
  --btn-color: var(--link-color);
  --btn-bg: transparent;
  --btn-border-color: transparent;
  --btn-hover-color: var(--link-hover-color);
  --btn-hover-border-color: transparent;
  --btn-active-color: var(--link-hover-color);
  --btn-active-border-color: transparent;
  --btn-disabled-color: #767676;
  --btn-disabled-border-color: transparent;
  --btn-box-shadow: 0 0 0 #000;
  --btn-focus-shadow-rgb: 64, 139, 213;
  text-decoration: underline;
}
.btn-link:hover, .btn-link:focus-visible {
  text-decoration: underline;
}
.btn-link:focus-visible {
  color: var(--btn-color);
}
.btn-link:hover {
  color: var(--btn-hover-color);
}

.btn-lg, .btn-group-lg > .btn {
  --btn-padding-y: 1.6725rem;
  --btn-padding-x: 1.6725rem;
  --btn-font-size: 1.25rem;
  --btn-border-radius: 50rem;
}

.btn-sm, .btn-group-sm > .btn {
  --btn-padding-y: 0.3333333333rem;
  --btn-padding-x: 0.3333333333rem;
  --btn-font-size: 0.75rem;
  --btn-border-radius: 50rem;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}

.dropup,
.dropend,
.dropdown,
.dropstart,
.dropup-center,
.dropdown-center {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  --dropdown-zindex: 1000;
  --dropdown-min-width: 16rem;
  --dropdown-padding-x: 0;
  --dropdown-padding-y: 0.5rem;
  --dropdown-spacer: 0.5rem;
  --dropdown-font-size: 1rem;
  --dropdown-color: #000;
  --dropdown-bg: #fff;
  --dropdown-border-color: transparent;
  --dropdown-border-radius: 0.5rem;
  --dropdown-border-width: 0;
  --dropdown-inner-border-radius: 0.5rem;
  --dropdown-divider-bg: #c6c6c6;
  --dropdown-divider-margin-y: 0.5rem;
  --dropdown-box-shadow: 0 8px 28px rgba(0, 0, 0, 0.12), 0 0.5px 0.5px rgba(0, 0, 0, 0.12);
  --dropdown-link-color: #373737;
  --dropdown-link-hover-color: #5fb624;
  --dropdown-link-hover-bg: #fff;
  --dropdown-link-active-color: #5fb624;
  --dropdown-link-active-bg: #fff;
  --dropdown-link-disabled-color: #767676;
  --dropdown-item-padding-x: 1rem;
  --dropdown-item-padding-y: 1rem;
  --dropdown-header-color: #767676;
  --dropdown-header-padding-x: 1rem;
  --dropdown-header-padding-y: 0.5rem;
  position: absolute;
  z-index: var(--dropdown-zindex);
  display: none;
  min-width: var(--dropdown-min-width);
  padding: var(--dropdown-padding-y) var(--dropdown-padding-x);
  margin: 0;
  font-size: var(--dropdown-font-size);
  color: var(--dropdown-color);
  text-align: left;
  list-style: none;
  background-color: var(--dropdown-bg);
  background-clip: padding-box;
  border: var(--dropdown-border-width) solid var(--dropdown-border-color);
  border-radius: var(--dropdown-border-radius);
  box-shadow: var(--dropdown-box-shadow);
}
.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: var(--dropdown-spacer);
}

.dropdown-menu-start {
  --bs-position: start;
}
.dropdown-menu-start[data-bs-popper] {
  right: auto;
  left: 0;
}

.dropdown-menu-end {
  --bs-position: end;
}
.dropdown-menu-end[data-bs-popper] {
  right: 0;
  left: auto;
}

@media (min-width: 540px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }
  .dropdown-menu-sm-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-end {
    --bs-position: end;
  }
  .dropdown-menu-sm-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }
  .dropdown-menu-md-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-end {
    --bs-position: end;
  }
  .dropdown-menu-md-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 960px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }
  .dropdown-menu-lg-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-end {
    --bs-position: end;
  }
  .dropdown-menu-lg-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1140px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }
  .dropdown-menu-xl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-end {
    --bs-position: end;
  }
  .dropdown-menu-xl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: var(--dropdown-spacer);
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: var(--dropdown-spacer);
}
.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropend .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropend .dropdown-toggle::after {
  vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: var(--dropdown-spacer);
}
.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropstart .dropdown-toggle::after {
  display: none;
}
.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropstart .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: var(--dropdown-divider-margin-y) 0;
  overflow: hidden;
  border-top: 1px solid var(--dropdown-divider-bg);
  opacity: 1;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: var(--dropdown-item-padding-y) var(--dropdown-item-padding-x);
  clear: both;
  font-weight: 400;
  color: var(--dropdown-link-color);
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  border-radius: var(--dropdown-item-border-radius, 0);
}
.dropdown-item:hover, .dropdown-item:focus {
  color: var(--dropdown-link-hover-color);
  text-decoration: none;
  background-color: var(--dropdown-link-hover-bg);
}
.dropdown-item.active, .dropdown-item:active {
  color: var(--dropdown-link-active-color);
  text-decoration: none;
  background-color: var(--dropdown-link-active-bg);
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: var(--dropdown-link-disabled-color);
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: var(--dropdown-header-padding-y) var(--dropdown-header-padding-x);
  margin-bottom: 0;
  font-size: 0.75rem;
  color: var(--dropdown-header-color);
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: var(--dropdown-item-padding-y) var(--dropdown-item-padding-x);
  color: var(--dropdown-link-color);
}

.dropdown-menu-dark {
  --dropdown-color: #ababab;
  --dropdown-bg: #484848;
  --dropdown-border-color: transparent;
  --dropdown-box-shadow: ;
  --dropdown-link-color: #ababab;
  --dropdown-link-hover-color: #fff;
  --dropdown-divider-bg: #c6c6c6;
  --dropdown-link-hover-bg: rgba(255, 255, 255, 0.15);
  --dropdown-link-active-color: #5fb624;
  --dropdown-link-active-bg: #fff;
  --dropdown-link-disabled-color: #797979;
  --dropdown-header-color: #797979;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn-check:checked + .btn,
.btn-group > .btn-check:focus + .btn,
.btn-group > .btn:hover,
.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn-check:checked + .btn,
.btn-group-vertical > .btn-check:focus + .btn,
.btn-group-vertical > .btn:hover,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group {
  border-radius: 50rem;
}
.btn-group > :not(.btn-check:first-child) + .btn,
.btn-group > .btn-group:not(:first-child) {
  margin-left: calc(-1 * 0);
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn.dropdown-toggle-split:first-child,
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:nth-child(n+3),
.btn-group > :not(.btn-check) + .btn,
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.9375rem;
  padding-left: 0.9375rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropend .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.25rem;
  padding-left: 0.25rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 1.254375rem;
  padding-left: 1.254375rem;
}

.btn-group.show .dropdown-toggle {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.btn-group.show .dropdown-toggle.btn-link {
  box-shadow: none;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: calc(-1 * 0);
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn:nth-child(n+3),
.btn-group-vertical > :not(.btn-check) + .btn,
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav {
  --nav-link-padding-x: 1rem;
  --nav-link-padding-y: 1rem;
  --nav-link-font-weight: ;
  --nav-link-color: var(--link-color);
  --nav-link-hover-color: var(--link-hover-color);
  --nav-link-disabled-color: #767676;
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: var(--nav-link-padding-y) var(--nav-link-padding-x);
  font-size: var(--nav-link-font-size);
  font-weight: var(--nav-link-font-weight);
  color: var(--nav-link-color);
  text-decoration: none;
  background: none;
  border: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}
.nav-link:hover, .nav-link:focus {
  color: var(--nav-link-hover-color);
  text-decoration: none;
}
.nav-link:focus-visible {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(30, 118, 206, 0.25);
}
.nav-link.disabled, .nav-link:disabled {
  color: var(--nav-link-disabled-color);
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  --nav-tabs-border-width: 2px;
  --nav-tabs-border-color: #9b9b9b;
  --nav-tabs-border-radius: 0.5rem;
  --nav-tabs-link-hover-border-color: #c6c6c6 #c6c6c6 #9b9b9b;
  --nav-tabs-link-active-color: #5c5c5c;
  --nav-tabs-link-active-bg: #fff;
  --nav-tabs-link-active-border-color: #9b9b9b #9b9b9b #fff;
  border-bottom: var(--nav-tabs-border-width) solid var(--nav-tabs-border-color);
}
.nav-tabs .nav-link {
  margin-bottom: calc(-1 * var(--nav-tabs-border-width));
  border: var(--nav-tabs-border-width) solid transparent;
  border-top-left-radius: var(--nav-tabs-border-radius);
  border-top-right-radius: var(--nav-tabs-border-radius);
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  isolation: isolate;
  border-color: var(--nav-tabs-link-hover-border-color);
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: var(--nav-tabs-link-active-color);
  background-color: var(--nav-tabs-link-active-bg);
  border-color: var(--nav-tabs-link-active-border-color);
}
.nav-tabs .dropdown-menu {
  margin-top: calc(-1 * var(--nav-tabs-border-width));
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills {
  --nav-pills-border-radius: 2rem;
  --nav-pills-link-active-color: #fff;
  --nav-pills-link-active-bg: #5fb624;
}
.nav-pills .nav-link {
  border-radius: var(--nav-pills-border-radius);
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: var(--nav-pills-link-active-color);
  background-color: var(--nav-pills-link-active-bg);
}

.nav-underline {
  --nav-underline-gap: 1rem;
  --nav-underline-border-width: 0.125rem;
  --nav-underline-link-active-color: var(--emphasis-color);
  gap: var(--nav-underline-gap);
}
.nav-underline .nav-link {
  padding-right: 0;
  padding-left: 0;
  border-bottom: var(--nav-underline-border-width) solid transparent;
}
.nav-underline .nav-link:hover, .nav-underline .nav-link:focus {
  border-bottom-color: currentcolor;
}
.nav-underline .nav-link.active,
.nav-underline .show > .nav-link {
  font-weight: 700;
  color: var(--nav-underline-link-active-color);
  border-bottom-color: currentcolor;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-grow: 1;
  flex-basis: 0;
  text-align: center;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  --navbar-padding-x: 2rem;
  --navbar-padding-y: 0.5rem;
  --navbar-color: #373737;
  --navbar-hover-color: #1e76ce;
  --navbar-disabled-color: rgba(216, 216, 216, 0.5);
  --navbar-active-color: #5fb624;
  --navbar-brand-padding-y: 0;
  --navbar-brand-margin-end: 1rem;
  --navbar-brand-font-size: 1.25rem;
  --navbar-brand-color: #5fb624;
  --navbar-brand-hover-color: #5fb624;
  --navbar-nav-link-padding-x: 1rem;
  --navbar-toggler-padding-y: 0.25rem;
  --navbar-toggler-padding-x: 0.75rem;
  --navbar-toggler-font-size: 1rem;
  --navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='%23373737' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  --navbar-toggler-border-color: rgba(0, 0, 0, 0.1);
  --navbar-toggler-border-radius: 50rem;
  --navbar-toggler-focus-width: 0.125rem;
  --navbar-toggler-transition: box-shadow 0.15s ease-in-out;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: var(--navbar-padding-y) var(--navbar-padding-x);
}
.navbar > .container,
.navbar > .container-fluid,
.navbar > .container-sm,
.navbar > .container-md,
.navbar > .container-lg,
.navbar > .container-xl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  padding-top: var(--navbar-brand-padding-y);
  padding-bottom: var(--navbar-brand-padding-y);
  margin-right: var(--navbar-brand-margin-end);
  font-size: var(--navbar-brand-font-size);
  color: var(--navbar-brand-color);
  text-decoration: none;
  white-space: nowrap;
}
.navbar-brand:hover, .navbar-brand:focus {
  color: var(--navbar-brand-hover-color);
  text-decoration: none;
}

.navbar-nav {
  --nav-link-padding-x: 0;
  --nav-link-padding-y: 1rem;
  --nav-link-font-weight: ;
  --nav-link-color: var(--navbar-color);
  --nav-link-hover-color: var(--navbar-hover-color);
  --nav-link-disabled-color: var(--navbar-disabled-color);
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link.active, .navbar-nav .nav-link.show {
  color: var(--navbar-active-color);
}
.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  padding-top: 1rem;
  padding-bottom: 1rem;
  color: var(--navbar-color);
}
.navbar-text a,
.navbar-text a:hover,
.navbar-text a:focus {
  color: var(--navbar-active-color);
}

.navbar-collapse {
  flex-grow: 1;
  flex-basis: 100%;
  align-items: center;
}

.navbar-toggler {
  padding: var(--navbar-toggler-padding-y) var(--navbar-toggler-padding-x);
  font-size: var(--navbar-toggler-font-size);
  line-height: 1;
  color: var(--navbar-color);
  background-color: transparent;
  border: var(--border-width) solid var(--navbar-toggler-border-color);
  border-radius: var(--navbar-toggler-border-radius);
  transition: var(--navbar-toggler-transition);
}
@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}
.navbar-toggler:hover {
  text-decoration: none;
}
.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 var(--navbar-toggler-focus-width);
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-image: var(--navbar-toggler-icon-bg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.navbar-nav-scroll {
  max-height: var(--scroll-height, 75vh);
  overflow-y: auto;
}

@media (min-width: 540px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: var(--navbar-nav-link-padding-x);
    padding-left: var(--navbar-nav-link-padding-x);
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
  .navbar-expand-sm .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    box-shadow: none;
    transition: none;
  }
  .navbar-expand-sm .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-sm .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: var(--navbar-nav-link-padding-x);
    padding-left: var(--navbar-nav-link-padding-x);
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
  .navbar-expand-md .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    box-shadow: none;
    transition: none;
  }
  .navbar-expand-md .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-md .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 960px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: var(--navbar-nav-link-padding-x);
    padding-left: var(--navbar-nav-link-padding-x);
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
  .navbar-expand-lg .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    box-shadow: none;
    transition: none;
  }
  .navbar-expand-lg .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-lg .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1140px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: var(--navbar-nav-link-padding-x);
    padding-left: var(--navbar-nav-link-padding-x);
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    box-shadow: none;
    transition: none;
  }
  .navbar-expand-xl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: var(--navbar-nav-link-padding-x);
  padding-left: var(--navbar-nav-link-padding-x);
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}
.navbar-expand .offcanvas {
  position: static;
  z-index: auto;
  flex-grow: 1;
  width: auto !important;
  height: auto !important;
  visibility: visible !important;
  background-color: transparent !important;
  border: 0 !important;
  transform: none !important;
  box-shadow: none;
  transition: none;
}
.navbar-expand .offcanvas .offcanvas-header {
  display: none;
}
.navbar-expand .offcanvas .offcanvas-body {
  display: flex;
  flex-grow: 0;
  padding: 0;
  overflow-y: visible;
}

.navbar-dark,
.navbar[data-bs-theme=dark] {
  --navbar-color: rgba(255, 255, 255, 0.5);
  --navbar-hover-color: rgba(255, 255, 255, 0.75);
  --navbar-disabled-color: rgba(255, 255, 255, 0.25);
  --navbar-active-color: #fff;
  --navbar-brand-color: #fff;
  --navbar-brand-hover-color: #fff;
  --navbar-toggler-border-color: rgba(255, 255, 255, 0.1);
  --navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

[data-bs-theme=dark] .navbar-toggler-icon {
  --navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.card {
  --card-spacer-y: 1.5rem;
  --card-spacer-x: 1rem;
  --card-title-spacer-y: 0.5rem;
  --card-title-color: ;
  --card-subtitle-color: ;
  --card-border-width: 0;
  --card-border-color: #c6c6c6;
  --card-border-radius: 1.5rem;
  --card-box-shadow: none;
  --card-inner-border-radius: 1.5rem;
  --card-cap-padding-y: 1.5rem;
  --card-cap-padding-x: 1rem;
  --card-cap-bg: #fff;
  --card-cap-color: inherit;
  --card-height: ;
  --card-color: #000;
  --card-bg: #fff;
  --card-img-overlay-padding: 1.25rem;
  --card-group-margin: 1rem;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: var(--card-height);
  color: var(--body-color);
  word-wrap: break-word;
  background-color: var(--card-bg);
  background-clip: border-box;
  border: var(--card-border-width) solid var(--card-border-color);
  border-radius: var(--card-border-radius);
  box-shadow: var(--card-box-shadow);
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: var(--card-inner-border-radius);
  border-top-right-radius: var(--card-inner-border-radius);
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: var(--card-inner-border-radius);
  border-bottom-left-radius: var(--card-inner-border-radius);
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  padding: var(--card-spacer-y) var(--card-spacer-x);
  color: var(--card-color);
}

.card-title {
  margin-bottom: var(--card-title-spacer-y);
  color: var(--card-title-color);
}

.card-subtitle {
  margin-top: calc(-0.5 * var(--card-title-spacer-y));
  margin-bottom: 0;
  color: var(--card-subtitle-color);
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}
.card-link + .card-link {
  margin-left: var(--card-spacer-x);
}

.card-header {
  padding: var(--card-cap-padding-y) var(--card-cap-padding-x);
  margin-bottom: 0;
  color: var(--card-cap-color);
  background-color: var(--card-cap-bg);
  border-bottom: var(--card-border-width) solid var(--card-border-color);
}
.card-header:first-child {
  border-radius: var(--card-inner-border-radius) var(--card-inner-border-radius) 0 0;
}

.card-footer {
  padding: var(--card-cap-padding-y) var(--card-cap-padding-x);
  color: var(--card-cap-color);
  background-color: var(--card-cap-bg);
  border-top: var(--card-border-width) solid var(--card-border-color);
}
.card-footer:last-child {
  border-radius: 0 0 var(--card-inner-border-radius) var(--card-inner-border-radius);
}

.card-header-tabs {
  margin-right: calc(-0.5 * var(--card-cap-padding-x));
  margin-bottom: calc(-1 * var(--card-cap-padding-y));
  margin-left: calc(-0.5 * var(--card-cap-padding-x));
  border-bottom: 0;
}
.card-header-tabs .nav-link.active {
  background-color: var(--card-bg);
  border-bottom-color: var(--card-bg);
}

.card-header-pills {
  margin-right: calc(-0.5 * var(--card-cap-padding-x));
  margin-left: calc(-0.5 * var(--card-cap-padding-x));
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: var(--card-img-overlay-padding);
  border-radius: var(--card-inner-border-radius);
}

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: var(--card-inner-border-radius);
  border-top-right-radius: var(--card-inner-border-radius);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: var(--card-inner-border-radius);
  border-bottom-left-radius: var(--card-inner-border-radius);
}

.card-group > .card {
  margin-bottom: var(--card-group-margin);
}
@media (min-width: 540px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.pagination {
  --pagination-padding-x: 1rem;
  --pagination-padding-y: 1rem;
  --pagination-font-size: 1rem;
  --pagination-color: #797979;
  --pagination-bg: #fff;
  --pagination-border-width: 2px;
  --pagination-border-color: transparent;
  --pagination-border-radius: var(--border-radius);
  --pagination-hover-color: #fff;
  --pagination-hover-bg: #797979;
  --pagination-hover-border-color: #797979;
  --pagination-focus-color: var(--link-hover-color);
  --pagination-focus-bg: var(--secondary-bg);
  --pagination-focus-box-shadow: 0 0 0 0.125rem #fff, 0 0 0 0.25rem #1e76ce;
  --pagination-active-color: #fff;
  --pagination-active-bg: #797979;
  --pagination-active-border-color: #797979;
  --pagination-disabled-color: #9b9b9b;
  --pagination-disabled-bg: #fff;
  --pagination-disabled-border-color: transparent;
  display: flex;
  padding-left: 0;
  list-style: none;
}

.page-link {
  position: relative;
  display: block;
  padding: var(--pagination-padding-y) var(--pagination-padding-x);
  font-size: var(--pagination-font-size);
  color: var(--pagination-color);
  text-decoration: none;
  background-color: var(--pagination-bg);
  border: var(--pagination-border-width) solid var(--pagination-border-color);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}
.page-link:hover {
  z-index: 2;
  color: var(--pagination-hover-color);
  text-decoration: none;
  background-color: var(--pagination-hover-bg);
  border-color: var(--pagination-hover-border-color);
}
.page-link:focus {
  z-index: 3;
  color: var(--pagination-focus-color);
  background-color: var(--pagination-focus-bg);
  outline: 0;
  box-shadow: var(--pagination-focus-box-shadow);
}
.page-link.active, .active > .page-link {
  z-index: 3;
  color: var(--pagination-active-color);
  background-color: var(--pagination-active-bg);
  border-color: var(--pagination-active-border-color);
}
.page-link.disabled, .disabled > .page-link {
  color: var(--pagination-disabled-color);
  pointer-events: none;
  background-color: var(--pagination-disabled-bg);
  border-color: var(--pagination-disabled-border-color);
}

.page-item:not(:first-child) .page-link {
  margin-left: calc(-1 * 2px);
}
.page-item:first-child .page-link {
  border-top-left-radius: var(--pagination-border-radius);
  border-bottom-left-radius: var(--pagination-border-radius);
}
.page-item:last-child .page-link {
  border-top-right-radius: var(--pagination-border-radius);
  border-bottom-right-radius: var(--pagination-border-radius);
}

.pagination-lg {
  --pagination-padding-x: 1.25rem;
  --pagination-padding-y: 1.25rem;
  --pagination-font-size: 1.25rem;
  --pagination-border-radius: 1.5rem;
}

.pagination-sm {
  --pagination-padding-x: 0.75rem;
  --pagination-padding-y: 0.75rem;
  --pagination-font-size: 0.75rem;
  --pagination-border-radius: 0.5rem;
}

.alert {
  --alert-bg: transparent;
  --alert-padding-x: 1.25rem;
  --alert-padding-y: 0.75rem;
  --alert-margin-bottom: 1rem;
  --alert-color: inherit;
  --alert-border-color: transparent;
  --alert-border: 2px solid var(--alert-border-color);
  --alert-border-radius: 1.5rem;
  --alert-link-color: inherit;
  position: relative;
  padding: var(--alert-padding-y) var(--alert-padding-x);
  margin-bottom: var(--alert-margin-bottom);
  color: var(--alert-color);
  background-color: var(--alert-bg);
  border: var(--alert-border);
  border-radius: var(--alert-border-radius);
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
  color: var(--alert-link-color);
}

.alert-dismissible {
  padding-right: 3.75rem;
}
.alert-dismissible .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 0.9375rem 1.25rem;
}

.alert-brand {
  --alert-color: var(--brand-text-emphasis);
  --alert-bg: var(--brand-bg-subtle);
  --alert-border-color: var(--brand-border-subtle);
  --alert-link-color: var(--brand-text-emphasis);
}

.alert-brand-alt {
  --alert-color: var(--brand-alt-text-emphasis);
  --alert-bg: var(--brand-alt-bg-subtle);
  --alert-border-color: var(--brand-alt-border-subtle);
  --alert-link-color: var(--brand-alt-text-emphasis);
}

.alert-brand-purple {
  --alert-color: var(--brand-purple-text-emphasis);
  --alert-bg: var(--brand-purple-bg-subtle);
  --alert-border-color: var(--brand-purple-border-subtle);
  --alert-link-color: var(--brand-purple-text-emphasis);
}

.alert-primary {
  --alert-color: var(--primary-text-emphasis);
  --alert-bg: var(--primary-bg-subtle);
  --alert-border-color: var(--primary-border-subtle);
  --alert-link-color: var(--primary-text-emphasis);
}

.alert-secondary {
  --alert-color: var(--secondary-text-emphasis);
  --alert-bg: var(--secondary-bg-subtle);
  --alert-border-color: var(--secondary-border-subtle);
  --alert-link-color: var(--secondary-text-emphasis);
}

.alert-success {
  --alert-color: var(--success-text-emphasis);
  --alert-bg: var(--success-bg-subtle);
  --alert-border-color: var(--success-border-subtle);
  --alert-link-color: var(--success-text-emphasis);
}

.alert-info {
  --alert-color: var(--info-text-emphasis);
  --alert-bg: var(--info-bg-subtle);
  --alert-border-color: var(--info-border-subtle);
  --alert-link-color: var(--info-text-emphasis);
}

.alert-warning {
  --alert-color: var(--warning-text-emphasis);
  --alert-bg: var(--warning-bg-subtle);
  --alert-border-color: var(--warning-border-subtle);
  --alert-link-color: var(--warning-text-emphasis);
}

.alert-danger {
  --alert-color: var(--danger-text-emphasis);
  --alert-bg: var(--danger-bg-subtle);
  --alert-border-color: var(--danger-border-subtle);
  --alert-link-color: var(--danger-text-emphasis);
}

.alert-light {
  --alert-color: var(--light-text-emphasis);
  --alert-bg: var(--light-bg-subtle);
  --alert-border-color: var(--light-border-subtle);
  --alert-link-color: var(--light-text-emphasis);
}

.alert-dark {
  --alert-color: var(--dark-text-emphasis);
  --alert-bg: var(--dark-bg-subtle);
  --alert-border-color: var(--dark-border-subtle);
  --alert-link-color: var(--dark-text-emphasis);
}

.btn-close {
  --btn-close-color: #1e76ce;
  --btn-close-bg: url("data:image/svg+xml,%3csvg viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' version='1.1' id='svg4'%3e%3cdefs id='defs8' /%3e%3cpath d='m 23.262297,4.1024518 -7.859006,7.8591312 7.859006,7.934053 c 0.973073,0.898027 0.973073,2.469892 0,3.368077 -0.898027,0.973074 -2.469892,0.973074 -3.368077,0 l -7.859164,-7.859165 -7.9339728,7.859165 c -0.8981855,0.973074 -2.4700024,0.973074 -3.36818789,0 -0.97304237,-0.898185 -0.97304237,-2.47005 0,-3.368077 L 8.5920911,11.961583 0.73289531,4.1024518 c -0.97304237,-0.8981857 -0.97304237,-2.4700023 0,-3.36818798 0.89818549,-0.9730264 2.47000239,-0.9730264 3.36818789,0 L 12.035056,8.5933797 19.89422,0.73426382 c 0.898185,-0.9730264 2.47005,-0.9730264 3.368077,0 0.973073,0.89818568 0.973073,2.47000228 0,3.36818798 z' fill='%231e76ce' id='path2' style='stroke-width:1.59678' /%3e%3c/svg%3e");
  --btn-close-opacity: 1;
  --btn-close-hover-opacity: 0.75;
  --btn-close-focus-shadow: 0 0 0 0.25rem rgba(30, 118, 206, 0.25);
  --btn-close-focus-opacity: 1;
  --btn-close-disabled-opacity: 0.25;
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: var(--btn-close-color);
  background: transparent var(--btn-close-bg) center/1em auto no-repeat;
  filter: var(--btn-close-filter);
  border: 0;
  border-radius: 1.5rem;
  opacity: var(--btn-close-opacity);
}
.btn-close:hover {
  color: var(--btn-close-color);
  text-decoration: none;
  opacity: var(--btn-close-hover-opacity);
}
.btn-close:focus {
  outline: 0;
  box-shadow: var(--btn-close-focus-shadow);
  opacity: var(--btn-close-focus-opacity);
}
.btn-close:disabled, .btn-close.disabled {
  pointer-events: none;
  user-select: none;
  opacity: var(--btn-close-disabled-opacity);
}

.btn-close-white {
  --btn-close-filter: invert(1) grayscale(100%) brightness(200%);
}

:root,
[data-bs-theme=light] {
  --btn-close-filter: ;
}

[data-bs-theme=dark] {
  --btn-close-filter: invert(1) grayscale(100%) brightness(200%);
}

.modal {
  --modal-zindex: 1055;
  --modal-width: 500px;
  --modal-padding: 0 2rem 2rem;
  --modal-margin: 0.5rem;
  --modal-color: #5c5c5c;
  --modal-bg: #fff;
  --modal-border-color: rgba(0, 0, 0, 0.2);
  --modal-border-width: 0;
  --modal-border-radius: 1.5rem;
  --modal-box-shadow: none;
  --modal-inner-border-radius: 1.5rem;
  --modal-header-padding-x: 2rem;
  --modal-header-padding-y: 2rem;
  --modal-header-padding: 2rem 2rem 1.35rem;
  --modal-header-border-color: #c6c6c6;
  --modal-header-border-width: 0;
  --modal-title-line-height: 1.5;
  --modal-footer-gap: 0.5rem;
  --modal-footer-bg: ;
  --modal-footer-border-color: #c6c6c6;
  --modal-footer-border-width: 0;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--modal-zindex);
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: var(--modal-margin);
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transform: translate(0, -50px);
  transition: transform 0.3s ease-out;
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - var(--modal-margin) * 2);
}
.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - var(--modal-margin) * 2);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--modal-color);
  pointer-events: auto;
  background-color: var(--modal-bg);
  background-clip: padding-box;
  border: var(--modal-border-width) solid var(--modal-border-color);
  border-radius: var(--modal-border-radius);
  box-shadow: var(--modal-box-shadow);
  outline: 0;
}

.modal-backdrop {
  --backdrop-zindex: 1050;
  --backdrop-bg: #000;
  --backdrop-opacity: 0.6;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--backdrop-zindex);
  width: 100vw;
  height: 100vh;
  background-color: var(--backdrop-bg);
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: var(--backdrop-opacity);
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  padding: var(--modal-header-padding);
  border-bottom: var(--modal-header-border-width) solid var(--modal-header-border-color);
  border-top-left-radius: var(--modal-inner-border-radius);
  border-top-right-radius: var(--modal-inner-border-radius);
}
.modal-header .btn-close {
  padding: calc(var(--modal-header-padding-y) * 0.5) calc(var(--modal-header-padding-x) * 0.5);
  margin-top: calc(-0.5 * var(--modal-header-padding-y));
  margin-right: calc(-0.5 * var(--modal-header-padding-x));
  margin-bottom: calc(-0.5 * var(--modal-header-padding-y));
  margin-left: auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: var(--modal-title-line-height);
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: var(--modal-padding);
}

.modal-footer {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: calc(var(--modal-padding) - var(--modal-footer-gap) * 0.5);
  background-color: var(--modal-footer-bg);
  border-top: var(--modal-footer-border-width) solid var(--modal-footer-border-color);
  border-bottom-right-radius: var(--modal-inner-border-radius);
  border-bottom-left-radius: var(--modal-inner-border-radius);
}
.modal-footer > * {
  margin: calc(var(--modal-footer-gap) * 0.5);
}

@media (min-width: 540px) {
  .modal {
    --modal-margin: 11rem;
    --modal-box-shadow: none;
  }
  .modal-dialog {
    max-width: var(--modal-width);
    margin-right: auto;
    margin-left: auto;
  }
  .modal-sm {
    --modal-width: 300px;
  }
}
@media (min-width: 960px) {
  .modal-lg,
  .modal-xl {
    --modal-width: 800px;
  }
}
@media (min-width: 1140px) {
  .modal-xl {
    --modal-width: 1140px;
  }
}
.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}
.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}
.modal-fullscreen .modal-header,
.modal-fullscreen .modal-footer {
  border-radius: 0;
}
.modal-fullscreen .modal-body {
  overflow-y: auto;
}

@media (max-width: 539.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-header,
  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-header,
  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 959.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-header,
  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1139.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-header,
  .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
}
.popover {
  --popover-zindex: 1070;
  --popover-max-width: 276px;
  --popover-font-size: 0.875rem;
  --popover-bg: #fff;
  --popover-border-width: 2px;
  --popover-border-color: transparent;
  --popover-border-radius: 1rem;
  --popover-inner-border-radius: calc(1rem - 2px);
  --popover-box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.2);
  --popover-header-padding-x: 1rem;
  --popover-header-padding-y: 1rem;
  --popover-header-font-size: 1rem;
  --popover-header-color: #000;
  --popover-header-bg: #f7f7f7;
  --popover-body-padding-x: 1rem;
  --popover-body-padding-y: 1rem;
  --popover-body-color: #000;
  --popover-arrow-width: 1rem;
  --popover-arrow-height: 0.5rem;
  --popover-arrow-border: var(--popover-border-color);
  z-index: var(--popover-zindex);
  display: block;
  max-width: var(--popover-max-width);
  font-family: OpenSans, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 2;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--popover-font-size);
  word-wrap: break-word;
  background-color: var(--popover-bg);
  background-clip: padding-box;
  border: var(--popover-border-width) solid var(--popover-border-color);
  border-radius: var(--popover-border-radius);
  box-shadow: var(--popover-box-shadow);
}
.popover .popover-arrow {
  display: block;
  width: var(--popover-arrow-width);
  height: var(--popover-arrow-height);
}
.popover .popover-arrow::before, .popover .popover-arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
  border-width: 0;
}

.bs-popover-top > .popover-arrow, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow {
  bottom: calc(-1 * (var(--popover-arrow-height)) - var(--popover-border-width));
}
.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before, .bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  border-width: var(--popover-arrow-height) calc(var(--popover-arrow-width) * 0.5) 0;
}
.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before {
  bottom: 0;
  border-top-color: var(--popover-arrow-border);
}
.bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  bottom: var(--popover-border-width);
  border-top-color: var(--popover-bg);
}

/* rtl:begin:ignore */
.bs-popover-end > .popover-arrow, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow {
  left: calc(-1 * (var(--popover-arrow-height)) - var(--popover-border-width));
  width: var(--popover-arrow-height);
  height: var(--popover-arrow-width);
}
.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before, .bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  border-width: calc(var(--popover-arrow-width) * 0.5) var(--popover-arrow-height) calc(var(--popover-arrow-width) * 0.5) 0;
}
.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before {
  left: 0;
  border-right-color: var(--popover-arrow-border);
}
.bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  left: var(--popover-border-width);
  border-right-color: var(--popover-bg);
}

/* rtl:end:ignore */
.bs-popover-bottom > .popover-arrow, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow {
  top: calc(-1 * (var(--popover-arrow-height)) - var(--popover-border-width));
}
.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before, .bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  border-width: 0 calc(var(--popover-arrow-width) * 0.5) var(--popover-arrow-height);
}
.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before {
  top: 0;
  border-bottom-color: var(--popover-arrow-border);
}
.bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  top: var(--popover-border-width);
  border-bottom-color: var(--popover-bg);
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: var(--popover-arrow-width);
  margin-left: calc(-0.5 * var(--popover-arrow-width));
  content: "";
  border-bottom: var(--popover-border-width) solid var(--popover-header-bg);
}

/* rtl:begin:ignore */
.bs-popover-start > .popover-arrow, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow {
  right: calc(-1 * (var(--popover-arrow-height)) - var(--popover-border-width));
  width: var(--popover-arrow-height);
  height: var(--popover-arrow-width);
}
.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before, .bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  border-width: calc(var(--popover-arrow-width) * 0.5) 0 calc(var(--popover-arrow-width) * 0.5) var(--popover-arrow-height);
}
.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before {
  right: 0;
  border-left-color: var(--popover-arrow-border);
}
.bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  right: var(--popover-border-width);
  border-left-color: var(--popover-bg);
}

/* rtl:end:ignore */
.popover-header {
  padding: var(--popover-header-padding-y) var(--popover-header-padding-x);
  margin-bottom: 0;
  font-size: var(--popover-header-font-size);
  color: var(--popover-header-color);
  background-color: var(--popover-header-bg);
  border-bottom: var(--popover-border-width) solid var(--popover-border-color);
  border-top-left-radius: var(--popover-inner-border-radius);
  border-top-right-radius: var(--popover-inner-border-radius);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: var(--popover-body-padding-y) var(--popover-body-padding-x);
  color: var(--popover-body-color);
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-start,
  .carousel-fade .active.carousel-item-end {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #484848;
  text-align: center;
  background: none;
  filter: var(--carousel-control-icon-filter);
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #484848;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23484848' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e") /*rtl:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23484848' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e")*/;
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23484848' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e") /*rtl:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23484848' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e")*/;
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
}
.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: var(--carousel-indicator-active-bg);
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-bs-target] {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: var(--carousel-caption-color);
  text-align: center;
}

.carousel-dark {
  --carousel-indicator-active-bg: #000;
  --carousel-caption-color: #000;
  --carousel-control-icon-filter: invert(1) grayscale(100);
}

:root,
[data-bs-theme=light] {
  --carousel-indicator-active-bg: #fff;
  --carousel-caption-color: #fff;
  --carousel-control-icon-filter: ;
}

[data-bs-theme=dark] {
  --carousel-indicator-active-bg: #000;
  --carousel-caption-color: #000;
  --carousel-control-icon-filter: invert(1) grayscale(100);
}

.accordion {
  --accordion-color: #410073;
  --accordion-bg: #fff;
  --accordion-transition: border-radius 0.15s ease;
  --accordion-border-color: rgba(0, 0, 0, 0.125);
  --accordion-border-width: 0;
  --accordion-border-radius: 1.5rem;
  --accordion-inner-border-radius: 1.5rem;
  --accordion-btn-padding-x: 2rem;
  --accordion-btn-padding-y: 1.5rem;
  --accordion-btn-color: #410073;
  --accordion-btn-bg: #fff;
  --accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%231e76ce'%3e%3cpath d='M14 3.75V10.5H20.75C21.5469 10.5 22.25 11.2031 22.25 12C22.25 12.8438 21.5469 13.5 20.75 13.5H14V20.25C14 21.0938 13.2969 21.75 12.5 21.75C11.6562 21.75 11 21.0938 11 20.25V13.5H4.25C3.40625 13.5 2.75 12.8438 2.75 12C2.75 11.2031 3.40625 10.5 4.25 10.5H11V3.75C11 2.95312 11.6562 2.25 12.5 2.25C13.2969 2.25 14 2.95312 14 3.75Z'/%3e%3c/svg%3e");
  --accordion-btn-icon-width: 1.25rem;
  --accordion-btn-icon-transform: rotate(-180deg);
  --accordion-btn-icon-transition: transform 0.2s ease-in-out;
  --accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%231e76ce'%3e%3cpath d='M22.25 12C22.25 12.8438 21.5469 13.5 20.75 13.5H4.25C3.40625 13.5 2.75 12.8438 2.75 12C2.75 11.2031 3.40625 10.5 4.25 10.5H20.75C21.5469 10.5 22.25 11.2031 22.25 12Z'/%3e%3c/svg%3e");
  --accordion-btn-focus-box-shadow: none;
  --accordion-body-padding-x: 2rem;
  --accordion-body-padding-y: 1.5rem;
  --accordion-active-color: #410073;
  --accordion-active-bg: #fff;
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: var(--accordion-btn-padding-y) var(--accordion-btn-padding-x);
  font-size: 1rem;
  color: var(--accordion-btn-color);
  text-align: left;
  background-color: var(--accordion-btn-bg);
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: var(--accordion-transition);
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}
.accordion-button:not(.collapsed) {
  color: var(--accordion-active-color);
  background-color: var(--accordion-active-bg);
  box-shadow: inset 0 calc(-1 * var(--accordion-border-width)) 0 var(--accordion-border-color);
}
.accordion-button:not(.collapsed)::after {
  background-image: var(--accordion-btn-active-icon);
  transform: var(--accordion-btn-icon-transform);
}
.accordion-button::after {
  flex-shrink: 0;
  width: var(--accordion-btn-icon-width);
  height: var(--accordion-btn-icon-width);
  margin-left: auto;
  content: "";
  background-image: var(--accordion-btn-icon);
  background-repeat: no-repeat;
  background-size: var(--accordion-btn-icon-width);
  transition: var(--accordion-btn-icon-transition);
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button::after {
    transition: none;
  }
}
.accordion-button:hover {
  z-index: 2;
}
.accordion-button:focus {
  z-index: 3;
  outline: 0;
  box-shadow: var(--accordion-btn-focus-box-shadow);
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  color: var(--accordion-color);
  background-color: var(--accordion-bg);
  border: var(--accordion-border-width) solid var(--accordion-border-color);
}
.accordion-item:first-of-type {
  border-top-left-radius: var(--accordion-border-radius);
  border-top-right-radius: var(--accordion-border-radius);
}
.accordion-item:first-of-type > .accordion-header .accordion-button {
  border-top-left-radius: var(--accordion-inner-border-radius);
  border-top-right-radius: var(--accordion-inner-border-radius);
}
.accordion-item:not(:first-of-type) {
  border-top: 0;
}
.accordion-item:last-of-type {
  border-bottom-right-radius: var(--accordion-border-radius);
  border-bottom-left-radius: var(--accordion-border-radius);
}
.accordion-item:last-of-type > .accordion-header .accordion-button.collapsed {
  border-bottom-right-radius: var(--accordion-inner-border-radius);
  border-bottom-left-radius: var(--accordion-inner-border-radius);
}
.accordion-item:last-of-type > .accordion-collapse {
  border-bottom-right-radius: var(--accordion-border-radius);
  border-bottom-left-radius: var(--accordion-border-radius);
}

.accordion-body {
  padding: var(--accordion-body-padding-y) var(--accordion-body-padding-x);
}

.accordion-flush > .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}
.accordion-flush > .accordion-item:first-child {
  border-top: 0;
}
.accordion-flush > .accordion-item:last-child {
  border-bottom: 0;
}
.accordion-flush > .accordion-item > .accordion-collapse,
.accordion-flush > .accordion-item > .accordion-header .accordion-button,
.accordion-flush > .accordion-item > .accordion-header .accordion-button.collapsed {
  border-radius: 0;
}

[data-bs-theme=dark] .accordion-button::after {
  --accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%2378ade2'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708'/%3e%3c/svg%3e");
  --accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%2378ade2'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708'/%3e%3c/svg%3e");
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.text-bg-brand {
  color: #fff !important;
  background-color: RGBA(var(--brand-rgb), var(--bg-opacity, 1)) !important;
}

.text-bg-brand-alt {
  color: #fff !important;
  background-color: RGBA(var(--brand-alt-rgb), var(--bg-opacity, 1)) !important;
}

.text-bg-brand-purple {
  color: #fff !important;
  background-color: RGBA(var(--brand-purple-rgb), var(--bg-opacity, 1)) !important;
}

.text-bg-primary {
  color: #fff !important;
  background-color: RGBA(var(--primary-rgb), var(--bg-opacity, 1)) !important;
}

.text-bg-secondary {
  color: #373737 !important;
  background-color: RGBA(var(--secondary-rgb), var(--bg-opacity, 1)) !important;
}

.text-bg-success {
  color: #fff !important;
  background-color: RGBA(var(--success-rgb), var(--bg-opacity, 1)) !important;
}

.text-bg-info {
  color: #fff !important;
  background-color: RGBA(var(--info-rgb), var(--bg-opacity, 1)) !important;
}

.text-bg-warning {
  color: #373737 !important;
  background-color: RGBA(var(--warning-rgb), var(--bg-opacity, 1)) !important;
}

.text-bg-danger {
  color: #fff !important;
  background-color: RGBA(var(--danger-rgb), var(--bg-opacity, 1)) !important;
}

.text-bg-light {
  color: #373737 !important;
  background-color: RGBA(var(--light-rgb), var(--bg-opacity, 1)) !important;
}

.text-bg-dark {
  color: #fff !important;
  background-color: RGBA(var(--dark-rgb), var(--bg-opacity, 1)) !important;
}

.link-brand {
  color: RGBA(var(--brand-rgb), var(--link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--brand-rgb), var(--link-underline-opacity, 1)) !important;
}
.link-brand:hover, .link-brand:focus {
  color: RGBA(76, 146, 29, var(--link-opacity, 1)) !important;
  text-decoration-color: RGBA(76, 146, 29, var(--link-underline-opacity, 1)) !important;
}

.link-brand-alt {
  color: RGBA(var(--brand-alt-rgb), var(--link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--brand-alt-rgb), var(--link-underline-opacity, 1)) !important;
}
.link-brand-alt:hover, .link-brand-alt:focus {
  color: RGBA(52, 0, 92, var(--link-opacity, 1)) !important;
  text-decoration-color: RGBA(52, 0, 92, var(--link-underline-opacity, 1)) !important;
}

.link-brand-purple {
  color: RGBA(var(--brand-purple-rgb), var(--link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--brand-purple-rgb), var(--link-underline-opacity, 1)) !important;
}
.link-brand-purple:hover, .link-brand-purple:focus {
  color: RGBA(52, 0, 92, var(--link-opacity, 1)) !important;
  text-decoration-color: RGBA(52, 0, 92, var(--link-underline-opacity, 1)) !important;
}

.link-primary {
  color: RGBA(var(--primary-rgb), var(--link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--primary-rgb), var(--link-underline-opacity, 1)) !important;
}
.link-primary:hover, .link-primary:focus {
  color: RGBA(24, 94, 165, var(--link-opacity, 1)) !important;
  text-decoration-color: RGBA(24, 94, 165, var(--link-underline-opacity, 1)) !important;
}

.link-secondary {
  color: RGBA(var(--secondary-rgb), var(--link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--secondary-rgb), var(--link-underline-opacity, 1)) !important;
}
.link-secondary:hover, .link-secondary:focus {
  color: RGBA(209, 209, 209, var(--link-opacity, 1)) !important;
  text-decoration-color: RGBA(209, 209, 209, var(--link-underline-opacity, 1)) !important;
}

.link-success {
  color: RGBA(var(--success-rgb), var(--link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--success-rgb), var(--link-underline-opacity, 1)) !important;
}
.link-success:hover, .link-success:focus {
  color: RGBA(54, 103, 21, var(--link-opacity, 1)) !important;
  text-decoration-color: RGBA(54, 103, 21, var(--link-underline-opacity, 1)) !important;
}

.link-info {
  color: RGBA(var(--info-rgb), var(--link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--info-rgb), var(--link-underline-opacity, 1)) !important;
}
.link-info:hover, .link-info:focus {
  color: RGBA(24, 94, 165, var(--link-opacity, 1)) !important;
  text-decoration-color: RGBA(24, 94, 165, var(--link-underline-opacity, 1)) !important;
}

.link-warning {
  color: RGBA(var(--warning-rgb), var(--link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--warning-rgb), var(--link-underline-opacity, 1)) !important;
}
.link-warning:hover, .link-warning:focus {
  color: RGBA(255, 185, 51, var(--link-opacity, 1)) !important;
  text-decoration-color: RGBA(255, 185, 51, var(--link-underline-opacity, 1)) !important;
}

.link-danger {
  color: RGBA(var(--danger-rgb), var(--link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--danger-rgb), var(--link-underline-opacity, 1)) !important;
}
.link-danger:hover, .link-danger:focus {
  color: RGBA(157, 21, 21, var(--link-opacity, 1)) !important;
  text-decoration-color: RGBA(157, 21, 21, var(--link-underline-opacity, 1)) !important;
}

.link-light {
  color: RGBA(var(--light-rgb), var(--link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--light-rgb), var(--link-underline-opacity, 1)) !important;
}
.link-light:hover, .link-light:focus {
  color: RGBA(245, 245, 245, var(--link-opacity, 1)) !important;
  text-decoration-color: RGBA(245, 245, 245, var(--link-underline-opacity, 1)) !important;
}

.link-dark {
  color: RGBA(var(--dark-rgb), var(--link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--dark-rgb), var(--link-underline-opacity, 1)) !important;
}
.link-dark:hover, .link-dark:focus {
  color: RGBA(97, 97, 97, var(--link-opacity, 1)) !important;
  text-decoration-color: RGBA(97, 97, 97, var(--link-underline-opacity, 1)) !important;
}

.link-body-emphasis {
  color: RGBA(var(--emphasis-color-rgb), var(--link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--emphasis-color-rgb), var(--link-underline-opacity, 1)) !important;
}
.link-body-emphasis:hover, .link-body-emphasis:focus {
  color: RGBA(var(--emphasis-color-rgb), var(--link-opacity, 0.75)) !important;
  text-decoration-color: RGBA(var(--emphasis-color-rgb), var(--link-underline-opacity, 0.75)) !important;
}

.focus-ring:focus {
  outline: 0;
  box-shadow: var(--focus-ring-x, 0) var(--focus-ring-y, 0) var(--focus-ring-blur, 0) var(--focus-ring-width) var(--focus-ring-color);
}

.icon-link {
  display: inline-flex;
  gap: 0.375rem;
  align-items: center;
  text-decoration-color: rgba(var(--link-color-rgb), var(--link-opacity, 0.5));
  text-underline-offset: 0.25em;
  backface-visibility: hidden;
}
.icon-link > .bi {
  flex-shrink: 0;
  width: 1em;
  height: 1em;
  fill: currentcolor;
  transition: 0.2s ease-in-out transform;
}
@media (prefers-reduced-motion: reduce) {
  .icon-link > .bi {
    transition: none;
  }
}

.icon-link-hover:hover > .bi, .icon-link-hover:focus-visible > .bi {
  transform: var(--icon-link-transform, translate3d(0.25em, 0, 0));
}

.ratio {
  position: relative;
  width: 100%;
}
.ratio::before {
  display: block;
  padding-top: var(--aspect-ratio);
  content: "";
}
.ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ratio-1x1 {
  --aspect-ratio: 100%;
}

.ratio-4x3 {
  --aspect-ratio: 75%;
}

.ratio-16x9 {
  --aspect-ratio: 56.25%;
}

.ratio-21x9 {
  --aspect-ratio: 42.8571428571%;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020;
}

.sticky-bottom {
  position: sticky;
  bottom: 0;
  z-index: 1020;
}

@media (min-width: 540px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-sm-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-md-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 960px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-lg-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1140px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-xl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
.hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
}

.vstack {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch;
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}
.visually-hidden:not(caption),
.visually-hidden-focusable:not(:focus):not(:focus-within):not(caption) {
  position: absolute !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.vr {
  display: inline-block;
  align-self: stretch;
  width: var(--border-width);
  min-height: 1em;
  background-color: currentcolor;
  opacity: 0.9;
}

@keyframes progress-bar-stripes {
  0% {
    background-position-x: var(--progress-height);
  }
}
.progress,
.progress-stacked {
  --progress-height: 1rem;
  --progress-font-size: 0.75rem;
  --progress-bg: #fff;
  --progress-border-radius: 1.5rem;
  --progress-box-shadow: none;
  --progress-bar-color: #fff;
  --progress-bar-bg: #5fb624;
  --progress-bar-transition: width 0.6s ease;
  display: flex;
  height: var(--progress-height);
  overflow: hidden;
  font-size: var(--progress-font-size);
  background-color: var(--progress-bg);
  border-radius: var(--progress-border-radius);
  box-shadow: var(--progress-box-shadow);
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: var(--progress-bar-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--progress-bar-bg);
  transition: var(--progress-bar-transition);
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: var(--progress-height) var(--progress-height);
}

.progress-stacked > .progress {
  overflow: visible;
}

.progress-stacked > .progress > .progress-bar {
  width: 100%;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.offcanvas, .offcanvas-xl, .offcanvas-lg, .offcanvas-md, .offcanvas-sm {
  --offcanvas-zindex: 1045;
  --offcanvas-width: 400px;
  --offcanvas-height: 30vh;
  --offcanvas-padding-x: 0 2rem 2rem;
  --offcanvas-padding-y: 0 2rem 2rem;
  --offcanvas-color: var(--body-color);
  --offcanvas-bg: var(--body-bg);
  --offcanvas-border-width: 0;
  --offcanvas-border-color: rgba(0, 0, 0, 0.2);
  --offcanvas-box-shadow: none;
  --offcanvas-transition: transform 0.3s ease-in-out;
  --offcanvas-title-line-height: 1.5;
}

@media (max-width: 539.98px) {
  .offcanvas-sm {
    position: fixed;
    bottom: 0;
    z-index: var(--offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--offcanvas-color);
    visibility: hidden;
    background-color: var(--offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    box-shadow: var(--offcanvas-box-shadow);
    transition: var(--offcanvas-transition);
  }
}
@media (max-width: 539.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-sm {
    transition: none;
  }
}
@media (max-width: 539.98px) {
  .offcanvas-sm.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--offcanvas-width);
    border-right: var(--offcanvas-border-width) solid var(--offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-sm.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--offcanvas-width);
    border-left: var(--offcanvas-border-width) solid var(--offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-sm.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--offcanvas-height);
    max-height: 100%;
    border-bottom: var(--offcanvas-border-width) solid var(--offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-sm.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--offcanvas-height);
    max-height: 100%;
    border-top: var(--offcanvas-border-width) solid var(--offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-sm.showing, .offcanvas-sm.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-sm.showing, .offcanvas-sm.hiding, .offcanvas-sm.show {
    visibility: visible;
  }
}
@media (min-width: 540px) {
  .offcanvas-sm {
    --offcanvas-height: auto;
    --offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-sm .offcanvas-header {
    display: none;
  }
  .offcanvas-sm .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 767.98px) {
  .offcanvas-md {
    position: fixed;
    bottom: 0;
    z-index: var(--offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--offcanvas-color);
    visibility: hidden;
    background-color: var(--offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    box-shadow: var(--offcanvas-box-shadow);
    transition: var(--offcanvas-transition);
  }
}
@media (max-width: 767.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-md {
    transition: none;
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--offcanvas-width);
    border-right: var(--offcanvas-border-width) solid var(--offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-md.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--offcanvas-width);
    border-left: var(--offcanvas-border-width) solid var(--offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-md.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--offcanvas-height);
    max-height: 100%;
    border-bottom: var(--offcanvas-border-width) solid var(--offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-md.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--offcanvas-height);
    max-height: 100%;
    border-top: var(--offcanvas-border-width) solid var(--offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-md.showing, .offcanvas-md.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-md.showing, .offcanvas-md.hiding, .offcanvas-md.show {
    visibility: visible;
  }
}
@media (min-width: 768px) {
  .offcanvas-md {
    --offcanvas-height: auto;
    --offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-md .offcanvas-header {
    display: none;
  }
  .offcanvas-md .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 959.98px) {
  .offcanvas-lg {
    position: fixed;
    bottom: 0;
    z-index: var(--offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--offcanvas-color);
    visibility: hidden;
    background-color: var(--offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    box-shadow: var(--offcanvas-box-shadow);
    transition: var(--offcanvas-transition);
  }
}
@media (max-width: 959.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-lg {
    transition: none;
  }
}
@media (max-width: 959.98px) {
  .offcanvas-lg.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--offcanvas-width);
    border-right: var(--offcanvas-border-width) solid var(--offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-lg.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--offcanvas-width);
    border-left: var(--offcanvas-border-width) solid var(--offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-lg.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--offcanvas-height);
    max-height: 100%;
    border-bottom: var(--offcanvas-border-width) solid var(--offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-lg.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--offcanvas-height);
    max-height: 100%;
    border-top: var(--offcanvas-border-width) solid var(--offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-lg.showing, .offcanvas-lg.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-lg.showing, .offcanvas-lg.hiding, .offcanvas-lg.show {
    visibility: visible;
  }
}
@media (min-width: 960px) {
  .offcanvas-lg {
    --offcanvas-height: auto;
    --offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-lg .offcanvas-header {
    display: none;
  }
  .offcanvas-lg .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1139.98px) {
  .offcanvas-xl {
    position: fixed;
    bottom: 0;
    z-index: var(--offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--offcanvas-color);
    visibility: hidden;
    background-color: var(--offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    box-shadow: var(--offcanvas-box-shadow);
    transition: var(--offcanvas-transition);
  }
}
@media (max-width: 1139.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xl {
    transition: none;
  }
}
@media (max-width: 1139.98px) {
  .offcanvas-xl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--offcanvas-width);
    border-right: var(--offcanvas-border-width) solid var(--offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-xl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--offcanvas-width);
    border-left: var(--offcanvas-border-width) solid var(--offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-xl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--offcanvas-height);
    max-height: 100%;
    border-bottom: var(--offcanvas-border-width) solid var(--offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-xl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--offcanvas-height);
    max-height: 100%;
    border-top: var(--offcanvas-border-width) solid var(--offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-xl.showing, .offcanvas-xl.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-xl.showing, .offcanvas-xl.hiding, .offcanvas-xl.show {
    visibility: visible;
  }
}
@media (min-width: 1140px) {
  .offcanvas-xl {
    --offcanvas-height: auto;
    --offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-xl .offcanvas-header {
    display: none;
  }
  .offcanvas-xl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: var(--offcanvas-zindex);
  display: flex;
  flex-direction: column;
  max-width: 100%;
  color: var(--offcanvas-color);
  visibility: hidden;
  background-color: var(--offcanvas-bg);
  background-clip: padding-box;
  outline: 0;
  box-shadow: var(--offcanvas-box-shadow);
  transition: var(--offcanvas-transition);
}
@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    transition: none;
  }
}
.offcanvas.offcanvas-start {
  top: 0;
  left: 0;
  width: var(--offcanvas-width);
  border-right: var(--offcanvas-border-width) solid var(--offcanvas-border-color);
  transform: translateX(-100%);
}
.offcanvas.offcanvas-end {
  top: 0;
  right: 0;
  width: var(--offcanvas-width);
  border-left: var(--offcanvas-border-width) solid var(--offcanvas-border-color);
  transform: translateX(100%);
}
.offcanvas.offcanvas-top {
  top: 0;
  right: 0;
  left: 0;
  height: var(--offcanvas-height);
  max-height: 100%;
  border-bottom: var(--offcanvas-border-width) solid var(--offcanvas-border-color);
  transform: translateY(-100%);
}
.offcanvas.offcanvas-bottom {
  right: 0;
  left: 0;
  height: var(--offcanvas-height);
  max-height: 100%;
  border-top: var(--offcanvas-border-width) solid var(--offcanvas-border-color);
  transform: translateY(100%);
}
.offcanvas.showing, .offcanvas.show:not(.hiding) {
  transform: none;
}
.offcanvas.showing, .offcanvas.hiding, .offcanvas.show {
  visibility: visible;
}

.offcanvas-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.offcanvas-backdrop.fade {
  opacity: 0;
}
.offcanvas-backdrop.show {
  opacity: 0.6;
}

.offcanvas-header {
  display: flex;
  align-items: center;
  padding: var(--offcanvas-padding-y) var(--offcanvas-padding-x);
}
.offcanvas-header .btn-close {
  padding: calc(var(--offcanvas-padding-y) * 0.5) calc(var(--offcanvas-padding-x) * 0.5);
  margin-top: calc(-0.5 * var(--offcanvas-padding-y));
  margin-right: calc(-0.5 * var(--offcanvas-padding-x));
  margin-bottom: calc(-0.5 * var(--offcanvas-padding-y));
  margin-left: auto;
}

.offcanvas-title {
  margin-bottom: 0;
  line-height: var(--offcanvas-title-line-height);
}

.offcanvas-body {
  flex-grow: 1;
  padding: var(--offcanvas-padding-y) var(--offcanvas-padding-x);
  overflow-y: auto;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.object-fit-contain {
  object-fit: contain !important;
}

.object-fit-cover {
  object-fit: cover !important;
}

.object-fit-fill {
  object-fit: fill !important;
}

.object-fit-scale {
  object-fit: scale-down !important;
}

.object-fit-none {
  object-fit: none !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-25 {
  opacity: 0.25 !important;
}

.opacity-50 {
  opacity: 0.5 !important;
}

.opacity-75 {
  opacity: 0.75 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.overflow-x-auto {
  overflow-x: auto !important;
}

.overflow-x-hidden {
  overflow-x: hidden !important;
}

.overflow-x-visible {
  overflow-x: visible !important;
}

.overflow-x-scroll {
  overflow-x: scroll !important;
}

.overflow-y-auto {
  overflow-y: auto !important;
}

.overflow-y-hidden {
  overflow-y: hidden !important;
}

.overflow-y-visible {
  overflow-y: visible !important;
}

.overflow-y-scroll {
  overflow-y: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-inline-grid {
  display: inline-grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: var(--box-shadow) !important;
}

.shadow-sm {
  box-shadow: var(--box-shadow-sm) !important;
}

.shadow-lg {
  box-shadow: var(--box-shadow-lg) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.focus-ring-brand {
  --focus-ring-color: rgba(var(--brand-rgb), var(--focus-ring-opacity));
}

.focus-ring-brand-alt {
  --focus-ring-color: rgba(var(--brand-alt-rgb), var(--focus-ring-opacity));
}

.focus-ring-brand-purple {
  --focus-ring-color: rgba(var(--brand-purple-rgb), var(--focus-ring-opacity));
}

.focus-ring-primary {
  --focus-ring-color: rgba(var(--primary-rgb), var(--focus-ring-opacity));
}

.focus-ring-secondary {
  --focus-ring-color: rgba(var(--secondary-rgb), var(--focus-ring-opacity));
}

.focus-ring-success {
  --focus-ring-color: rgba(var(--success-rgb), var(--focus-ring-opacity));
}

.focus-ring-info {
  --focus-ring-color: rgba(var(--info-rgb), var(--focus-ring-opacity));
}

.focus-ring-warning {
  --focus-ring-color: rgba(var(--warning-rgb), var(--focus-ring-opacity));
}

.focus-ring-danger {
  --focus-ring-color: rgba(var(--danger-rgb), var(--focus-ring-opacity));
}

.focus-ring-light {
  --focus-ring-color: rgba(var(--light-rgb), var(--focus-ring-opacity));
}

.focus-ring-dark {
  --focus-ring-color: rgba(var(--dark-rgb), var(--focus-ring-opacity));
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: var(--border-width) var(--border-style) var(--border-color) !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: var(--border-width) var(--border-style) var(--border-color) !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: var(--border-width) var(--border-style) var(--border-color) !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: var(--border-width) var(--border-style) var(--border-color) !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: var(--border-width) var(--border-style) var(--border-color) !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-brand {
  --border-opacity: 1;
  border-color: rgba(var(--brand-rgb), var(--border-opacity)) !important;
}

.border-brand-alt {
  --border-opacity: 1;
  border-color: rgba(var(--brand-alt-rgb), var(--border-opacity)) !important;
}

.border-brand-purple {
  --border-opacity: 1;
  border-color: rgba(var(--brand-purple-rgb), var(--border-opacity)) !important;
}

.border-primary {
  --border-opacity: 1;
  border-color: rgba(var(--primary-rgb), var(--border-opacity)) !important;
}

.border-secondary {
  --border-opacity: 1;
  border-color: rgba(var(--secondary-rgb), var(--border-opacity)) !important;
}

.border-success {
  --border-opacity: 1;
  border-color: rgba(var(--success-rgb), var(--border-opacity)) !important;
}

.border-info {
  --border-opacity: 1;
  border-color: rgba(var(--info-rgb), var(--border-opacity)) !important;
}

.border-warning {
  --border-opacity: 1;
  border-color: rgba(var(--warning-rgb), var(--border-opacity)) !important;
}

.border-danger {
  --border-opacity: 1;
  border-color: rgba(var(--danger-rgb), var(--border-opacity)) !important;
}

.border-light {
  --border-opacity: 1;
  border-color: rgba(var(--light-rgb), var(--border-opacity)) !important;
}

.border-dark {
  --border-opacity: 1;
  border-color: rgba(var(--dark-rgb), var(--border-opacity)) !important;
}

.border-black {
  --border-opacity: 1;
  border-color: rgba(var(--black-rgb), var(--border-opacity)) !important;
}

.border-white {
  --border-opacity: 1;
  border-color: rgba(var(--white-rgb), var(--border-opacity)) !important;
}

.border-primary-subtle {
  border-color: var(--primary-border-subtle) !important;
}

.border-secondary-subtle {
  border-color: var(--secondary-border-subtle) !important;
}

.border-success-subtle {
  border-color: var(--success-border-subtle) !important;
}

.border-info-subtle {
  border-color: var(--info-border-subtle) !important;
}

.border-warning-subtle {
  border-color: var(--warning-border-subtle) !important;
}

.border-danger-subtle {
  border-color: var(--danger-border-subtle) !important;
}

.border-light-subtle {
  border-color: var(--light-border-subtle) !important;
}

.border-dark-subtle {
  border-color: var(--dark-border-subtle) !important;
}

.border-1 {
  border-width: 1px !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-3 {
  border-width: 3px !important;
}

.border-4 {
  border-width: 4px !important;
}

.border-5 {
  border-width: 5px !important;
}

.border-opacity-10 {
  --border-opacity: 0.1;
}

.border-opacity-25 {
  --border-opacity: 0.25;
}

.border-opacity-50 {
  --border-opacity: 0.5;
}

.border-opacity-75 {
  --border-opacity: 0.75;
}

.border-opacity-100 {
  --border-opacity: 1;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.5rem !important;
}

.m-2 {
  margin: 1rem !important;
}

.m-3 {
  margin: 1.5rem !important;
}

.m-4 {
  margin: 2rem !important;
}

.m-5 {
  margin: 2.5rem !important;
}

.m-6 {
  margin: 3rem !important;
}

.m-8 {
  margin: 4rem !important;
}

.m-10 {
  margin: 5rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-2 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-3 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-4 {
  margin-right: 2rem !important;
  margin-left: 2rem !important;
}

.mx-5 {
  margin-right: 2.5rem !important;
  margin-left: 2.5rem !important;
}

.mx-6 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.mx-8 {
  margin-right: 4rem !important;
  margin-left: 4rem !important;
}

.mx-10 {
  margin-right: 5rem !important;
  margin-left: 5rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-2 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-3 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-4 {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
}

.my-5 {
  margin-top: 2.5rem !important;
  margin-bottom: 2.5rem !important;
}

.my-6 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-8 {
  margin-top: 4rem !important;
  margin-bottom: 4rem !important;
}

.my-10 {
  margin-top: 5rem !important;
  margin-bottom: 5rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.5rem !important;
}

.mt-2 {
  margin-top: 1rem !important;
}

.mt-3 {
  margin-top: 1.5rem !important;
}

.mt-4 {
  margin-top: 2rem !important;
}

.mt-5 {
  margin-top: 2.5rem !important;
}

.mt-6 {
  margin-top: 3rem !important;
}

.mt-8 {
  margin-top: 4rem !important;
}

.mt-10 {
  margin-top: 5rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.5rem !important;
}

.me-2 {
  margin-right: 1rem !important;
}

.me-3 {
  margin-right: 1.5rem !important;
}

.me-4 {
  margin-right: 2rem !important;
}

.me-5 {
  margin-right: 2.5rem !important;
}

.me-6 {
  margin-right: 3rem !important;
}

.me-8 {
  margin-right: 4rem !important;
}

.me-10 {
  margin-right: 5rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.5rem !important;
}

.mb-2 {
  margin-bottom: 1rem !important;
}

.mb-3 {
  margin-bottom: 1.5rem !important;
}

.mb-4 {
  margin-bottom: 2rem !important;
}

.mb-5 {
  margin-bottom: 2.5rem !important;
}

.mb-6 {
  margin-bottom: 3rem !important;
}

.mb-8 {
  margin-bottom: 4rem !important;
}

.mb-10 {
  margin-bottom: 5rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.5rem !important;
}

.ms-2 {
  margin-left: 1rem !important;
}

.ms-3 {
  margin-left: 1.5rem !important;
}

.ms-4 {
  margin-left: 2rem !important;
}

.ms-5 {
  margin-left: 2.5rem !important;
}

.ms-6 {
  margin-left: 3rem !important;
}

.ms-8 {
  margin-left: 4rem !important;
}

.ms-10 {
  margin-left: 5rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.m-n1 {
  margin: -0.5rem !important;
}

.m-n2 {
  margin: -1rem !important;
}

.m-n3 {
  margin: -1.5rem !important;
}

.m-n4 {
  margin: -2rem !important;
}

.m-n5 {
  margin: -2.5rem !important;
}

.m-n6 {
  margin: -3rem !important;
}

.m-n8 {
  margin: -4rem !important;
}

.m-n10 {
  margin: -5rem !important;
}

.mx-n1 {
  margin-right: -0.5rem !important;
  margin-left: -0.5rem !important;
}

.mx-n2 {
  margin-right: -1rem !important;
  margin-left: -1rem !important;
}

.mx-n3 {
  margin-right: -1.5rem !important;
  margin-left: -1.5rem !important;
}

.mx-n4 {
  margin-right: -2rem !important;
  margin-left: -2rem !important;
}

.mx-n5 {
  margin-right: -2.5rem !important;
  margin-left: -2.5rem !important;
}

.mx-n6 {
  margin-right: -3rem !important;
  margin-left: -3rem !important;
}

.mx-n8 {
  margin-right: -4rem !important;
  margin-left: -4rem !important;
}

.mx-n10 {
  margin-right: -5rem !important;
  margin-left: -5rem !important;
}

.my-n1 {
  margin-top: -0.5rem !important;
  margin-bottom: -0.5rem !important;
}

.my-n2 {
  margin-top: -1rem !important;
  margin-bottom: -1rem !important;
}

.my-n3 {
  margin-top: -1.5rem !important;
  margin-bottom: -1.5rem !important;
}

.my-n4 {
  margin-top: -2rem !important;
  margin-bottom: -2rem !important;
}

.my-n5 {
  margin-top: -2.5rem !important;
  margin-bottom: -2.5rem !important;
}

.my-n6 {
  margin-top: -3rem !important;
  margin-bottom: -3rem !important;
}

.my-n8 {
  margin-top: -4rem !important;
  margin-bottom: -4rem !important;
}

.my-n10 {
  margin-top: -5rem !important;
  margin-bottom: -5rem !important;
}

.mt-n1 {
  margin-top: -0.5rem !important;
}

.mt-n2 {
  margin-top: -1rem !important;
}

.mt-n3 {
  margin-top: -1.5rem !important;
}

.mt-n4 {
  margin-top: -2rem !important;
}

.mt-n5 {
  margin-top: -2.5rem !important;
}

.mt-n6 {
  margin-top: -3rem !important;
}

.mt-n8 {
  margin-top: -4rem !important;
}

.mt-n10 {
  margin-top: -5rem !important;
}

.me-n1 {
  margin-right: -0.5rem !important;
}

.me-n2 {
  margin-right: -1rem !important;
}

.me-n3 {
  margin-right: -1.5rem !important;
}

.me-n4 {
  margin-right: -2rem !important;
}

.me-n5 {
  margin-right: -2.5rem !important;
}

.me-n6 {
  margin-right: -3rem !important;
}

.me-n8 {
  margin-right: -4rem !important;
}

.me-n10 {
  margin-right: -5rem !important;
}

.mb-n1 {
  margin-bottom: -0.5rem !important;
}

.mb-n2 {
  margin-bottom: -1rem !important;
}

.mb-n3 {
  margin-bottom: -1.5rem !important;
}

.mb-n4 {
  margin-bottom: -2rem !important;
}

.mb-n5 {
  margin-bottom: -2.5rem !important;
}

.mb-n6 {
  margin-bottom: -3rem !important;
}

.mb-n8 {
  margin-bottom: -4rem !important;
}

.mb-n10 {
  margin-bottom: -5rem !important;
}

.ms-n1 {
  margin-left: -0.5rem !important;
}

.ms-n2 {
  margin-left: -1rem !important;
}

.ms-n3 {
  margin-left: -1.5rem !important;
}

.ms-n4 {
  margin-left: -2rem !important;
}

.ms-n5 {
  margin-left: -2.5rem !important;
}

.ms-n6 {
  margin-left: -3rem !important;
}

.ms-n8 {
  margin-left: -4rem !important;
}

.ms-n10 {
  margin-left: -5rem !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.5rem !important;
}

.p-2 {
  padding: 1rem !important;
}

.p-3 {
  padding: 1.5rem !important;
}

.p-4 {
  padding: 2rem !important;
}

.p-5 {
  padding: 2.5rem !important;
}

.p-6 {
  padding: 3rem !important;
}

.p-8 {
  padding: 4rem !important;
}

.p-10 {
  padding: 5rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.px-2 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-3 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-4 {
  padding-right: 2rem !important;
  padding-left: 2rem !important;
}

.px-5 {
  padding-right: 2.5rem !important;
  padding-left: 2.5rem !important;
}

.px-6 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.px-8 {
  padding-right: 4rem !important;
  padding-left: 4rem !important;
}

.px-10 {
  padding-right: 5rem !important;
  padding-left: 5rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-2 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-3 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-4 {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}

.py-5 {
  padding-top: 2.5rem !important;
  padding-bottom: 2.5rem !important;
}

.py-6 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.py-8 {
  padding-top: 4rem !important;
  padding-bottom: 4rem !important;
}

.py-10 {
  padding-top: 5rem !important;
  padding-bottom: 5rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.5rem !important;
}

.pt-2 {
  padding-top: 1rem !important;
}

.pt-3 {
  padding-top: 1.5rem !important;
}

.pt-4 {
  padding-top: 2rem !important;
}

.pt-5 {
  padding-top: 2.5rem !important;
}

.pt-6 {
  padding-top: 3rem !important;
}

.pt-8 {
  padding-top: 4rem !important;
}

.pt-10 {
  padding-top: 5rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.5rem !important;
}

.pe-2 {
  padding-right: 1rem !important;
}

.pe-3 {
  padding-right: 1.5rem !important;
}

.pe-4 {
  padding-right: 2rem !important;
}

.pe-5 {
  padding-right: 2.5rem !important;
}

.pe-6 {
  padding-right: 3rem !important;
}

.pe-8 {
  padding-right: 4rem !important;
}

.pe-10 {
  padding-right: 5rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.5rem !important;
}

.pb-2 {
  padding-bottom: 1rem !important;
}

.pb-3 {
  padding-bottom: 1.5rem !important;
}

.pb-4 {
  padding-bottom: 2rem !important;
}

.pb-5 {
  padding-bottom: 2.5rem !important;
}

.pb-6 {
  padding-bottom: 3rem !important;
}

.pb-8 {
  padding-bottom: 4rem !important;
}

.pb-10 {
  padding-bottom: 5rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.5rem !important;
}

.ps-2 {
  padding-left: 1rem !important;
}

.ps-3 {
  padding-left: 1.5rem !important;
}

.ps-4 {
  padding-left: 2rem !important;
}

.ps-5 {
  padding-left: 2.5rem !important;
}

.ps-6 {
  padding-left: 3rem !important;
}

.ps-8 {
  padding-left: 4rem !important;
}

.ps-10 {
  padding-left: 5rem !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 0.5rem !important;
}

.gap-2 {
  gap: 1rem !important;
}

.gap-3 {
  gap: 1.5rem !important;
}

.gap-4 {
  gap: 2rem !important;
}

.gap-5 {
  gap: 2.5rem !important;
}

.gap-6 {
  gap: 3rem !important;
}

.gap-8 {
  gap: 4rem !important;
}

.gap-10 {
  gap: 5rem !important;
}

.row-gap-0 {
  row-gap: 0 !important;
}

.row-gap-1 {
  row-gap: 0.5rem !important;
}

.row-gap-2 {
  row-gap: 1rem !important;
}

.row-gap-3 {
  row-gap: 1.5rem !important;
}

.row-gap-4 {
  row-gap: 2rem !important;
}

.row-gap-5 {
  row-gap: 2.5rem !important;
}

.row-gap-6 {
  row-gap: 3rem !important;
}

.row-gap-8 {
  row-gap: 4rem !important;
}

.row-gap-10 {
  row-gap: 5rem !important;
}

.column-gap-0 {
  column-gap: 0 !important;
}

.column-gap-1 {
  column-gap: 0.5rem !important;
}

.column-gap-2 {
  column-gap: 1rem !important;
}

.column-gap-3 {
  column-gap: 1.5rem !important;
}

.column-gap-4 {
  column-gap: 2rem !important;
}

.column-gap-5 {
  column-gap: 2.5rem !important;
}

.column-gap-6 {
  column-gap: 3rem !important;
}

.column-gap-8 {
  column-gap: 4rem !important;
}

.column-gap-10 {
  column-gap: 5rem !important;
}

.font-monospace {
  font-family: var(--font-monospace) !important;
}

.fs-1 {
  font-size: 3rem !important;
}

.fs-2 {
  font-size: 2rem !important;
}

.fs-3 {
  font-size: 1.75rem !important;
}

.fs-4 {
  font-size: 1.25rem !important;
}

.fs-5 {
  font-size: 1rem !important;
}

.fs-6 {
  font-size: 1rem !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-light {
  font-weight: 400 !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-medium {
  font-weight: 600 !important;
}

.fw-semibold {
  font-weight: 600 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-bolder {
  font-weight: 700 !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.5 !important;
}

.lh-base {
  line-height: 2 !important;
}

.lh-lg {
  line-height: 1.875 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

/* rtl:end:remove */
.text-brand {
  --text-opacity: 1;
  color: rgba(var(--brand-rgb), var(--text-opacity)) !important;
}

.text-brand-alt {
  --text-opacity: 1;
  color: rgba(var(--brand-alt-rgb), var(--text-opacity)) !important;
}

.text-brand-purple {
  --text-opacity: 1;
  color: rgba(var(--brand-purple-rgb), var(--text-opacity)) !important;
}

.text-primary {
  --text-opacity: 1;
  color: rgba(var(--primary-rgb), var(--text-opacity)) !important;
}

.text-secondary {
  --text-opacity: 1;
  color: rgba(var(--secondary-rgb), var(--text-opacity)) !important;
}

.text-success {
  --text-opacity: 1;
  color: rgba(var(--success-rgb), var(--text-opacity)) !important;
}

.text-info {
  --text-opacity: 1;
  color: rgba(var(--info-rgb), var(--text-opacity)) !important;
}

.text-warning {
  --text-opacity: 1;
  color: rgba(var(--warning-rgb), var(--text-opacity)) !important;
}

.text-danger {
  --text-opacity: 1;
  color: rgba(var(--danger-rgb), var(--text-opacity)) !important;
}

.text-light {
  --text-opacity: 1;
  color: rgba(var(--light-rgb), var(--text-opacity)) !important;
}

.text-dark {
  --text-opacity: 1;
  color: rgba(var(--dark-rgb), var(--text-opacity)) !important;
}

.text-black {
  --text-opacity: 1;
  color: rgba(var(--black-rgb), var(--text-opacity)) !important;
}

.text-white {
  --text-opacity: 1;
  color: rgba(var(--white-rgb), var(--text-opacity)) !important;
}

.text-body {
  --text-opacity: 1;
  color: rgba(var(--body-color-rgb), var(--text-opacity)) !important;
}

.text-muted {
  --text-opacity: 1;
  color: var(--secondary-color) !important;
}

.text-black-50 {
  --text-opacity: 1;
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  --text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-body-secondary {
  --text-opacity: 1;
  color: var(--secondary-color) !important;
}

.text-body-tertiary {
  --text-opacity: 1;
  color: var(--tertiary-color) !important;
}

.text-body-emphasis {
  --text-opacity: 1;
  color: var(--emphasis-color) !important;
}

.text-reset {
  --text-opacity: 1;
  color: inherit !important;
}

.text-opacity-25 {
  --text-opacity: 0.25;
}

.text-opacity-50 {
  --text-opacity: 0.5;
}

.text-opacity-75 {
  --text-opacity: 0.75;
}

.text-opacity-100 {
  --text-opacity: 1;
}

.text-primary-emphasis {
  color: var(--primary-text-emphasis) !important;
}

.text-secondary-emphasis {
  color: var(--secondary-text-emphasis) !important;
}

.text-success-emphasis {
  color: var(--success-text-emphasis) !important;
}

.text-info-emphasis {
  color: var(--info-text-emphasis) !important;
}

.text-warning-emphasis {
  color: var(--warning-text-emphasis) !important;
}

.text-danger-emphasis {
  color: var(--danger-text-emphasis) !important;
}

.text-light-emphasis {
  color: var(--light-text-emphasis) !important;
}

.text-dark-emphasis {
  color: var(--dark-text-emphasis) !important;
}

.link-opacity-10 {
  --link-opacity: 0.1;
}

.link-opacity-10-hover:hover {
  --link-opacity: 0.1;
}

.link-opacity-25 {
  --link-opacity: 0.25;
}

.link-opacity-25-hover:hover {
  --link-opacity: 0.25;
}

.link-opacity-50 {
  --link-opacity: 0.5;
}

.link-opacity-50-hover:hover {
  --link-opacity: 0.5;
}

.link-opacity-75 {
  --link-opacity: 0.75;
}

.link-opacity-75-hover:hover {
  --link-opacity: 0.75;
}

.link-opacity-100 {
  --link-opacity: 1;
}

.link-opacity-100-hover:hover {
  --link-opacity: 1;
}

.link-offset-1 {
  text-underline-offset: 0.125em !important;
}

.link-offset-1-hover:hover {
  text-underline-offset: 0.125em !important;
}

.link-offset-2 {
  text-underline-offset: 0.25em !important;
}

.link-offset-2-hover:hover {
  text-underline-offset: 0.25em !important;
}

.link-offset-3 {
  text-underline-offset: 0.375em !important;
}

.link-offset-3-hover:hover {
  text-underline-offset: 0.375em !important;
}

.link-underline-brand {
  --link-underline-opacity: 1;
  text-decoration-color: rgba(var(--brand-rgb), var(--link-underline-opacity)) !important;
}

.link-underline-brand-alt {
  --link-underline-opacity: 1;
  text-decoration-color: rgba(var(--brand-alt-rgb), var(--link-underline-opacity)) !important;
}

.link-underline-brand-purple {
  --link-underline-opacity: 1;
  text-decoration-color: rgba(var(--brand-purple-rgb), var(--link-underline-opacity)) !important;
}

.link-underline-primary {
  --link-underline-opacity: 1;
  text-decoration-color: rgba(var(--primary-rgb), var(--link-underline-opacity)) !important;
}

.link-underline-secondary {
  --link-underline-opacity: 1;
  text-decoration-color: rgba(var(--secondary-rgb), var(--link-underline-opacity)) !important;
}

.link-underline-success {
  --link-underline-opacity: 1;
  text-decoration-color: rgba(var(--success-rgb), var(--link-underline-opacity)) !important;
}

.link-underline-info {
  --link-underline-opacity: 1;
  text-decoration-color: rgba(var(--info-rgb), var(--link-underline-opacity)) !important;
}

.link-underline-warning {
  --link-underline-opacity: 1;
  text-decoration-color: rgba(var(--warning-rgb), var(--link-underline-opacity)) !important;
}

.link-underline-danger {
  --link-underline-opacity: 1;
  text-decoration-color: rgba(var(--danger-rgb), var(--link-underline-opacity)) !important;
}

.link-underline-light {
  --link-underline-opacity: 1;
  text-decoration-color: rgba(var(--light-rgb), var(--link-underline-opacity)) !important;
}

.link-underline-dark {
  --link-underline-opacity: 1;
  text-decoration-color: rgba(var(--dark-rgb), var(--link-underline-opacity)) !important;
}

.link-underline {
  --link-underline-opacity: 1;
  text-decoration-color: rgba(var(--link-color-rgb), var(--link-underline-opacity, 1)) !important;
}

.link-underline-opacity-0 {
  --link-underline-opacity: 0;
}

.link-underline-opacity-0-hover:hover {
  --link-underline-opacity: 0;
}

.link-underline-opacity-10 {
  --link-underline-opacity: 0.1;
}

.link-underline-opacity-10-hover:hover {
  --link-underline-opacity: 0.1;
}

.link-underline-opacity-25 {
  --link-underline-opacity: 0.25;
}

.link-underline-opacity-25-hover:hover {
  --link-underline-opacity: 0.25;
}

.link-underline-opacity-50 {
  --link-underline-opacity: 0.5;
}

.link-underline-opacity-50-hover:hover {
  --link-underline-opacity: 0.5;
}

.link-underline-opacity-75 {
  --link-underline-opacity: 0.75;
}

.link-underline-opacity-75-hover:hover {
  --link-underline-opacity: 0.75;
}

.link-underline-opacity-100 {
  --link-underline-opacity: 1;
}

.link-underline-opacity-100-hover:hover {
  --link-underline-opacity: 1;
}

.bg-brand, .bg-brand-gradient {
  --bg-opacity: 1;
  background-color: rgba(var(--brand-rgb), var(--bg-opacity)) !important;
}

.bg-brand-alt {
  --bg-opacity: 1;
  background-color: rgba(var(--brand-alt-rgb), var(--bg-opacity)) !important;
}

.bg-brand-purple {
  --bg-opacity: 1;
  background-color: rgba(var(--brand-purple-rgb), var(--bg-opacity)) !important;
}

.bg-primary {
  --bg-opacity: 1;
  background-color: rgba(var(--primary-rgb), var(--bg-opacity)) !important;
}

.bg-secondary {
  --bg-opacity: 1;
  background-color: rgba(var(--secondary-rgb), var(--bg-opacity)) !important;
}

.bg-success {
  --bg-opacity: 1;
  background-color: rgba(var(--success-rgb), var(--bg-opacity)) !important;
}

.bg-info {
  --bg-opacity: 1;
  background-color: rgba(var(--info-rgb), var(--bg-opacity)) !important;
}

.bg-warning {
  --bg-opacity: 1;
  background-color: rgba(var(--warning-rgb), var(--bg-opacity)) !important;
}

.bg-danger {
  --bg-opacity: 1;
  background-color: rgba(var(--danger-rgb), var(--bg-opacity)) !important;
}

.bg-light {
  --bg-opacity: 1;
  background-color: rgba(var(--light-rgb), var(--bg-opacity)) !important;
}

.bg-dark {
  --bg-opacity: 1;
  background-color: rgba(var(--dark-rgb), var(--bg-opacity)) !important;
}

.bg-black {
  --bg-opacity: 1;
  background-color: rgba(var(--black-rgb), var(--bg-opacity)) !important;
}

.bg-white {
  --bg-opacity: 1;
  background-color: rgba(var(--white-rgb), var(--bg-opacity)) !important;
}

.bg-body {
  --bg-opacity: 1;
  background-color: rgba(var(--body-bg-rgb), var(--bg-opacity)) !important;
}

.bg-transparent {
  --bg-opacity: 1;
  background-color: transparent !important;
}

.bg-body-secondary {
  --bg-opacity: 1;
  background-color: rgba(var(--secondary-bg-rgb), var(--bg-opacity)) !important;
}

.bg-body-tertiary {
  --bg-opacity: 1;
  background-color: rgba(var(--tertiary-bg-rgb), var(--bg-opacity)) !important;
}

.bg-opacity-10 {
  --bg-opacity: 0.1;
}

.bg-opacity-25 {
  --bg-opacity: 0.25;
}

.bg-opacity-50 {
  --bg-opacity: 0.5;
}

.bg-opacity-75 {
  --bg-opacity: 0.75;
}

.bg-opacity-100 {
  --bg-opacity: 1;
}

.bg-primary-subtle {
  background-color: var(--primary-bg-subtle) !important;
}

.bg-secondary-subtle {
  background-color: var(--secondary-bg-subtle) !important;
}

.bg-success-subtle {
  background-color: var(--success-bg-subtle) !important;
}

.bg-info-subtle {
  background-color: var(--info-bg-subtle) !important;
}

.bg-warning-subtle {
  background-color: var(--warning-bg-subtle) !important;
}

.bg-danger-subtle {
  background-color: var(--danger-bg-subtle) !important;
}

.bg-light-subtle {
  background-color: var(--light-bg-subtle) !important;
}

.bg-dark-subtle {
  background-color: var(--dark-bg-subtle) !important;
}

.bg-gradient {
  background-image: var(--gradient) !important;
}

.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: var(--border-radius) !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: var(--border-radius-sm) !important;
}

.rounded-2 {
  border-radius: var(--border-radius) !important;
}

.rounded-3 {
  border-radius: var(--border-radius-lg) !important;
}

.rounded-4 {
  border-radius: var(--border-radius-xl) !important;
}

.rounded-5 {
  border-radius: var(--border-radius-xxl) !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: var(--border-radius-pill) !important;
}

.rounded-top {
  border-top-left-radius: var(--border-radius) !important;
  border-top-right-radius: var(--border-radius) !important;
}

.rounded-top-0 {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.rounded-top-1 {
  border-top-left-radius: var(--border-radius-sm) !important;
  border-top-right-radius: var(--border-radius-sm) !important;
}

.rounded-top-2 {
  border-top-left-radius: var(--border-radius) !important;
  border-top-right-radius: var(--border-radius) !important;
}

.rounded-top-3 {
  border-top-left-radius: var(--border-radius-lg) !important;
  border-top-right-radius: var(--border-radius-lg) !important;
}

.rounded-top-4 {
  border-top-left-radius: var(--border-radius-xl) !important;
  border-top-right-radius: var(--border-radius-xl) !important;
}

.rounded-top-5 {
  border-top-left-radius: var(--border-radius-xxl) !important;
  border-top-right-radius: var(--border-radius-xxl) !important;
}

.rounded-top-circle {
  border-top-left-radius: 50% !important;
  border-top-right-radius: 50% !important;
}

.rounded-top-pill {
  border-top-left-radius: var(--border-radius-pill) !important;
  border-top-right-radius: var(--border-radius-pill) !important;
}

.rounded-end {
  border-top-right-radius: var(--border-radius) !important;
  border-bottom-right-radius: var(--border-radius) !important;
}

.rounded-end-0 {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.rounded-end-1 {
  border-top-right-radius: var(--border-radius-sm) !important;
  border-bottom-right-radius: var(--border-radius-sm) !important;
}

.rounded-end-2 {
  border-top-right-radius: var(--border-radius) !important;
  border-bottom-right-radius: var(--border-radius) !important;
}

.rounded-end-3 {
  border-top-right-radius: var(--border-radius-lg) !important;
  border-bottom-right-radius: var(--border-radius-lg) !important;
}

.rounded-end-4 {
  border-top-right-radius: var(--border-radius-xl) !important;
  border-bottom-right-radius: var(--border-radius-xl) !important;
}

.rounded-end-5 {
  border-top-right-radius: var(--border-radius-xxl) !important;
  border-bottom-right-radius: var(--border-radius-xxl) !important;
}

.rounded-end-circle {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}

.rounded-end-pill {
  border-top-right-radius: var(--border-radius-pill) !important;
  border-bottom-right-radius: var(--border-radius-pill) !important;
}

.rounded-bottom {
  border-bottom-right-radius: var(--border-radius) !important;
  border-bottom-left-radius: var(--border-radius) !important;
}

.rounded-bottom-0 {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rounded-bottom-1 {
  border-bottom-right-radius: var(--border-radius-sm) !important;
  border-bottom-left-radius: var(--border-radius-sm) !important;
}

.rounded-bottom-2 {
  border-bottom-right-radius: var(--border-radius) !important;
  border-bottom-left-radius: var(--border-radius) !important;
}

.rounded-bottom-3 {
  border-bottom-right-radius: var(--border-radius-lg) !important;
  border-bottom-left-radius: var(--border-radius-lg) !important;
}

.rounded-bottom-4 {
  border-bottom-right-radius: var(--border-radius-xl) !important;
  border-bottom-left-radius: var(--border-radius-xl) !important;
}

.rounded-bottom-5 {
  border-bottom-right-radius: var(--border-radius-xxl) !important;
  border-bottom-left-radius: var(--border-radius-xxl) !important;
}

.rounded-bottom-circle {
  border-bottom-right-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}

.rounded-bottom-pill {
  border-bottom-right-radius: var(--border-radius-pill) !important;
  border-bottom-left-radius: var(--border-radius-pill) !important;
}

.rounded-start {
  border-bottom-left-radius: var(--border-radius) !important;
  border-top-left-radius: var(--border-radius) !important;
}

.rounded-start-0 {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
}

.rounded-start-1 {
  border-bottom-left-radius: var(--border-radius-sm) !important;
  border-top-left-radius: var(--border-radius-sm) !important;
}

.rounded-start-2 {
  border-bottom-left-radius: var(--border-radius) !important;
  border-top-left-radius: var(--border-radius) !important;
}

.rounded-start-3 {
  border-bottom-left-radius: var(--border-radius-lg) !important;
  border-top-left-radius: var(--border-radius-lg) !important;
}

.rounded-start-4 {
  border-bottom-left-radius: var(--border-radius-xl) !important;
  border-top-left-radius: var(--border-radius-xl) !important;
}

.rounded-start-5 {
  border-bottom-left-radius: var(--border-radius-xxl) !important;
  border-top-left-radius: var(--border-radius-xxl) !important;
}

.rounded-start-circle {
  border-bottom-left-radius: 50% !important;
  border-top-left-radius: 50% !important;
}

.rounded-start-pill {
  border-bottom-left-radius: var(--border-radius-pill) !important;
  border-top-left-radius: var(--border-radius-pill) !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

.z-n1 {
  z-index: -1 !important;
}

.z-0 {
  z-index: 0 !important;
}

.z-1 {
  z-index: 1 !important;
}

.z-2 {
  z-index: 2 !important;
}

.z-3 {
  z-index: 3 !important;
}

@media (min-width: 540px) {
  .float-sm-start {
    float: left !important;
  }
  .float-sm-end {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
  .object-fit-sm-contain {
    object-fit: contain !important;
  }
  .object-fit-sm-cover {
    object-fit: cover !important;
  }
  .object-fit-sm-fill {
    object-fit: fill !important;
  }
  .object-fit-sm-scale {
    object-fit: scale-down !important;
  }
  .object-fit-sm-none {
    object-fit: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-grid {
    display: grid !important;
  }
  .d-sm-inline-grid {
    display: inline-grid !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
  .d-sm-none {
    display: none !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
  .order-sm-first {
    order: -1 !important;
  }
  .order-sm-0 {
    order: 0 !important;
  }
  .order-sm-1 {
    order: 1 !important;
  }
  .order-sm-2 {
    order: 2 !important;
  }
  .order-sm-3 {
    order: 3 !important;
  }
  .order-sm-4 {
    order: 4 !important;
  }
  .order-sm-5 {
    order: 5 !important;
  }
  .order-sm-last {
    order: 6 !important;
  }
  .m-sm-0 {
    margin: 0 !important;
  }
  .m-sm-1 {
    margin: 0.5rem !important;
  }
  .m-sm-2 {
    margin: 1rem !important;
  }
  .m-sm-3 {
    margin: 1.5rem !important;
  }
  .m-sm-4 {
    margin: 2rem !important;
  }
  .m-sm-5 {
    margin: 2.5rem !important;
  }
  .m-sm-6 {
    margin: 3rem !important;
  }
  .m-sm-8 {
    margin: 4rem !important;
  }
  .m-sm-10 {
    margin: 5rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-sm-1 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-sm-2 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-sm-3 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-sm-4 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }
  .mx-sm-5 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }
  .mx-sm-6 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-sm-8 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }
  .mx-sm-10 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-sm-1 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-sm-2 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-sm-3 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-sm-4 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .my-sm-5 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }
  .my-sm-6 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-sm-8 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }
  .my-sm-10 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-sm-0 {
    margin-top: 0 !important;
  }
  .mt-sm-1 {
    margin-top: 0.5rem !important;
  }
  .mt-sm-2 {
    margin-top: 1rem !important;
  }
  .mt-sm-3 {
    margin-top: 1.5rem !important;
  }
  .mt-sm-4 {
    margin-top: 2rem !important;
  }
  .mt-sm-5 {
    margin-top: 2.5rem !important;
  }
  .mt-sm-6 {
    margin-top: 3rem !important;
  }
  .mt-sm-8 {
    margin-top: 4rem !important;
  }
  .mt-sm-10 {
    margin-top: 5rem !important;
  }
  .mt-sm-auto {
    margin-top: auto !important;
  }
  .me-sm-0 {
    margin-right: 0 !important;
  }
  .me-sm-1 {
    margin-right: 0.5rem !important;
  }
  .me-sm-2 {
    margin-right: 1rem !important;
  }
  .me-sm-3 {
    margin-right: 1.5rem !important;
  }
  .me-sm-4 {
    margin-right: 2rem !important;
  }
  .me-sm-5 {
    margin-right: 2.5rem !important;
  }
  .me-sm-6 {
    margin-right: 3rem !important;
  }
  .me-sm-8 {
    margin-right: 4rem !important;
  }
  .me-sm-10 {
    margin-right: 5rem !important;
  }
  .me-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-0 {
    margin-bottom: 0 !important;
  }
  .mb-sm-1 {
    margin-bottom: 0.5rem !important;
  }
  .mb-sm-2 {
    margin-bottom: 1rem !important;
  }
  .mb-sm-3 {
    margin-bottom: 1.5rem !important;
  }
  .mb-sm-4 {
    margin-bottom: 2rem !important;
  }
  .mb-sm-5 {
    margin-bottom: 2.5rem !important;
  }
  .mb-sm-6 {
    margin-bottom: 3rem !important;
  }
  .mb-sm-8 {
    margin-bottom: 4rem !important;
  }
  .mb-sm-10 {
    margin-bottom: 5rem !important;
  }
  .mb-sm-auto {
    margin-bottom: auto !important;
  }
  .ms-sm-0 {
    margin-left: 0 !important;
  }
  .ms-sm-1 {
    margin-left: 0.5rem !important;
  }
  .ms-sm-2 {
    margin-left: 1rem !important;
  }
  .ms-sm-3 {
    margin-left: 1.5rem !important;
  }
  .ms-sm-4 {
    margin-left: 2rem !important;
  }
  .ms-sm-5 {
    margin-left: 2.5rem !important;
  }
  .ms-sm-6 {
    margin-left: 3rem !important;
  }
  .ms-sm-8 {
    margin-left: 4rem !important;
  }
  .ms-sm-10 {
    margin-left: 5rem !important;
  }
  .ms-sm-auto {
    margin-left: auto !important;
  }
  .m-sm-n1 {
    margin: -0.5rem !important;
  }
  .m-sm-n2 {
    margin: -1rem !important;
  }
  .m-sm-n3 {
    margin: -1.5rem !important;
  }
  .m-sm-n4 {
    margin: -2rem !important;
  }
  .m-sm-n5 {
    margin: -2.5rem !important;
  }
  .m-sm-n6 {
    margin: -3rem !important;
  }
  .m-sm-n8 {
    margin: -4rem !important;
  }
  .m-sm-n10 {
    margin: -5rem !important;
  }
  .mx-sm-n1 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-sm-n2 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-sm-n3 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-sm-n4 {
    margin-right: -2rem !important;
    margin-left: -2rem !important;
  }
  .mx-sm-n5 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important;
  }
  .mx-sm-n6 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .mx-sm-n8 {
    margin-right: -4rem !important;
    margin-left: -4rem !important;
  }
  .mx-sm-n10 {
    margin-right: -5rem !important;
    margin-left: -5rem !important;
  }
  .my-sm-n1 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-sm-n2 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-sm-n3 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-sm-n4 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }
  .my-sm-n5 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }
  .my-sm-n6 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .my-sm-n8 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }
  .my-sm-n10 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }
  .mt-sm-n1 {
    margin-top: -0.5rem !important;
  }
  .mt-sm-n2 {
    margin-top: -1rem !important;
  }
  .mt-sm-n3 {
    margin-top: -1.5rem !important;
  }
  .mt-sm-n4 {
    margin-top: -2rem !important;
  }
  .mt-sm-n5 {
    margin-top: -2.5rem !important;
  }
  .mt-sm-n6 {
    margin-top: -3rem !important;
  }
  .mt-sm-n8 {
    margin-top: -4rem !important;
  }
  .mt-sm-n10 {
    margin-top: -5rem !important;
  }
  .me-sm-n1 {
    margin-right: -0.5rem !important;
  }
  .me-sm-n2 {
    margin-right: -1rem !important;
  }
  .me-sm-n3 {
    margin-right: -1.5rem !important;
  }
  .me-sm-n4 {
    margin-right: -2rem !important;
  }
  .me-sm-n5 {
    margin-right: -2.5rem !important;
  }
  .me-sm-n6 {
    margin-right: -3rem !important;
  }
  .me-sm-n8 {
    margin-right: -4rem !important;
  }
  .me-sm-n10 {
    margin-right: -5rem !important;
  }
  .mb-sm-n1 {
    margin-bottom: -0.5rem !important;
  }
  .mb-sm-n2 {
    margin-bottom: -1rem !important;
  }
  .mb-sm-n3 {
    margin-bottom: -1.5rem !important;
  }
  .mb-sm-n4 {
    margin-bottom: -2rem !important;
  }
  .mb-sm-n5 {
    margin-bottom: -2.5rem !important;
  }
  .mb-sm-n6 {
    margin-bottom: -3rem !important;
  }
  .mb-sm-n8 {
    margin-bottom: -4rem !important;
  }
  .mb-sm-n10 {
    margin-bottom: -5rem !important;
  }
  .ms-sm-n1 {
    margin-left: -0.5rem !important;
  }
  .ms-sm-n2 {
    margin-left: -1rem !important;
  }
  .ms-sm-n3 {
    margin-left: -1.5rem !important;
  }
  .ms-sm-n4 {
    margin-left: -2rem !important;
  }
  .ms-sm-n5 {
    margin-left: -2.5rem !important;
  }
  .ms-sm-n6 {
    margin-left: -3rem !important;
  }
  .ms-sm-n8 {
    margin-left: -4rem !important;
  }
  .ms-sm-n10 {
    margin-left: -5rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .p-sm-1 {
    padding: 0.5rem !important;
  }
  .p-sm-2 {
    padding: 1rem !important;
  }
  .p-sm-3 {
    padding: 1.5rem !important;
  }
  .p-sm-4 {
    padding: 2rem !important;
  }
  .p-sm-5 {
    padding: 2.5rem !important;
  }
  .p-sm-6 {
    padding: 3rem !important;
  }
  .p-sm-8 {
    padding: 4rem !important;
  }
  .p-sm-10 {
    padding: 5rem !important;
  }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-sm-1 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-sm-2 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-sm-3 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-sm-4 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }
  .px-sm-5 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }
  .px-sm-6 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-sm-8 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }
  .px-sm-10 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-sm-1 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-sm-2 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-sm-3 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-sm-4 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  .py-sm-5 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }
  .py-sm-6 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-sm-8 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }
  .py-sm-10 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  .pt-sm-0 {
    padding-top: 0 !important;
  }
  .pt-sm-1 {
    padding-top: 0.5rem !important;
  }
  .pt-sm-2 {
    padding-top: 1rem !important;
  }
  .pt-sm-3 {
    padding-top: 1.5rem !important;
  }
  .pt-sm-4 {
    padding-top: 2rem !important;
  }
  .pt-sm-5 {
    padding-top: 2.5rem !important;
  }
  .pt-sm-6 {
    padding-top: 3rem !important;
  }
  .pt-sm-8 {
    padding-top: 4rem !important;
  }
  .pt-sm-10 {
    padding-top: 5rem !important;
  }
  .pe-sm-0 {
    padding-right: 0 !important;
  }
  .pe-sm-1 {
    padding-right: 0.5rem !important;
  }
  .pe-sm-2 {
    padding-right: 1rem !important;
  }
  .pe-sm-3 {
    padding-right: 1.5rem !important;
  }
  .pe-sm-4 {
    padding-right: 2rem !important;
  }
  .pe-sm-5 {
    padding-right: 2.5rem !important;
  }
  .pe-sm-6 {
    padding-right: 3rem !important;
  }
  .pe-sm-8 {
    padding-right: 4rem !important;
  }
  .pe-sm-10 {
    padding-right: 5rem !important;
  }
  .pb-sm-0 {
    padding-bottom: 0 !important;
  }
  .pb-sm-1 {
    padding-bottom: 0.5rem !important;
  }
  .pb-sm-2 {
    padding-bottom: 1rem !important;
  }
  .pb-sm-3 {
    padding-bottom: 1.5rem !important;
  }
  .pb-sm-4 {
    padding-bottom: 2rem !important;
  }
  .pb-sm-5 {
    padding-bottom: 2.5rem !important;
  }
  .pb-sm-6 {
    padding-bottom: 3rem !important;
  }
  .pb-sm-8 {
    padding-bottom: 4rem !important;
  }
  .pb-sm-10 {
    padding-bottom: 5rem !important;
  }
  .ps-sm-0 {
    padding-left: 0 !important;
  }
  .ps-sm-1 {
    padding-left: 0.5rem !important;
  }
  .ps-sm-2 {
    padding-left: 1rem !important;
  }
  .ps-sm-3 {
    padding-left: 1.5rem !important;
  }
  .ps-sm-4 {
    padding-left: 2rem !important;
  }
  .ps-sm-5 {
    padding-left: 2.5rem !important;
  }
  .ps-sm-6 {
    padding-left: 3rem !important;
  }
  .ps-sm-8 {
    padding-left: 4rem !important;
  }
  .ps-sm-10 {
    padding-left: 5rem !important;
  }
  .gap-sm-0 {
    gap: 0 !important;
  }
  .gap-sm-1 {
    gap: 0.5rem !important;
  }
  .gap-sm-2 {
    gap: 1rem !important;
  }
  .gap-sm-3 {
    gap: 1.5rem !important;
  }
  .gap-sm-4 {
    gap: 2rem !important;
  }
  .gap-sm-5 {
    gap: 2.5rem !important;
  }
  .gap-sm-6 {
    gap: 3rem !important;
  }
  .gap-sm-8 {
    gap: 4rem !important;
  }
  .gap-sm-10 {
    gap: 5rem !important;
  }
  .row-gap-sm-0 {
    row-gap: 0 !important;
  }
  .row-gap-sm-1 {
    row-gap: 0.5rem !important;
  }
  .row-gap-sm-2 {
    row-gap: 1rem !important;
  }
  .row-gap-sm-3 {
    row-gap: 1.5rem !important;
  }
  .row-gap-sm-4 {
    row-gap: 2rem !important;
  }
  .row-gap-sm-5 {
    row-gap: 2.5rem !important;
  }
  .row-gap-sm-6 {
    row-gap: 3rem !important;
  }
  .row-gap-sm-8 {
    row-gap: 4rem !important;
  }
  .row-gap-sm-10 {
    row-gap: 5rem !important;
  }
  .column-gap-sm-0 {
    column-gap: 0 !important;
  }
  .column-gap-sm-1 {
    column-gap: 0.5rem !important;
  }
  .column-gap-sm-2 {
    column-gap: 1rem !important;
  }
  .column-gap-sm-3 {
    column-gap: 1.5rem !important;
  }
  .column-gap-sm-4 {
    column-gap: 2rem !important;
  }
  .column-gap-sm-5 {
    column-gap: 2.5rem !important;
  }
  .column-gap-sm-6 {
    column-gap: 3rem !important;
  }
  .column-gap-sm-8 {
    column-gap: 4rem !important;
  }
  .column-gap-sm-10 {
    column-gap: 5rem !important;
  }
  .text-sm-start {
    text-align: left !important;
  }
  .text-sm-end {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }
  .float-md-end {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
  .object-fit-md-contain {
    object-fit: contain !important;
  }
  .object-fit-md-cover {
    object-fit: cover !important;
  }
  .object-fit-md-fill {
    object-fit: fill !important;
  }
  .object-fit-md-scale {
    object-fit: scale-down !important;
  }
  .object-fit-md-none {
    object-fit: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-grid {
    display: grid !important;
  }
  .d-md-inline-grid {
    display: inline-grid !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
  .d-md-none {
    display: none !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
  .order-md-first {
    order: -1 !important;
  }
  .order-md-0 {
    order: 0 !important;
  }
  .order-md-1 {
    order: 1 !important;
  }
  .order-md-2 {
    order: 2 !important;
  }
  .order-md-3 {
    order: 3 !important;
  }
  .order-md-4 {
    order: 4 !important;
  }
  .order-md-5 {
    order: 5 !important;
  }
  .order-md-last {
    order: 6 !important;
  }
  .m-md-0 {
    margin: 0 !important;
  }
  .m-md-1 {
    margin: 0.5rem !important;
  }
  .m-md-2 {
    margin: 1rem !important;
  }
  .m-md-3 {
    margin: 1.5rem !important;
  }
  .m-md-4 {
    margin: 2rem !important;
  }
  .m-md-5 {
    margin: 2.5rem !important;
  }
  .m-md-6 {
    margin: 3rem !important;
  }
  .m-md-8 {
    margin: 4rem !important;
  }
  .m-md-10 {
    margin: 5rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-md-1 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-md-2 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-md-3 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-md-4 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }
  .mx-md-5 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }
  .mx-md-6 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-md-8 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }
  .mx-md-10 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-md-1 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-md-2 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-md-3 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-md-4 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .my-md-5 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }
  .my-md-6 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-md-8 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }
  .my-md-10 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-md-0 {
    margin-top: 0 !important;
  }
  .mt-md-1 {
    margin-top: 0.5rem !important;
  }
  .mt-md-2 {
    margin-top: 1rem !important;
  }
  .mt-md-3 {
    margin-top: 1.5rem !important;
  }
  .mt-md-4 {
    margin-top: 2rem !important;
  }
  .mt-md-5 {
    margin-top: 2.5rem !important;
  }
  .mt-md-6 {
    margin-top: 3rem !important;
  }
  .mt-md-8 {
    margin-top: 4rem !important;
  }
  .mt-md-10 {
    margin-top: 5rem !important;
  }
  .mt-md-auto {
    margin-top: auto !important;
  }
  .me-md-0 {
    margin-right: 0 !important;
  }
  .me-md-1 {
    margin-right: 0.5rem !important;
  }
  .me-md-2 {
    margin-right: 1rem !important;
  }
  .me-md-3 {
    margin-right: 1.5rem !important;
  }
  .me-md-4 {
    margin-right: 2rem !important;
  }
  .me-md-5 {
    margin-right: 2.5rem !important;
  }
  .me-md-6 {
    margin-right: 3rem !important;
  }
  .me-md-8 {
    margin-right: 4rem !important;
  }
  .me-md-10 {
    margin-right: 5rem !important;
  }
  .me-md-auto {
    margin-right: auto !important;
  }
  .mb-md-0 {
    margin-bottom: 0 !important;
  }
  .mb-md-1 {
    margin-bottom: 0.5rem !important;
  }
  .mb-md-2 {
    margin-bottom: 1rem !important;
  }
  .mb-md-3 {
    margin-bottom: 1.5rem !important;
  }
  .mb-md-4 {
    margin-bottom: 2rem !important;
  }
  .mb-md-5 {
    margin-bottom: 2.5rem !important;
  }
  .mb-md-6 {
    margin-bottom: 3rem !important;
  }
  .mb-md-8 {
    margin-bottom: 4rem !important;
  }
  .mb-md-10 {
    margin-bottom: 5rem !important;
  }
  .mb-md-auto {
    margin-bottom: auto !important;
  }
  .ms-md-0 {
    margin-left: 0 !important;
  }
  .ms-md-1 {
    margin-left: 0.5rem !important;
  }
  .ms-md-2 {
    margin-left: 1rem !important;
  }
  .ms-md-3 {
    margin-left: 1.5rem !important;
  }
  .ms-md-4 {
    margin-left: 2rem !important;
  }
  .ms-md-5 {
    margin-left: 2.5rem !important;
  }
  .ms-md-6 {
    margin-left: 3rem !important;
  }
  .ms-md-8 {
    margin-left: 4rem !important;
  }
  .ms-md-10 {
    margin-left: 5rem !important;
  }
  .ms-md-auto {
    margin-left: auto !important;
  }
  .m-md-n1 {
    margin: -0.5rem !important;
  }
  .m-md-n2 {
    margin: -1rem !important;
  }
  .m-md-n3 {
    margin: -1.5rem !important;
  }
  .m-md-n4 {
    margin: -2rem !important;
  }
  .m-md-n5 {
    margin: -2.5rem !important;
  }
  .m-md-n6 {
    margin: -3rem !important;
  }
  .m-md-n8 {
    margin: -4rem !important;
  }
  .m-md-n10 {
    margin: -5rem !important;
  }
  .mx-md-n1 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-md-n2 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-md-n3 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-md-n4 {
    margin-right: -2rem !important;
    margin-left: -2rem !important;
  }
  .mx-md-n5 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important;
  }
  .mx-md-n6 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .mx-md-n8 {
    margin-right: -4rem !important;
    margin-left: -4rem !important;
  }
  .mx-md-n10 {
    margin-right: -5rem !important;
    margin-left: -5rem !important;
  }
  .my-md-n1 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-md-n2 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-md-n3 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-md-n4 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }
  .my-md-n5 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }
  .my-md-n6 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .my-md-n8 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }
  .my-md-n10 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }
  .mt-md-n1 {
    margin-top: -0.5rem !important;
  }
  .mt-md-n2 {
    margin-top: -1rem !important;
  }
  .mt-md-n3 {
    margin-top: -1.5rem !important;
  }
  .mt-md-n4 {
    margin-top: -2rem !important;
  }
  .mt-md-n5 {
    margin-top: -2.5rem !important;
  }
  .mt-md-n6 {
    margin-top: -3rem !important;
  }
  .mt-md-n8 {
    margin-top: -4rem !important;
  }
  .mt-md-n10 {
    margin-top: -5rem !important;
  }
  .me-md-n1 {
    margin-right: -0.5rem !important;
  }
  .me-md-n2 {
    margin-right: -1rem !important;
  }
  .me-md-n3 {
    margin-right: -1.5rem !important;
  }
  .me-md-n4 {
    margin-right: -2rem !important;
  }
  .me-md-n5 {
    margin-right: -2.5rem !important;
  }
  .me-md-n6 {
    margin-right: -3rem !important;
  }
  .me-md-n8 {
    margin-right: -4rem !important;
  }
  .me-md-n10 {
    margin-right: -5rem !important;
  }
  .mb-md-n1 {
    margin-bottom: -0.5rem !important;
  }
  .mb-md-n2 {
    margin-bottom: -1rem !important;
  }
  .mb-md-n3 {
    margin-bottom: -1.5rem !important;
  }
  .mb-md-n4 {
    margin-bottom: -2rem !important;
  }
  .mb-md-n5 {
    margin-bottom: -2.5rem !important;
  }
  .mb-md-n6 {
    margin-bottom: -3rem !important;
  }
  .mb-md-n8 {
    margin-bottom: -4rem !important;
  }
  .mb-md-n10 {
    margin-bottom: -5rem !important;
  }
  .ms-md-n1 {
    margin-left: -0.5rem !important;
  }
  .ms-md-n2 {
    margin-left: -1rem !important;
  }
  .ms-md-n3 {
    margin-left: -1.5rem !important;
  }
  .ms-md-n4 {
    margin-left: -2rem !important;
  }
  .ms-md-n5 {
    margin-left: -2.5rem !important;
  }
  .ms-md-n6 {
    margin-left: -3rem !important;
  }
  .ms-md-n8 {
    margin-left: -4rem !important;
  }
  .ms-md-n10 {
    margin-left: -5rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .p-md-1 {
    padding: 0.5rem !important;
  }
  .p-md-2 {
    padding: 1rem !important;
  }
  .p-md-3 {
    padding: 1.5rem !important;
  }
  .p-md-4 {
    padding: 2rem !important;
  }
  .p-md-5 {
    padding: 2.5rem !important;
  }
  .p-md-6 {
    padding: 3rem !important;
  }
  .p-md-8 {
    padding: 4rem !important;
  }
  .p-md-10 {
    padding: 5rem !important;
  }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-md-1 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-md-2 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-md-3 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-md-4 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }
  .px-md-5 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }
  .px-md-6 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-md-8 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }
  .px-md-10 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-md-1 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-md-2 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-md-3 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-md-4 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  .py-md-5 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }
  .py-md-6 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-md-8 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }
  .py-md-10 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  .pt-md-0 {
    padding-top: 0 !important;
  }
  .pt-md-1 {
    padding-top: 0.5rem !important;
  }
  .pt-md-2 {
    padding-top: 1rem !important;
  }
  .pt-md-3 {
    padding-top: 1.5rem !important;
  }
  .pt-md-4 {
    padding-top: 2rem !important;
  }
  .pt-md-5 {
    padding-top: 2.5rem !important;
  }
  .pt-md-6 {
    padding-top: 3rem !important;
  }
  .pt-md-8 {
    padding-top: 4rem !important;
  }
  .pt-md-10 {
    padding-top: 5rem !important;
  }
  .pe-md-0 {
    padding-right: 0 !important;
  }
  .pe-md-1 {
    padding-right: 0.5rem !important;
  }
  .pe-md-2 {
    padding-right: 1rem !important;
  }
  .pe-md-3 {
    padding-right: 1.5rem !important;
  }
  .pe-md-4 {
    padding-right: 2rem !important;
  }
  .pe-md-5 {
    padding-right: 2.5rem !important;
  }
  .pe-md-6 {
    padding-right: 3rem !important;
  }
  .pe-md-8 {
    padding-right: 4rem !important;
  }
  .pe-md-10 {
    padding-right: 5rem !important;
  }
  .pb-md-0 {
    padding-bottom: 0 !important;
  }
  .pb-md-1 {
    padding-bottom: 0.5rem !important;
  }
  .pb-md-2 {
    padding-bottom: 1rem !important;
  }
  .pb-md-3 {
    padding-bottom: 1.5rem !important;
  }
  .pb-md-4 {
    padding-bottom: 2rem !important;
  }
  .pb-md-5 {
    padding-bottom: 2.5rem !important;
  }
  .pb-md-6 {
    padding-bottom: 3rem !important;
  }
  .pb-md-8 {
    padding-bottom: 4rem !important;
  }
  .pb-md-10 {
    padding-bottom: 5rem !important;
  }
  .ps-md-0 {
    padding-left: 0 !important;
  }
  .ps-md-1 {
    padding-left: 0.5rem !important;
  }
  .ps-md-2 {
    padding-left: 1rem !important;
  }
  .ps-md-3 {
    padding-left: 1.5rem !important;
  }
  .ps-md-4 {
    padding-left: 2rem !important;
  }
  .ps-md-5 {
    padding-left: 2.5rem !important;
  }
  .ps-md-6 {
    padding-left: 3rem !important;
  }
  .ps-md-8 {
    padding-left: 4rem !important;
  }
  .ps-md-10 {
    padding-left: 5rem !important;
  }
  .gap-md-0 {
    gap: 0 !important;
  }
  .gap-md-1 {
    gap: 0.5rem !important;
  }
  .gap-md-2 {
    gap: 1rem !important;
  }
  .gap-md-3 {
    gap: 1.5rem !important;
  }
  .gap-md-4 {
    gap: 2rem !important;
  }
  .gap-md-5 {
    gap: 2.5rem !important;
  }
  .gap-md-6 {
    gap: 3rem !important;
  }
  .gap-md-8 {
    gap: 4rem !important;
  }
  .gap-md-10 {
    gap: 5rem !important;
  }
  .row-gap-md-0 {
    row-gap: 0 !important;
  }
  .row-gap-md-1 {
    row-gap: 0.5rem !important;
  }
  .row-gap-md-2 {
    row-gap: 1rem !important;
  }
  .row-gap-md-3 {
    row-gap: 1.5rem !important;
  }
  .row-gap-md-4 {
    row-gap: 2rem !important;
  }
  .row-gap-md-5 {
    row-gap: 2.5rem !important;
  }
  .row-gap-md-6 {
    row-gap: 3rem !important;
  }
  .row-gap-md-8 {
    row-gap: 4rem !important;
  }
  .row-gap-md-10 {
    row-gap: 5rem !important;
  }
  .column-gap-md-0 {
    column-gap: 0 !important;
  }
  .column-gap-md-1 {
    column-gap: 0.5rem !important;
  }
  .column-gap-md-2 {
    column-gap: 1rem !important;
  }
  .column-gap-md-3 {
    column-gap: 1.5rem !important;
  }
  .column-gap-md-4 {
    column-gap: 2rem !important;
  }
  .column-gap-md-5 {
    column-gap: 2.5rem !important;
  }
  .column-gap-md-6 {
    column-gap: 3rem !important;
  }
  .column-gap-md-8 {
    column-gap: 4rem !important;
  }
  .column-gap-md-10 {
    column-gap: 5rem !important;
  }
  .text-md-start {
    text-align: left !important;
  }
  .text-md-end {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 960px) {
  .float-lg-start {
    float: left !important;
  }
  .float-lg-end {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
  .object-fit-lg-contain {
    object-fit: contain !important;
  }
  .object-fit-lg-cover {
    object-fit: cover !important;
  }
  .object-fit-lg-fill {
    object-fit: fill !important;
  }
  .object-fit-lg-scale {
    object-fit: scale-down !important;
  }
  .object-fit-lg-none {
    object-fit: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-grid {
    display: grid !important;
  }
  .d-lg-inline-grid {
    display: inline-grid !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
  .d-lg-none {
    display: none !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
  .order-lg-first {
    order: -1 !important;
  }
  .order-lg-0 {
    order: 0 !important;
  }
  .order-lg-1 {
    order: 1 !important;
  }
  .order-lg-2 {
    order: 2 !important;
  }
  .order-lg-3 {
    order: 3 !important;
  }
  .order-lg-4 {
    order: 4 !important;
  }
  .order-lg-5 {
    order: 5 !important;
  }
  .order-lg-last {
    order: 6 !important;
  }
  .m-lg-0 {
    margin: 0 !important;
  }
  .m-lg-1 {
    margin: 0.5rem !important;
  }
  .m-lg-2 {
    margin: 1rem !important;
  }
  .m-lg-3 {
    margin: 1.5rem !important;
  }
  .m-lg-4 {
    margin: 2rem !important;
  }
  .m-lg-5 {
    margin: 2.5rem !important;
  }
  .m-lg-6 {
    margin: 3rem !important;
  }
  .m-lg-8 {
    margin: 4rem !important;
  }
  .m-lg-10 {
    margin: 5rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-lg-1 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-lg-2 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-lg-3 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-lg-4 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }
  .mx-lg-5 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }
  .mx-lg-6 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-lg-8 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }
  .mx-lg-10 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-lg-1 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-lg-2 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-lg-3 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-lg-4 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .my-lg-5 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }
  .my-lg-6 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-lg-8 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }
  .my-lg-10 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-lg-0 {
    margin-top: 0 !important;
  }
  .mt-lg-1 {
    margin-top: 0.5rem !important;
  }
  .mt-lg-2 {
    margin-top: 1rem !important;
  }
  .mt-lg-3 {
    margin-top: 1.5rem !important;
  }
  .mt-lg-4 {
    margin-top: 2rem !important;
  }
  .mt-lg-5 {
    margin-top: 2.5rem !important;
  }
  .mt-lg-6 {
    margin-top: 3rem !important;
  }
  .mt-lg-8 {
    margin-top: 4rem !important;
  }
  .mt-lg-10 {
    margin-top: 5rem !important;
  }
  .mt-lg-auto {
    margin-top: auto !important;
  }
  .me-lg-0 {
    margin-right: 0 !important;
  }
  .me-lg-1 {
    margin-right: 0.5rem !important;
  }
  .me-lg-2 {
    margin-right: 1rem !important;
  }
  .me-lg-3 {
    margin-right: 1.5rem !important;
  }
  .me-lg-4 {
    margin-right: 2rem !important;
  }
  .me-lg-5 {
    margin-right: 2.5rem !important;
  }
  .me-lg-6 {
    margin-right: 3rem !important;
  }
  .me-lg-8 {
    margin-right: 4rem !important;
  }
  .me-lg-10 {
    margin-right: 5rem !important;
  }
  .me-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-0 {
    margin-bottom: 0 !important;
  }
  .mb-lg-1 {
    margin-bottom: 0.5rem !important;
  }
  .mb-lg-2 {
    margin-bottom: 1rem !important;
  }
  .mb-lg-3 {
    margin-bottom: 1.5rem !important;
  }
  .mb-lg-4 {
    margin-bottom: 2rem !important;
  }
  .mb-lg-5 {
    margin-bottom: 2.5rem !important;
  }
  .mb-lg-6 {
    margin-bottom: 3rem !important;
  }
  .mb-lg-8 {
    margin-bottom: 4rem !important;
  }
  .mb-lg-10 {
    margin-bottom: 5rem !important;
  }
  .mb-lg-auto {
    margin-bottom: auto !important;
  }
  .ms-lg-0 {
    margin-left: 0 !important;
  }
  .ms-lg-1 {
    margin-left: 0.5rem !important;
  }
  .ms-lg-2 {
    margin-left: 1rem !important;
  }
  .ms-lg-3 {
    margin-left: 1.5rem !important;
  }
  .ms-lg-4 {
    margin-left: 2rem !important;
  }
  .ms-lg-5 {
    margin-left: 2.5rem !important;
  }
  .ms-lg-6 {
    margin-left: 3rem !important;
  }
  .ms-lg-8 {
    margin-left: 4rem !important;
  }
  .ms-lg-10 {
    margin-left: 5rem !important;
  }
  .ms-lg-auto {
    margin-left: auto !important;
  }
  .m-lg-n1 {
    margin: -0.5rem !important;
  }
  .m-lg-n2 {
    margin: -1rem !important;
  }
  .m-lg-n3 {
    margin: -1.5rem !important;
  }
  .m-lg-n4 {
    margin: -2rem !important;
  }
  .m-lg-n5 {
    margin: -2.5rem !important;
  }
  .m-lg-n6 {
    margin: -3rem !important;
  }
  .m-lg-n8 {
    margin: -4rem !important;
  }
  .m-lg-n10 {
    margin: -5rem !important;
  }
  .mx-lg-n1 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-lg-n2 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-lg-n3 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-lg-n4 {
    margin-right: -2rem !important;
    margin-left: -2rem !important;
  }
  .mx-lg-n5 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important;
  }
  .mx-lg-n6 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .mx-lg-n8 {
    margin-right: -4rem !important;
    margin-left: -4rem !important;
  }
  .mx-lg-n10 {
    margin-right: -5rem !important;
    margin-left: -5rem !important;
  }
  .my-lg-n1 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-lg-n2 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-lg-n3 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-lg-n4 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }
  .my-lg-n5 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }
  .my-lg-n6 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .my-lg-n8 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }
  .my-lg-n10 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }
  .mt-lg-n1 {
    margin-top: -0.5rem !important;
  }
  .mt-lg-n2 {
    margin-top: -1rem !important;
  }
  .mt-lg-n3 {
    margin-top: -1.5rem !important;
  }
  .mt-lg-n4 {
    margin-top: -2rem !important;
  }
  .mt-lg-n5 {
    margin-top: -2.5rem !important;
  }
  .mt-lg-n6 {
    margin-top: -3rem !important;
  }
  .mt-lg-n8 {
    margin-top: -4rem !important;
  }
  .mt-lg-n10 {
    margin-top: -5rem !important;
  }
  .me-lg-n1 {
    margin-right: -0.5rem !important;
  }
  .me-lg-n2 {
    margin-right: -1rem !important;
  }
  .me-lg-n3 {
    margin-right: -1.5rem !important;
  }
  .me-lg-n4 {
    margin-right: -2rem !important;
  }
  .me-lg-n5 {
    margin-right: -2.5rem !important;
  }
  .me-lg-n6 {
    margin-right: -3rem !important;
  }
  .me-lg-n8 {
    margin-right: -4rem !important;
  }
  .me-lg-n10 {
    margin-right: -5rem !important;
  }
  .mb-lg-n1 {
    margin-bottom: -0.5rem !important;
  }
  .mb-lg-n2 {
    margin-bottom: -1rem !important;
  }
  .mb-lg-n3 {
    margin-bottom: -1.5rem !important;
  }
  .mb-lg-n4 {
    margin-bottom: -2rem !important;
  }
  .mb-lg-n5 {
    margin-bottom: -2.5rem !important;
  }
  .mb-lg-n6 {
    margin-bottom: -3rem !important;
  }
  .mb-lg-n8 {
    margin-bottom: -4rem !important;
  }
  .mb-lg-n10 {
    margin-bottom: -5rem !important;
  }
  .ms-lg-n1 {
    margin-left: -0.5rem !important;
  }
  .ms-lg-n2 {
    margin-left: -1rem !important;
  }
  .ms-lg-n3 {
    margin-left: -1.5rem !important;
  }
  .ms-lg-n4 {
    margin-left: -2rem !important;
  }
  .ms-lg-n5 {
    margin-left: -2.5rem !important;
  }
  .ms-lg-n6 {
    margin-left: -3rem !important;
  }
  .ms-lg-n8 {
    margin-left: -4rem !important;
  }
  .ms-lg-n10 {
    margin-left: -5rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .p-lg-1 {
    padding: 0.5rem !important;
  }
  .p-lg-2 {
    padding: 1rem !important;
  }
  .p-lg-3 {
    padding: 1.5rem !important;
  }
  .p-lg-4 {
    padding: 2rem !important;
  }
  .p-lg-5 {
    padding: 2.5rem !important;
  }
  .p-lg-6 {
    padding: 3rem !important;
  }
  .p-lg-8 {
    padding: 4rem !important;
  }
  .p-lg-10 {
    padding: 5rem !important;
  }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-lg-1 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-lg-2 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-lg-3 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-lg-4 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }
  .px-lg-5 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }
  .px-lg-6 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-lg-8 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }
  .px-lg-10 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-lg-1 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-lg-2 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-lg-3 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-lg-4 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  .py-lg-5 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }
  .py-lg-6 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-lg-8 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }
  .py-lg-10 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  .pt-lg-0 {
    padding-top: 0 !important;
  }
  .pt-lg-1 {
    padding-top: 0.5rem !important;
  }
  .pt-lg-2 {
    padding-top: 1rem !important;
  }
  .pt-lg-3 {
    padding-top: 1.5rem !important;
  }
  .pt-lg-4 {
    padding-top: 2rem !important;
  }
  .pt-lg-5 {
    padding-top: 2.5rem !important;
  }
  .pt-lg-6 {
    padding-top: 3rem !important;
  }
  .pt-lg-8 {
    padding-top: 4rem !important;
  }
  .pt-lg-10 {
    padding-top: 5rem !important;
  }
  .pe-lg-0 {
    padding-right: 0 !important;
  }
  .pe-lg-1 {
    padding-right: 0.5rem !important;
  }
  .pe-lg-2 {
    padding-right: 1rem !important;
  }
  .pe-lg-3 {
    padding-right: 1.5rem !important;
  }
  .pe-lg-4 {
    padding-right: 2rem !important;
  }
  .pe-lg-5 {
    padding-right: 2.5rem !important;
  }
  .pe-lg-6 {
    padding-right: 3rem !important;
  }
  .pe-lg-8 {
    padding-right: 4rem !important;
  }
  .pe-lg-10 {
    padding-right: 5rem !important;
  }
  .pb-lg-0 {
    padding-bottom: 0 !important;
  }
  .pb-lg-1 {
    padding-bottom: 0.5rem !important;
  }
  .pb-lg-2 {
    padding-bottom: 1rem !important;
  }
  .pb-lg-3 {
    padding-bottom: 1.5rem !important;
  }
  .pb-lg-4 {
    padding-bottom: 2rem !important;
  }
  .pb-lg-5 {
    padding-bottom: 2.5rem !important;
  }
  .pb-lg-6 {
    padding-bottom: 3rem !important;
  }
  .pb-lg-8 {
    padding-bottom: 4rem !important;
  }
  .pb-lg-10 {
    padding-bottom: 5rem !important;
  }
  .ps-lg-0 {
    padding-left: 0 !important;
  }
  .ps-lg-1 {
    padding-left: 0.5rem !important;
  }
  .ps-lg-2 {
    padding-left: 1rem !important;
  }
  .ps-lg-3 {
    padding-left: 1.5rem !important;
  }
  .ps-lg-4 {
    padding-left: 2rem !important;
  }
  .ps-lg-5 {
    padding-left: 2.5rem !important;
  }
  .ps-lg-6 {
    padding-left: 3rem !important;
  }
  .ps-lg-8 {
    padding-left: 4rem !important;
  }
  .ps-lg-10 {
    padding-left: 5rem !important;
  }
  .gap-lg-0 {
    gap: 0 !important;
  }
  .gap-lg-1 {
    gap: 0.5rem !important;
  }
  .gap-lg-2 {
    gap: 1rem !important;
  }
  .gap-lg-3 {
    gap: 1.5rem !important;
  }
  .gap-lg-4 {
    gap: 2rem !important;
  }
  .gap-lg-5 {
    gap: 2.5rem !important;
  }
  .gap-lg-6 {
    gap: 3rem !important;
  }
  .gap-lg-8 {
    gap: 4rem !important;
  }
  .gap-lg-10 {
    gap: 5rem !important;
  }
  .row-gap-lg-0 {
    row-gap: 0 !important;
  }
  .row-gap-lg-1 {
    row-gap: 0.5rem !important;
  }
  .row-gap-lg-2 {
    row-gap: 1rem !important;
  }
  .row-gap-lg-3 {
    row-gap: 1.5rem !important;
  }
  .row-gap-lg-4 {
    row-gap: 2rem !important;
  }
  .row-gap-lg-5 {
    row-gap: 2.5rem !important;
  }
  .row-gap-lg-6 {
    row-gap: 3rem !important;
  }
  .row-gap-lg-8 {
    row-gap: 4rem !important;
  }
  .row-gap-lg-10 {
    row-gap: 5rem !important;
  }
  .column-gap-lg-0 {
    column-gap: 0 !important;
  }
  .column-gap-lg-1 {
    column-gap: 0.5rem !important;
  }
  .column-gap-lg-2 {
    column-gap: 1rem !important;
  }
  .column-gap-lg-3 {
    column-gap: 1.5rem !important;
  }
  .column-gap-lg-4 {
    column-gap: 2rem !important;
  }
  .column-gap-lg-5 {
    column-gap: 2.5rem !important;
  }
  .column-gap-lg-6 {
    column-gap: 3rem !important;
  }
  .column-gap-lg-8 {
    column-gap: 4rem !important;
  }
  .column-gap-lg-10 {
    column-gap: 5rem !important;
  }
  .text-lg-start {
    text-align: left !important;
  }
  .text-lg-end {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1140px) {
  .float-xl-start {
    float: left !important;
  }
  .float-xl-end {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
  .object-fit-xl-contain {
    object-fit: contain !important;
  }
  .object-fit-xl-cover {
    object-fit: cover !important;
  }
  .object-fit-xl-fill {
    object-fit: fill !important;
  }
  .object-fit-xl-scale {
    object-fit: scale-down !important;
  }
  .object-fit-xl-none {
    object-fit: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-grid {
    display: grid !important;
  }
  .d-xl-inline-grid {
    display: inline-grid !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
  .d-xl-none {
    display: none !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
  .order-xl-first {
    order: -1 !important;
  }
  .order-xl-0 {
    order: 0 !important;
  }
  .order-xl-1 {
    order: 1 !important;
  }
  .order-xl-2 {
    order: 2 !important;
  }
  .order-xl-3 {
    order: 3 !important;
  }
  .order-xl-4 {
    order: 4 !important;
  }
  .order-xl-5 {
    order: 5 !important;
  }
  .order-xl-last {
    order: 6 !important;
  }
  .m-xl-0 {
    margin: 0 !important;
  }
  .m-xl-1 {
    margin: 0.5rem !important;
  }
  .m-xl-2 {
    margin: 1rem !important;
  }
  .m-xl-3 {
    margin: 1.5rem !important;
  }
  .m-xl-4 {
    margin: 2rem !important;
  }
  .m-xl-5 {
    margin: 2.5rem !important;
  }
  .m-xl-6 {
    margin: 3rem !important;
  }
  .m-xl-8 {
    margin: 4rem !important;
  }
  .m-xl-10 {
    margin: 5rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xl-1 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xl-2 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xl-3 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xl-4 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }
  .mx-xl-5 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }
  .mx-xl-6 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xl-8 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }
  .mx-xl-10 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xl-1 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xl-2 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xl-3 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xl-4 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .my-xl-5 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }
  .my-xl-6 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xl-8 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }
  .my-xl-10 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xl-0 {
    margin-top: 0 !important;
  }
  .mt-xl-1 {
    margin-top: 0.5rem !important;
  }
  .mt-xl-2 {
    margin-top: 1rem !important;
  }
  .mt-xl-3 {
    margin-top: 1.5rem !important;
  }
  .mt-xl-4 {
    margin-top: 2rem !important;
  }
  .mt-xl-5 {
    margin-top: 2.5rem !important;
  }
  .mt-xl-6 {
    margin-top: 3rem !important;
  }
  .mt-xl-8 {
    margin-top: 4rem !important;
  }
  .mt-xl-10 {
    margin-top: 5rem !important;
  }
  .mt-xl-auto {
    margin-top: auto !important;
  }
  .me-xl-0 {
    margin-right: 0 !important;
  }
  .me-xl-1 {
    margin-right: 0.5rem !important;
  }
  .me-xl-2 {
    margin-right: 1rem !important;
  }
  .me-xl-3 {
    margin-right: 1.5rem !important;
  }
  .me-xl-4 {
    margin-right: 2rem !important;
  }
  .me-xl-5 {
    margin-right: 2.5rem !important;
  }
  .me-xl-6 {
    margin-right: 3rem !important;
  }
  .me-xl-8 {
    margin-right: 4rem !important;
  }
  .me-xl-10 {
    margin-right: 5rem !important;
  }
  .me-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xl-1 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xl-2 {
    margin-bottom: 1rem !important;
  }
  .mb-xl-3 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xl-4 {
    margin-bottom: 2rem !important;
  }
  .mb-xl-5 {
    margin-bottom: 2.5rem !important;
  }
  .mb-xl-6 {
    margin-bottom: 3rem !important;
  }
  .mb-xl-8 {
    margin-bottom: 4rem !important;
  }
  .mb-xl-10 {
    margin-bottom: 5rem !important;
  }
  .mb-xl-auto {
    margin-bottom: auto !important;
  }
  .ms-xl-0 {
    margin-left: 0 !important;
  }
  .ms-xl-1 {
    margin-left: 0.5rem !important;
  }
  .ms-xl-2 {
    margin-left: 1rem !important;
  }
  .ms-xl-3 {
    margin-left: 1.5rem !important;
  }
  .ms-xl-4 {
    margin-left: 2rem !important;
  }
  .ms-xl-5 {
    margin-left: 2.5rem !important;
  }
  .ms-xl-6 {
    margin-left: 3rem !important;
  }
  .ms-xl-8 {
    margin-left: 4rem !important;
  }
  .ms-xl-10 {
    margin-left: 5rem !important;
  }
  .ms-xl-auto {
    margin-left: auto !important;
  }
  .m-xl-n1 {
    margin: -0.5rem !important;
  }
  .m-xl-n2 {
    margin: -1rem !important;
  }
  .m-xl-n3 {
    margin: -1.5rem !important;
  }
  .m-xl-n4 {
    margin: -2rem !important;
  }
  .m-xl-n5 {
    margin: -2.5rem !important;
  }
  .m-xl-n6 {
    margin: -3rem !important;
  }
  .m-xl-n8 {
    margin: -4rem !important;
  }
  .m-xl-n10 {
    margin: -5rem !important;
  }
  .mx-xl-n1 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-xl-n2 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-xl-n3 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-xl-n4 {
    margin-right: -2rem !important;
    margin-left: -2rem !important;
  }
  .mx-xl-n5 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important;
  }
  .mx-xl-n6 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .mx-xl-n8 {
    margin-right: -4rem !important;
    margin-left: -4rem !important;
  }
  .mx-xl-n10 {
    margin-right: -5rem !important;
    margin-left: -5rem !important;
  }
  .my-xl-n1 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-xl-n2 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-xl-n3 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-xl-n4 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }
  .my-xl-n5 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }
  .my-xl-n6 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .my-xl-n8 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }
  .my-xl-n10 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }
  .mt-xl-n1 {
    margin-top: -0.5rem !important;
  }
  .mt-xl-n2 {
    margin-top: -1rem !important;
  }
  .mt-xl-n3 {
    margin-top: -1.5rem !important;
  }
  .mt-xl-n4 {
    margin-top: -2rem !important;
  }
  .mt-xl-n5 {
    margin-top: -2.5rem !important;
  }
  .mt-xl-n6 {
    margin-top: -3rem !important;
  }
  .mt-xl-n8 {
    margin-top: -4rem !important;
  }
  .mt-xl-n10 {
    margin-top: -5rem !important;
  }
  .me-xl-n1 {
    margin-right: -0.5rem !important;
  }
  .me-xl-n2 {
    margin-right: -1rem !important;
  }
  .me-xl-n3 {
    margin-right: -1.5rem !important;
  }
  .me-xl-n4 {
    margin-right: -2rem !important;
  }
  .me-xl-n5 {
    margin-right: -2.5rem !important;
  }
  .me-xl-n6 {
    margin-right: -3rem !important;
  }
  .me-xl-n8 {
    margin-right: -4rem !important;
  }
  .me-xl-n10 {
    margin-right: -5rem !important;
  }
  .mb-xl-n1 {
    margin-bottom: -0.5rem !important;
  }
  .mb-xl-n2 {
    margin-bottom: -1rem !important;
  }
  .mb-xl-n3 {
    margin-bottom: -1.5rem !important;
  }
  .mb-xl-n4 {
    margin-bottom: -2rem !important;
  }
  .mb-xl-n5 {
    margin-bottom: -2.5rem !important;
  }
  .mb-xl-n6 {
    margin-bottom: -3rem !important;
  }
  .mb-xl-n8 {
    margin-bottom: -4rem !important;
  }
  .mb-xl-n10 {
    margin-bottom: -5rem !important;
  }
  .ms-xl-n1 {
    margin-left: -0.5rem !important;
  }
  .ms-xl-n2 {
    margin-left: -1rem !important;
  }
  .ms-xl-n3 {
    margin-left: -1.5rem !important;
  }
  .ms-xl-n4 {
    margin-left: -2rem !important;
  }
  .ms-xl-n5 {
    margin-left: -2.5rem !important;
  }
  .ms-xl-n6 {
    margin-left: -3rem !important;
  }
  .ms-xl-n8 {
    margin-left: -4rem !important;
  }
  .ms-xl-n10 {
    margin-left: -5rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .p-xl-1 {
    padding: 0.5rem !important;
  }
  .p-xl-2 {
    padding: 1rem !important;
  }
  .p-xl-3 {
    padding: 1.5rem !important;
  }
  .p-xl-4 {
    padding: 2rem !important;
  }
  .p-xl-5 {
    padding: 2.5rem !important;
  }
  .p-xl-6 {
    padding: 3rem !important;
  }
  .p-xl-8 {
    padding: 4rem !important;
  }
  .p-xl-10 {
    padding: 5rem !important;
  }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xl-1 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xl-2 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xl-3 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xl-4 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }
  .px-xl-5 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }
  .px-xl-6 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-xl-8 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }
  .px-xl-10 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xl-1 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xl-2 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xl-3 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xl-4 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  .py-xl-5 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }
  .py-xl-6 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-xl-8 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }
  .py-xl-10 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  .pt-xl-0 {
    padding-top: 0 !important;
  }
  .pt-xl-1 {
    padding-top: 0.5rem !important;
  }
  .pt-xl-2 {
    padding-top: 1rem !important;
  }
  .pt-xl-3 {
    padding-top: 1.5rem !important;
  }
  .pt-xl-4 {
    padding-top: 2rem !important;
  }
  .pt-xl-5 {
    padding-top: 2.5rem !important;
  }
  .pt-xl-6 {
    padding-top: 3rem !important;
  }
  .pt-xl-8 {
    padding-top: 4rem !important;
  }
  .pt-xl-10 {
    padding-top: 5rem !important;
  }
  .pe-xl-0 {
    padding-right: 0 !important;
  }
  .pe-xl-1 {
    padding-right: 0.5rem !important;
  }
  .pe-xl-2 {
    padding-right: 1rem !important;
  }
  .pe-xl-3 {
    padding-right: 1.5rem !important;
  }
  .pe-xl-4 {
    padding-right: 2rem !important;
  }
  .pe-xl-5 {
    padding-right: 2.5rem !important;
  }
  .pe-xl-6 {
    padding-right: 3rem !important;
  }
  .pe-xl-8 {
    padding-right: 4rem !important;
  }
  .pe-xl-10 {
    padding-right: 5rem !important;
  }
  .pb-xl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xl-1 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xl-2 {
    padding-bottom: 1rem !important;
  }
  .pb-xl-3 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xl-4 {
    padding-bottom: 2rem !important;
  }
  .pb-xl-5 {
    padding-bottom: 2.5rem !important;
  }
  .pb-xl-6 {
    padding-bottom: 3rem !important;
  }
  .pb-xl-8 {
    padding-bottom: 4rem !important;
  }
  .pb-xl-10 {
    padding-bottom: 5rem !important;
  }
  .ps-xl-0 {
    padding-left: 0 !important;
  }
  .ps-xl-1 {
    padding-left: 0.5rem !important;
  }
  .ps-xl-2 {
    padding-left: 1rem !important;
  }
  .ps-xl-3 {
    padding-left: 1.5rem !important;
  }
  .ps-xl-4 {
    padding-left: 2rem !important;
  }
  .ps-xl-5 {
    padding-left: 2.5rem !important;
  }
  .ps-xl-6 {
    padding-left: 3rem !important;
  }
  .ps-xl-8 {
    padding-left: 4rem !important;
  }
  .ps-xl-10 {
    padding-left: 5rem !important;
  }
  .gap-xl-0 {
    gap: 0 !important;
  }
  .gap-xl-1 {
    gap: 0.5rem !important;
  }
  .gap-xl-2 {
    gap: 1rem !important;
  }
  .gap-xl-3 {
    gap: 1.5rem !important;
  }
  .gap-xl-4 {
    gap: 2rem !important;
  }
  .gap-xl-5 {
    gap: 2.5rem !important;
  }
  .gap-xl-6 {
    gap: 3rem !important;
  }
  .gap-xl-8 {
    gap: 4rem !important;
  }
  .gap-xl-10 {
    gap: 5rem !important;
  }
  .row-gap-xl-0 {
    row-gap: 0 !important;
  }
  .row-gap-xl-1 {
    row-gap: 0.5rem !important;
  }
  .row-gap-xl-2 {
    row-gap: 1rem !important;
  }
  .row-gap-xl-3 {
    row-gap: 1.5rem !important;
  }
  .row-gap-xl-4 {
    row-gap: 2rem !important;
  }
  .row-gap-xl-5 {
    row-gap: 2.5rem !important;
  }
  .row-gap-xl-6 {
    row-gap: 3rem !important;
  }
  .row-gap-xl-8 {
    row-gap: 4rem !important;
  }
  .row-gap-xl-10 {
    row-gap: 5rem !important;
  }
  .column-gap-xl-0 {
    column-gap: 0 !important;
  }
  .column-gap-xl-1 {
    column-gap: 0.5rem !important;
  }
  .column-gap-xl-2 {
    column-gap: 1rem !important;
  }
  .column-gap-xl-3 {
    column-gap: 1.5rem !important;
  }
  .column-gap-xl-4 {
    column-gap: 2rem !important;
  }
  .column-gap-xl-5 {
    column-gap: 2.5rem !important;
  }
  .column-gap-xl-6 {
    column-gap: 3rem !important;
  }
  .column-gap-xl-8 {
    column-gap: 4rem !important;
  }
  .column-gap-xl-10 {
    column-gap: 5rem !important;
  }
  .text-xl-start {
    text-align: left !important;
  }
  .text-xl-end {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
@media print {
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-grid {
    display: grid !important;
  }
  .d-print-inline-grid {
    display: inline-grid !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
  .d-print-none {
    display: none !important;
  }
}
.accordion-header {
  border-radius: inherit;
}

.accordion-button {
  border-radius: inherit;
}
.accordion-button:not(.collapsed):focus {
  box-shadow: inset 0 calc(-1 * var(--accordion-border-width)) 0 var(--accordion-border-color);
}

.accordion-item--brand-header {
  border: 0;
}
.accordion-item--brand-header:not(:last-of-type) {
  border-bottom: 1px solid #c6c6c6;
}
.accordion-item--brand-header:last-of-type .accordion-body {
  border-bottom: 1px solid #c6c6c6;
}
.accordion-item--brand-header > .accordion-header > .accordion-button {
  color: #fff;
}
.accordion-item--brand-header .accordion-header {
  background-color: #5fb624;
  color: #fff;
}
.accordion-item--brand-header .accordion-header > .accordion-button::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23fff'%3e%3cpath d='M14 3.75V10.5H20.75C21.5469 10.5 22.25 11.2031 22.25 12C22.25 12.8438 21.5469 13.5 20.75 13.5H14V20.25C14 21.0938 13.2969 21.75 12.5 21.75C11.6562 21.75 11 21.0938 11 20.25V13.5H4.25C3.40625 13.5 2.75 12.8438 2.75 12C2.75 11.2031 3.40625 10.5 4.25 10.5H11V3.75C11 2.95312 11.6562 2.25 12.5 2.25C13.2969 2.25 14 2.95312 14 3.75Z'/%3e%3c/svg%3e");
}
.accordion-item--brand-header .accordion-header > .accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23fff'%3e%3cpath d='M22.25 12C22.25 12.8438 21.5469 13.5 20.75 13.5H4.25C3.40625 13.5 2.75 12.8438 2.75 12C2.75 11.2031 3.40625 10.5 4.25 10.5H20.75C21.5469 10.5 22.25 11.2031 22.25 12Z'/%3e%3c/svg%3e");
}
.accordion-item--brand-header .accordion-body {
  border-right: 1px solid #c6c6c6;
  border-left: 1px solid #c6c6c6;
}
.accordion-item--brand-header:has(.accordion-header):has(.accordion-button:not(.collapsed)) {
  border-bottom: 0;
}
.accordion-item--dark-header {
  border: 0;
}
.accordion-item--dark-header:not(:last-of-type) {
  border-bottom: 1px solid #c6c6c6;
}
.accordion-item--dark-header:last-of-type .accordion-body {
  border-bottom: 1px solid #c6c6c6;
}
.accordion-item--dark-header > .accordion-header > .accordion-button {
  color: #fff;
}
.accordion-item--dark-header .accordion-header {
  background-color: #797979;
  color: #fff;
}
.accordion-item--dark-header .accordion-header > .accordion-button::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23fff'%3e%3cpath d='M14 3.75V10.5H20.75C21.5469 10.5 22.25 11.2031 22.25 12C22.25 12.8438 21.5469 13.5 20.75 13.5H14V20.25C14 21.0938 13.2969 21.75 12.5 21.75C11.6562 21.75 11 21.0938 11 20.25V13.5H4.25C3.40625 13.5 2.75 12.8438 2.75 12C2.75 11.2031 3.40625 10.5 4.25 10.5H11V3.75C11 2.95312 11.6562 2.25 12.5 2.25C13.2969 2.25 14 2.95312 14 3.75Z'/%3e%3c/svg%3e");
}
.accordion-item--dark-header .accordion-header > .accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23fff'%3e%3cpath d='M22.25 12C22.25 12.8438 21.5469 13.5 20.75 13.5H4.25C3.40625 13.5 2.75 12.8438 2.75 12C2.75 11.2031 3.40625 10.5 4.25 10.5H20.75C21.5469 10.5 22.25 11.2031 22.25 12Z'/%3e%3c/svg%3e");
}
.accordion-item--dark-header .accordion-body {
  border-right: 1px solid #c6c6c6;
  border-left: 1px solid #c6c6c6;
}
.accordion-item--dark-header:has(.accordion-header):has(.accordion-button:not(.collapsed)) {
  border-bottom: 0;
}
.accordion-item--light-header {
  border: 0;
}
.accordion-item--light-header:not(:last-of-type) {
  border-bottom: 1px solid #c6c6c6;
}
.accordion-item--light-header:last-of-type .accordion-body {
  border-bottom: 1px solid #c6c6c6;
}
.accordion-item--light-header > .accordion-header > .accordion-button {
  color: #797979;
}
.accordion-item--light-header .accordion-header {
  background-color: #fff;
  color: #797979;
}
.accordion-item--light-header .accordion-header > .accordion-button::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%231e76ce'%3e%3cpath d='M14 3.75V10.5H20.75C21.5469 10.5 22.25 11.2031 22.25 12C22.25 12.8438 21.5469 13.5 20.75 13.5H14V20.25C14 21.0938 13.2969 21.75 12.5 21.75C11.6562 21.75 11 21.0938 11 20.25V13.5H4.25C3.40625 13.5 2.75 12.8438 2.75 12C2.75 11.2031 3.40625 10.5 4.25 10.5H11V3.75C11 2.95312 11.6562 2.25 12.5 2.25C13.2969 2.25 14 2.95312 14 3.75Z'/%3e%3c/svg%3e");
}
.accordion-item--light-header .accordion-header > .accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%231e76ce'%3e%3cpath d='M22.25 12C22.25 12.8438 21.5469 13.5 20.75 13.5H4.25C3.40625 13.5 2.75 12.8438 2.75 12C2.75 11.2031 3.40625 10.5 4.25 10.5H20.75C21.5469 10.5 22.25 11.2031 22.25 12Z'/%3e%3c/svg%3e");
}
.accordion-item--light-header .accordion-body {
  border-right: 1px solid #c6c6c6;
  border-left: 1px solid #c6c6c6;
}
.accordion-item--light-header:has(.accordion-header):has(.accordion-button:not(.collapsed)) {
  border-bottom: 0;
}
.accordion-item--transparent {
  background-color: transparent;
  border: 0;
  border-bottom: 1px solid #c6c6c6;
  border-radius: 0 !important;
}
.accordion-item--collapse-content-padding {
  --accordion-body-padding-x: 0;
  --accordion-body-padding-y: 0;
}
.accordion-item--collapse-content-padding .accordion-body {
  border-width: 0 !important;
}
.accordion-item:not(:last-of-type) .accordion-item.nested-accordion:last-of-type {
  --accordion-border-radius: 0;
}
.accordion-item:last-of-type .accordion-item.nested-accordion .accordion-button {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.accordion-item:last-of-type .accordion-item.nested-accordion:last-of-type {
  --accordion-inner-border-radius: 1.5rem;
}

.nested-accordion {
  --accordion-inner-border-radius: 0;
}
.nested-accordion:not(:first-of-type) {
  border-top: 1px solid #c6c6c6 !important;
}
.nested-accordion .accordion-header {
  background-color: #c6c6c6 !important;
}
.nested-accordion .accordion-body {
  border: 0 !important;
}

.accordion-header:has(.accordion-button:not(.collapsed)) {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.accordion--transparent,
.accordion--transparent .accordion-header,
.accordion--transparent .accordion-item,
.accordion--transparent .accordion-button {
  background-color: transparent;
  border: 0;
  box-shadow: none !important;
}
.accordion--transparent .accordion-button:focus {
  outline: none !important;
}

.accordion-item--small .accordion-body {
  padding: 0 1rem 1rem;
}

.mijn {
  background: #f3f3f3;
}

.body-disable-scrolling {
  overflow: hidden;
}

@media (max-width: 767.98px) {
  .body-disable-scrolling-md {
    overflow: hidden;
  }
}

@media (max-width: 539.98px) {
  .body-disable-scrolling-sm {
    overflow: hidden;
  }
}

body {
  overflow-x: hidden;
}

.rounded-right-0 {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.rounded-left-0 {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rounded-bottom-0 {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rounded-top-0 {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.btn {
  line-height: 1.5;
}
.btn.btn-ghost {
  padding: 0.5rem 1rem;
  border-color: #9b9b9b;
  border-width: 1px;
  color: #797979;
  font-size: 1rem;
  font-weight: 400;
}
.btn.btn-ghost:focus {
  border-color: #5fb624;
  box-shadow: none;
}
.btn.btn-ghost:hover, .btn.btn-ghost:active {
  background-color: #9b9b9b;
  border-color: #9b9b9b;
  color: #fff;
}
.btn.btn-link {
  box-shadow: none;
}

.btn.toggle-button {
  border-radius: 4rem;
}
.btn.toggle-button.btn-outline-primary:active:not(:disabled), .btn.toggle-button.btn-outline-primary:focus:not(:disabled), .btn.toggle-button.btn-outline-primary:hover:not(:disabled) {
  background-color: transparent;
  border-color: #1e76ce;
  color: #1e76ce;
}
.btn.toggle-button.btn-outline-primary.active:active:not(:disabled), .btn.toggle-button.btn-outline-primary.active:focus:not(:disabled), .btn.toggle-button.btn-outline-primary.active:hover:not(:disabled) {
  background-color: #1e76ce;
  border-color: #1e76ce;
  color: #fff;
}

.mx-n-card {
  margin-right: -1rem;
  margin-left: -1rem;
}

.ng-failed-lazyloaded {
  display: none;
}

figure.image-cover > img,
img.image-cover {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.rte img,
.rte-content img {
  max-width: 100%;
}

.btn,
.nav-link,
.dropdown-item,
.page-link {
  text-decoration: none;
}

.link,
.link:not([href]):not([tabindex]) {
  background-color: transparent;
  color: #1e76ce;
  text-decoration: underline;
}
.link:hover,
.link:not([href]):not([tabindex]):hover {
  color: #1e76ce;
  text-decoration: underline;
  cursor: pointer;
}

a:focus {
  z-index: 10;
}

.modal {
  --modal-margin: 1rem;
}

@media (max-width: 539.98px) {
  .mijn .card--fixed-overlay {
    position: fixed;
    top: 0;
    left: 0;
    overflow-y: scroll;
    width: 100%;
    height: 100vh;
    border: 0;
    border-radius: 0 !important;
    z-index: 1100;
  }
}

.modal-header .close:focus {
  outline: 0;
}

.modal--left .modal-dialog {
  height: 100%;
  max-height: 100%;
  margin: 0;
}
.modal--left .modal-dialog .modal-content {
  height: 100%;
  max-height: 100%;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
@media (max-width: 539.98px) {
  .modal--left .modal-dialog .modal-content {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.modal--right .modal-dialog {
  height: 100%;
  max-height: 100%;
  margin: 0 0 0 auto;
}
.modal--right .modal-dialog .modal-content {
  height: 100%;
  max-height: 100%;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.modal-backdrop--hidden {
  overflow-y: auto !important;
  padding-right: 0 !important;
}

.modal.fade.modal--bottom .modal-dialog {
  transform: translate(0, 50px);
}

.modal.show.modal--bottom .modal-dialog {
  transform: none;
}

.modal--bottom {
  display: flex !important;
  align-items: flex-end;
  justify-content: center;
  overflow: hidden;
}
.modal--bottom .modal-dialog {
  margin-bottom: 2rem;
}
@media (max-width: 959.98px) {
  .modal--bottom .modal-dialog {
    width: 100%;
    max-width: 100%;
    margin-bottom: 0;
  }
  .modal--bottom .modal-dialog .modal-content {
    border-radius: 0;
  }
  .modal--bottom .modal-dialog .modal-header {
    border-radius: 0;
  }
}
.modal--bottom .modal-shadow .modal-content {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175);
}

/* stylelint-disable selector-no-qualifying-type,property-no-vendor-prefix */
/* Disable number input spinners on desktop */
input[type=number] {
  -moz-appearance: textfield; /* Firefox */
}
input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number]::-ms-clear {
  display: none;
}

ol,
ul {
  padding-inline-start: 40px;
}

.hyphenate {
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
}

a,
.btn-link {
  text-underline-position: under;
}

.sidenote {
  color: #797979;
  font-size: 0.875rem;
}

.font-size-lg {
  font-size: 1.25rem;
}

.font-size-sm {
  font-size: 0.75rem;
}

p {
  overflow-wrap: break-word;
}

.text-brand-alt {
  color: #5fb624;
}

.ng-select .ng-select-container {
  padding: 0.75rem 3rem 0.61rem 0;
  border-color: #9b9b9b;
  border-style: solid;
  border-width: 2px;
  border-radius: 0.5rem;
}
.ng-select .ng-select-container:hover {
  cursor: pointer;
}
.ng-select .ng-value {
  color: #000;
}
.ng-select.ng-select--icon .ng-select-container {
  padding-left: 1.6rem;
}
.ng-select .ng-dropdown-panel {
  z-index: 1000;
}
.ng-select .ng-dropdown-panel-items {
  background-color: #fff;
  border-color: #9b9b9b;
  border-style: solid;
  border-width: 2px;
  border-radius: 1.5rem;
}
.ng-select .ng-dropdown-panel-items.scroll-host {
  overflow-y: auto;
  max-height: 20rem;
}
.ng-select .ng-option {
  padding: 0.75rem 3rem 0.75rem 1rem;
}
.ng-select .ng-option.ng-option-marked {
  background-color: #1e76ce;
  color: #fff;
  outline: 0;
  cursor: pointer;
}
.ng-select .ng-option.ng-option-selected {
  font-weight: 900;
}
.ng-select .ng-value,
.ng-select .ng-placeholder {
  margin-left: 1rem;
}
.ng-select-clearable .ng-value,
.ng-select-clearable .ng-placeholder {
  max-width: calc(100% - 5 * 1rem);
}
.ng-select.ng-select-opened .ng-select-container {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.ng-select.ng-select-opened .ng-dropdown-panel-items {
  top: -4px !important;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  box-shadow: 0 8px 28px rgba(0, 0, 0, 0.12), 0 0.5px 0.5px rgba(0, 0, 0, 0.12);
}
.ng-select.is-valid .ng-select-container {
  border-bottom-color: #43811a;
  border-bottom-width: 2px;
}
.ng-select.is-valid.ng-select-opened .ng-dropdown-panel {
  border-top-color: #43811a;
  border-top-width: 2px;
}
.ng-select.is-invalid .ng-select-container {
  border-bottom-color: #c41a1a;
  border-bottom-width: 2px;
}
.ng-select.is-invalid.ng-select-opened .ng-dropdown-panel {
  border-top-color: #c41a1a;
  border-top-width: 2px;
}

.form-check input:focus,
.form-radio input:focus {
  border-color: #1e76ce;
}

.input-group input:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group input:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group :not(:first-child) input:not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group-text {
  justify-content: center;
}

.form-floating > input:focus + label {
  color: #000;
  font-weight: 700;
}

.form-floating > :not(input:placeholder-shown) + label {
  color: #000;
  font-weight: 700;
}

.form-floating > label {
  margin-bottom: unset;
  padding: 1rem 0 1rem 1rem;
  color: #9b9b9b;
  line-height: 1.5;
  font-weight: 400;
}

.form-floating > .form-control:focus ~ label {
  padding: 1rem 0 1rem 1rem;
}

.form-floating > .form-control ~ label::after {
  background-color: transparent !important;
}

.form-floating > .form-control:focus::placeholder {
  color: #9b9b9b;
}

.form-check-label {
  margin-bottom: 0;
}

label {
  margin-bottom: 0.3333333333rem;
  color: #000;
  font-weight: 700;
}

.d-essent {
  display: none;
}

.d-ed {
  display: none;
}

.d-ew {
  display: none;
}

.d-essent-block {
  display: none;
}

.d-ed-block {
  display: none;
}

.d-ew-block {
  display: none;
}

.d-xs-ed {
  display: none;
}

.d-xs-essent {
  display: none;
}

.d-xs-ew {
  display: none;
}

.d-sm-ed {
  display: none;
}

.d-sm-essent {
  display: none;
}

.d-sm-ew {
  display: none;
}

.d-md-ed {
  display: none;
}

.d-md-essent {
  display: none;
}

.d-md-ew {
  display: none;
}

.d-lg-ed {
  display: none;
}

.d-lg-essent {
  display: none;
}

.d-lg-ew {
  display: none;
}

.d-xl-ed {
  display: none;
}

.d-xl-essent {
  display: none;
}

.d-xl-ew {
  display: none;
}

/**
 * We need `:root` (or some type of element + class/pseudo-class/attribute selector) to get
 * the CSS specificity high enough to be able to override styles set in the rich-text-wrapper
 * component (because the ::ng-deep pseudo-class selector" is used there).
 * Using `app-root[ng-version]` instead of `:root` works too, or `html[lang]`.
 */
:root [class^=rich-text-icon-]::before,
:root [class*=" rich-text-icon-"]::before,
:root [class^=rich-text-list-icon-] li::before,
:root [class*=" rich-text-list-icon-"] li::before,
:root [class^=rich-text-icon-]::after,
:root [class*=" rich-text-icon-"]::after,
:root [class^=rich-text-list-icon-] li::after,
:root [class*=" rich-text-list-icon-"] li::after {
  display: inline-block;
  width: 1em;
  margin-right: 0.2em;
  /* Animation center compensation - margins should be symmetric */
  margin-left: 0.2em;
  font-family: "fontello-solid";
  font-style: normal;
  font-weight: normal;
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-align: center;
  text-decoration: inherit;
  text-transform: none;
  speak: never;
}
:root [class^=rich-text-list-icon-],
:root [class*=" rich-text-list-icon-"] {
  padding-left: 0;
  list-style-type: none;
}
:root [class^=rich-text-list-icon-] li,
:root [class*=" rich-text-list-icon-"] li {
  display: block;
  position: relative;
  margin-left: 2rem;
}
:root [class^=rich-text-list-icon-] li::before,
:root [class*=" rich-text-list-icon-"] li::before {
  position: absolute;
  left: -2rem;
}
:root [class^=rich-text-list-icon-check],
:root [class*=" rich-text-list-icon-check"] {
  margin-left: 0.5rem;
  padding-left: 1.4rem;
  text-indent: -1.4rem;
}
:root [class^=rich-text-list-icon-check] li,
:root [class*=" rich-text-list-icon-check"] li {
  display: block;
  margin-left: 0;
}
:root [class^=rich-text-list-icon-check] li::before,
:root [class*=" rich-text-list-icon-check"] li::before {
  position: relative;
  left: 0;
}
:root .rich-text-list-icon-check li::before,
:root .rich-text-icon-check::before {
  content: "\e800";
  color: currentColor;
}
:root .rich-text-list-icon-check-success li::before,
:root .rich-text-icon-check-success::before {
  content: "\e800";
  color: #43811a;
}
:root .rich-text-list-icon-check-white li::before,
:root .rich-text-icon-check-white::before {
  content: "\e800";
  color: #fff;
}
:root .rich-text-list-icon-underlined-links-angle-right li::after,
:root .rich-text-icon-underlined-links-angle-right::after {
  content: "A";
  color: #1e76ce;
}
:root .rich-text-list-icon-underlined-links-grey-line-blue-angle-right li::after,
:root .rich-text-icon-underlined-links-grey-line-blue-angle-right::after {
  content: "A";
  color: #1e76ce;
}
:root .rich-text-list-icon-cross li::before,
:root .rich-text-icon-cross::before {
  content: "T";
  color: #c41a1a;
}
:root .rich-text-list-icon-exclamation-circle li::before,
:root .rich-text-icon-exclamation-circle::before {
  content: "E";
  color: currentColor;
}
:root ul.rich-text-list-icon-underlined-links-angle-right, :root ul.rich-text-list-icon-underlined-links-grey-line-blue-angle-right,
:root ol.rich-text-list-icon-underlined-links-angle-right,
:root ol.rich-text-list-icon-underlined-links-grey-line-blue-angle-right {
  padding: 0;
  list-style-type: none;
}
:root ul.rich-text-list-icon-underlined-links-angle-right > li, :root ul.rich-text-list-icon-underlined-links-grey-line-blue-angle-right > li,
:root ol.rich-text-list-icon-underlined-links-angle-right > li,
:root ol.rich-text-list-icon-underlined-links-grey-line-blue-angle-right > li {
  /* normal lists in the rich-text editor have a margin-bottom of 1em (to comply with designs),
     it's removed here because the spacing is added as padding within the <a> tag
     (so that the space around the link will also be clickable) */
  margin-bottom: 0;
  margin-left: 0;
  border-bottom: 1px solid currentColor;
  text-align: left !important;
}
:root ul.rich-text-list-icon-underlined-links-angle-right > li::after, :root ul.rich-text-list-icon-underlined-links-grey-line-blue-angle-right > li::after,
:root ol.rich-text-list-icon-underlined-links-angle-right > li::after,
:root ol.rich-text-list-icon-underlined-links-grey-line-blue-angle-right > li::after {
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  justify-content: center;
  align-items: center;
  margin: 0;
  color: currentColor;
  font-size: 2em;
  pointer-events: none;
}
:root ul.rich-text-list-icon-underlined-links-angle-right > li a, :root ul.rich-text-list-icon-underlined-links-grey-line-blue-angle-right > li a,
:root ol.rich-text-list-icon-underlined-links-angle-right > li a,
:root ol.rich-text-list-icon-underlined-links-grey-line-blue-angle-right > li a {
  display: inline-block;
  /**
   * A fixed width is being placed on the <a> tag in certain situations by the rich-text
   * editor, important is used here to always overwrite it.
   */
  width: 100% !important;
  padding: 1em 0;
  color: currentColor;
  text-decoration: none;
  cursor: pointer;
}
:root ul.rich-text-list-icon-underlined-links-angle-right.rich-text-list-icon-underlined-links-angle-right > li:hover a, :root ul.rich-text-list-icon-underlined-links-grey-line-blue-angle-right.rich-text-list-icon-underlined-links-angle-right > li:hover a,
:root ol.rich-text-list-icon-underlined-links-angle-right.rich-text-list-icon-underlined-links-angle-right > li:hover a,
:root ol.rich-text-list-icon-underlined-links-grey-line-blue-angle-right.rich-text-list-icon-underlined-links-angle-right > li:hover a {
  text-decoration: underline;
}
:root ul.rich-text-list-icon-underlined-links-angle-right.rich-text-list-icon-underlined-links-grey-line-blue-angle-right > li, :root ul.rich-text-list-icon-underlined-links-grey-line-blue-angle-right.rich-text-list-icon-underlined-links-grey-line-blue-angle-right > li,
:root ol.rich-text-list-icon-underlined-links-angle-right.rich-text-list-icon-underlined-links-grey-line-blue-angle-right > li,
:root ol.rich-text-list-icon-underlined-links-grey-line-blue-angle-right.rich-text-list-icon-underlined-links-grey-line-blue-angle-right > li {
  border-color: #ababab;
}
:root ul.rich-text-list-icon-underlined-links-angle-right.rich-text-list-icon-underlined-links-grey-line-blue-angle-right > li:hover a, :root ul.rich-text-list-icon-underlined-links-grey-line-blue-angle-right.rich-text-list-icon-underlined-links-grey-line-blue-angle-right > li:hover a,
:root ol.rich-text-list-icon-underlined-links-angle-right.rich-text-list-icon-underlined-links-grey-line-blue-angle-right > li:hover a,
:root ol.rich-text-list-icon-underlined-links-grey-line-blue-angle-right.rich-text-list-icon-underlined-links-grey-line-blue-angle-right > li:hover a {
  color: #1e76ce;
}
:root ul.rich-text-list-icon-underlined-links-angle-right.rich-text-list-icon-underlined-links-grey-line-blue-angle-right > li::after, :root ul.rich-text-list-icon-underlined-links-grey-line-blue-angle-right.rich-text-list-icon-underlined-links-grey-line-blue-angle-right > li::after,
:root ol.rich-text-list-icon-underlined-links-angle-right.rich-text-list-icon-underlined-links-grey-line-blue-angle-right > li::after,
:root ol.rich-text-list-icon-underlined-links-grey-line-blue-angle-right.rich-text-list-icon-underlined-links-grey-line-blue-angle-right > li::after {
  color: #1e76ce;
}
:root ul.rich-text-list-remove-indentation:not(ul ul):not(ol ul):not(ul ol):not(ol ol),
:root ol.rich-text-list-remove-indentation:not(ul ul):not(ol ul):not(ul ol):not(ol ol) {
  padding-left: 1.4rem;
  list-style-position: inside;
  text-indent: -1.4rem;
}

.form-check.form-switch .form-check-input:checked {
  background-color: #198754;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
  border-color: #198754;
}

@media (min-width: 0) {
  @supports (position: sticky) {
    .sticky-xs-top {
      position: sticky;
      z-index: 1020;
    }
  }
}
@media (min-width: 540px) {
  @supports (position: sticky) {
    .sticky-sm-top {
      position: sticky;
      z-index: 1020;
    }
  }
}
@media (min-width: 768px) {
  @supports (position: sticky) {
    .sticky-md-top {
      position: sticky;
      z-index: 1020;
    }
  }
}
@media (min-width: 960px) {
  @supports (position: sticky) {
    .sticky-lg-top {
      position: sticky;
      z-index: 1020;
    }
  }
}
@media (min-width: 1140px) {
  @supports (position: sticky) {
    .sticky-xl-top {
      position: sticky;
      z-index: 1020;
    }
  }
}
:not(ngb-carousel, ngb-pagination) > ul:not(.list-no-default-spacing) > li:not(:last-of-type):not(.list-group-item),
:not(ngb-carousel, ngb-pagination) > ol:not(.list-no-default-spacing) > li:not(:last-of-type):not(.list-group-item) {
  margin-bottom: 0.5rem;
}
:not(ngb-carousel, ngb-pagination) > ul:not(.list-no-default-spacing) > li > ul,
:not(ngb-carousel, ngb-pagination) > ul:not(.list-no-default-spacing) > li > ol,
:not(ngb-carousel, ngb-pagination) > ol:not(.list-no-default-spacing) > li > ul,
:not(ngb-carousel, ngb-pagination) > ol:not(.list-no-default-spacing) > li > ol {
  margin-top: 0.5rem;
}
@supports (counter-increment: any) {
  :not(ngb-carousel, ngb-pagination) > ol:not(.list-no-default-spacing) {
    padding-left: 1.4rem;
  }
  :not(ngb-carousel, ngb-pagination) > ol:not(.list-no-default-spacing) > li {
    counter-increment: listItemCounter;
    list-style-type: none;
  }
  :not(ngb-carousel, ngb-pagination) > ol:not(.list-no-default-spacing) > li::before {
    content: counter(listItemCounter) ".";
    margin-right: 0.5rem;
  }
}

ul.reset-list-styles {
  margin: 0;
  padding: 0;
  list-style: none;
}
ul.reset-list-styles li {
  margin: 0 !important;
  padding: 0;
  list-style: none;
}

.list-unstyled--no-spacing > li {
  margin-bottom: 0 !important;
}

sc-placeholder wl-basic-content-wrapper:not(:first-child) {
  display: block;
  margin-top: 1rem;
}

.accordion-item {
  --accordion-color: #000;
  --link-color: #1e76ce;
  background-color: #fff;
  box-shadow: -16px 8px 8px rgba(0, 0, 0, 0.01), 16px 8px 8px rgba(0, 0, 0, 0.01), 0 32px 32px rgba(0, 0, 0, 0.05), 0 0.5px 0.5px rgba(0, 0, 0, 0.12);
}
.accordion-item--dark-header {
  --accordion-bg: #410073;
  --accordion-btn-bg: #410073;
  --accordion-btn-color: #fff;
  --accordion-active-color: #fff;
  --accordion-active-bg: #410073;
}
.accordion-item--light-header .accordion-button {
  color: #410073;
}
.accordion-item:first-of-type {
  border-top-left-radius: var(--accordion-border-radius) !important;
  border-top-right-radius: var(--accordion-border-radius) !important;
  box-shadow: 0 8px 28px rgba(0, 0, 0, 0.12), 0 0.5px 0.5px rgba(0, 0, 0, 0.12);
}
.accordion-item:last-of-type {
  border-bottom-right-radius: var(--accordion-border-radius) !important;
  border-bottom-left-radius: var(--accordion-border-radius) !important;
}
.accordion-item:not(:last-of-type) {
  border-bottom: 2px solid #d8d8d8;
}
.accordion-item .accordion-button {
  margin-bottom: 0;
  line-height: 1.5rem;
  font-size: 1.25rem;
  text-decoration: none;
  /* stylelint-disable-next-line order/order */
}
.accordion-item .accordion-button:focus {
  outline: 2px solid #1e76ce;
  outline-offset: 0;
}
@media (max-width: 767.98px) {
  .accordion-item .accordion-button {
    --accordion-btn-padding-x: 1rem;
    font-size: 1rem;
  }
}
.accordion-item .accordion-title {
  font-weight: bold;
  text-decoration: none;
}
.accordion-item .collapsing,
.accordion-item .collapse,
.accordion-item .show {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.accordion-item .accordion-body {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.accordion-item--transparent {
  border-bottom: 0;
}

.bg-brand-gradient {
  background-image: radial-gradient(circle at bottom center, #58b50f, #3ca516 80%);
}

.btn {
  --btn-padding-y: 0.75rem;
  --btn-padding-x: 1.25rem;
  --btn-font-size: 1rem;
  --btn-border-radius: 50rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  display: inline-block;
  vertical-align: middle;
  background-color: transparent;
  border: 0 solid transparent;
  border-radius: var(--btn-border-radius);
  color: #000;
  line-height: 1.5;
  font-weight: 700;
  text-align: center;
  text-decoration: none;
  user-select: none;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: #000;
  text-decoration: none;
}
.btn:focus,
.btn.focus {
  outline: 0;
}
.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}
.btn.btn-link {
  color: #1e76ce;
  font-size: 1rem;
  font-weight: 700;
  text-decoration: underline;
}
.btn.btn-link.back-toggle {
  padding: 0.5rem 1rem;
  background-color: #fff;
  border-width: 2px;
  border-radius: 1.5rem;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 0 0 rgba(0, 0, 0, 0.05);
  color: #000;
  text-decoration: none;
}
.btn.btn-link.back-toggle:hover {
  top: 0;
}
.btn.btn-link:hover {
  color: #1e76ce;
}
.btn.btn-link:active {
  box-shadow: none !important;
}

.btn-primary {
  outline: 0.125rem solid transparent;
  outline-offset: -0.125rem;
  background-color: #1e76ce;
  box-shadow: none;
  color: #fff;
  line-height: 1.75;
  font-size: 1rem;
}
@media (max-width: 539.98px) {
  .btn-primary {
    padding: 0.5rem 1rem;
    font-size: 1rem;
    font-weight: 700;
    padding: 1rem;
  }
  .btn-primary:not(:disabled):not(.disabled):active {
    padding-top: 0.625rem;
    padding-bottom: 0.375rem;
  }
  .btn-primary::after {
    transform: translateY(-5px);
  }
  .btn-primary::after {
    transform: translateY(1px);
  }
}
.btn-primary:focus, .btn-primary.focus {
  outline: 2px solid #1e76ce;
  outline-offset: 2px;
  background-color: #1e76ce;
  box-shadow: none;
  color: #fff;
}
.btn-primary:focus:not(:focus-visible) {
  outline: 2px solid #1e76ce;
  outline-offset: 2px;
}
.btn-primary:hover, .btn-primary:not(:disabled):hover:active, .btn-primary.hover {
  background-color: #155492;
  color: #fff;
}
.btn-primary.disabled, .btn-primary:disabled {
  outline: 0.125rem solid transparent;
  outline-offset: 0;
  background-color: #f3f3f3;
  color: #797979;
  text-decoration: none;
}
.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active {
  padding-top: 0.875rem;
  padding-bottom: 0.625rem;
  background-color: #155492;
  box-shadow: inset 0 4px 0 rgba(0, 0, 0, 0.48);
  color: #fff;
  outline: none;
}

.btn-outline-primary {
  outline: 0.125rem solid #1e76ce;
  outline-offset: -0.125rem;
  background-color: #fff;
  box-shadow: none;
  color: #1e76ce;
  line-height: 1.75;
  font-size: 1rem;
}
@media (max-width: 539.98px) {
  .btn-outline-primary {
    padding: 0.5rem 1rem;
    font-size: 1rem;
    font-weight: 700;
    padding: 1rem;
  }
  .btn-outline-primary:not(:disabled):not(.disabled):active {
    padding-top: 0.625rem;
    padding-bottom: 0.375rem;
  }
  .btn-outline-primary::after {
    transform: translateY(-5px);
  }
  .btn-outline-primary::after {
    transform: translateY(1px);
  }
}
.btn-outline-primary:focus, .btn-outline-primary.focus {
  outline: 2px solid #1e76ce;
  outline-offset: 2px;
  background-color: #fff;
  box-shadow: none;
  color: #1e76ce;
}
.btn-outline-primary:focus:not(:focus-visible) {
  outline: 2px solid #1e76ce;
  outline-offset: 2px;
}
.btn-outline-primary:hover, .btn-outline-primary:not(:disabled):hover:active, .btn-outline-primary.hover {
  background-color: #1e76ce;
  color: #fff;
}
.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  outline: 0.125rem solid #f3f3f3;
  outline-offset: 0;
  background-color: #fff;
  color: #797979;
  text-decoration: none;
}
.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active {
  padding-top: 0.875rem;
  padding-bottom: 0.625rem;
  background-color: #1e76ce;
  box-shadow: inset 0 4px 0 rgba(0, 0, 0, 0.48);
  color: #fff;
  outline: none;
}

.btn-sales-primary {
  outline: 0.125rem solid transparent;
  outline-offset: -0.125rem;
  background-color: #ffc800;
  box-shadow: none;
  color: #000;
  line-height: 1.75;
  font-size: 1rem;
  display: inline-flex;
  padding-right: 1.5rem;
  justify-content: center;
}
.btn-sales-primary::after {
  content: "";
  display: inline-flex;
  width: 22px;
  height: 24px;
  margin-left: 0.5rem;
  flex: 0 0 22px;
  background-color: #000;
  transform: translateY(1px);
  mask-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'><path fill='currentColor' d='M22.5312 13.0781L15.0312 20.5781C14.4688 21.1875 13.4844 21.1875 12.9219 20.5781C12.3125 20.0156 12.3125 19.0312 12.9219 18.4688L17.8438 13.5H3.5C2.65625 13.5 2 12.8438 2 12C2 11.2031 2.65625 10.5 3.5 10.5H17.8438L12.9219 5.57812C12.3125 5.01562 12.3125 4.03125 12.9219 3.46875C13.4844 2.85938 14.4688 2.85938 15.0312 3.46875L22.5312 10.9688C23.1406 11.5312 23.1406 12.5156 22.5312 13.0781Z'/></svg>");
  mask-size: contain;
  align-self: center;
}
@media (max-width: 539.98px) {
  .btn-sales-primary {
    padding: 0.5rem 1rem;
    font-size: 1rem;
    font-weight: 700;
    padding: 1rem;
  }
  .btn-sales-primary:not(:disabled):not(.disabled):active {
    padding-top: 0.625rem;
    padding-bottom: 0.375rem;
  }
  .btn-sales-primary::after {
    transform: translateY(-5px);
  }
  .btn-sales-primary::after {
    transform: translateY(1px);
  }
}
.btn-sales-primary:focus, .btn-sales-primary.focus {
  outline: 2px solid #1e76ce;
  outline-offset: 2px;
  background-color: #ffc800;
  box-shadow: none;
  color: #000;
}
.btn-sales-primary:focus:not(:focus-visible) {
  outline: 2px solid #1e76ce;
  outline-offset: 2px;
}
.btn-sales-primary:hover, .btn-sales-primary:not(:disabled):hover:active, .btn-sales-primary.hover {
  background-color: #ffda54;
  color: #373737;
}
.btn-sales-primary.disabled, .btn-sales-primary:disabled {
  outline: 0.125rem solid transparent;
  outline-offset: 0;
  background-color: #f3f3f3;
  color: #797979;
  text-decoration: none;
}
.btn-sales-primary.disabled::after, .btn-sales-primary:disabled::after {
  background-color: #797979;
}
.btn-sales-primary:not(:disabled):not(.disabled):active, .btn-sales-primary:not(:disabled):not(.disabled).active {
  padding-top: 0.875rem;
  padding-bottom: 0.625rem;
  background-color: #ffda54;
  box-shadow: inset 0 4px 0 rgba(0, 0, 0, 0.48);
  color: #373737;
  outline: none;
}

.btn-secondary {
  outline: 0.125rem solid #1e76ce;
  outline-offset: -0.125rem;
  background-color: #fff;
  box-shadow: none;
  color: #1e76ce;
  line-height: 1.75;
  font-size: 1rem;
}
@media (max-width: 539.98px) {
  .btn-secondary {
    padding: 0.5rem 1rem;
    font-size: 1rem;
    font-weight: 700;
    padding: 1rem;
  }
  .btn-secondary:not(:disabled):not(.disabled):active {
    padding-top: 0.625rem;
    padding-bottom: 0.375rem;
  }
  .btn-secondary::after {
    transform: translateY(-5px);
  }
  .btn-secondary::after {
    transform: translateY(1px);
  }
}
.btn-secondary:focus, .btn-secondary.focus {
  outline: 2px solid #1e76ce;
  outline-offset: 2px;
  background-color: #fff;
  box-shadow: none;
  color: #1e76ce;
}
.btn-secondary:focus:not(:focus-visible) {
  outline: 2px solid #1e76ce;
  outline-offset: 2px;
}
.btn-secondary:hover, .btn-secondary:not(:disabled):hover:active, .btn-secondary.hover {
  background-color: #1e76ce;
  color: #fff;
}
.btn-secondary.disabled, .btn-secondary:disabled {
  outline: 0.125rem solid #f3f3f3;
  outline-offset: 0;
  background-color: #fff;
  color: #797979;
  text-decoration: none;
}
.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active {
  padding-top: 0.875rem;
  padding-bottom: 0.625rem;
  background-color: #1e76ce;
  box-shadow: inset 0 4px 0 rgba(0, 0, 0, 0.48);
  color: #fff;
  outline: none;
}

.btn-outline-secondary {
  outline: 0.125rem solid #1e76ce;
  outline-offset: -0.125rem;
  background-color: #fff;
  box-shadow: none;
  color: #1e76ce;
  line-height: 1.75;
  font-size: 1rem;
}
@media (max-width: 539.98px) {
  .btn-outline-secondary {
    padding: 0.5rem 1rem;
    font-size: 1rem;
    font-weight: 700;
    padding: 1rem;
  }
  .btn-outline-secondary:not(:disabled):not(.disabled):active {
    padding-top: 0.625rem;
    padding-bottom: 0.375rem;
  }
  .btn-outline-secondary::after {
    transform: translateY(-5px);
  }
  .btn-outline-secondary::after {
    transform: translateY(1px);
  }
}
.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  outline: 2px solid #1e76ce;
  outline-offset: 2px;
  background-color: #fff;
  box-shadow: none;
  color: #1e76ce;
}
.btn-outline-secondary:focus:not(:focus-visible) {
  outline: 2px solid #1e76ce;
  outline-offset: 2px;
}
.btn-outline-secondary:hover, .btn-outline-secondary:not(:disabled):hover:active, .btn-outline-secondary.hover {
  background-color: #1e76ce;
  color: #fff;
}
.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  outline: 0.125rem solid #f3f3f3;
  outline-offset: 0;
  background-color: #fff;
  color: #797979;
  text-decoration: none;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active {
  padding-top: 0.875rem;
  padding-bottom: 0.625rem;
  background-color: #1e76ce;
  box-shadow: inset 0 4px 0 rgba(0, 0, 0, 0.48);
  color: #fff;
  outline: none;
}

.btn-sales-secondary {
  outline: 0.125rem solid #ffc800;
  outline-offset: -0.125rem;
  background-color: #fff;
  box-shadow: none;
  color: #373737;
  line-height: 1.75;
  font-size: 1rem;
  display: inline-flex;
  padding-right: 1.5rem;
  justify-content: center;
}
.btn-sales-secondary::after {
  content: "";
  display: inline-flex;
  width: 22px;
  height: 24px;
  margin-left: 0.5rem;
  flex: 0 0 22px;
  background-color: #373737;
  transform: translateY(1px);
  mask-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'><path fill='currentColor' d='M22.5312 13.0781L15.0312 20.5781C14.4688 21.1875 13.4844 21.1875 12.9219 20.5781C12.3125 20.0156 12.3125 19.0312 12.9219 18.4688L17.8438 13.5H3.5C2.65625 13.5 2 12.8438 2 12C2 11.2031 2.65625 10.5 3.5 10.5H17.8438L12.9219 5.57812C12.3125 5.01562 12.3125 4.03125 12.9219 3.46875C13.4844 2.85938 14.4688 2.85938 15.0312 3.46875L22.5312 10.9688C23.1406 11.5312 23.1406 12.5156 22.5312 13.0781Z'/></svg>");
  mask-size: contain;
  align-self: center;
}
@media (max-width: 539.98px) {
  .btn-sales-secondary {
    padding: 0.5rem 1rem;
    font-size: 1rem;
    font-weight: 700;
    padding: 1rem;
  }
  .btn-sales-secondary:not(:disabled):not(.disabled):active {
    padding-top: 0.625rem;
    padding-bottom: 0.375rem;
  }
  .btn-sales-secondary::after {
    transform: translateY(-5px);
  }
  .btn-sales-secondary::after {
    transform: translateY(1px);
  }
}
.btn-sales-secondary:focus, .btn-sales-secondary.focus {
  outline: 2px solid #1e76ce;
  outline-offset: 2px;
  background-color: #fff;
  box-shadow: none;
  color: #373737;
}
.btn-sales-secondary:focus:not(:focus-visible) {
  outline: 2px solid #1e76ce;
  outline-offset: 2px;
}
.btn-sales-secondary:hover, .btn-sales-secondary:not(:disabled):hover:active, .btn-sales-secondary.hover {
  background-color: #ffc800;
  color: #373737;
}
.btn-sales-secondary.disabled, .btn-sales-secondary:disabled {
  outline: 0.125rem solid #f3f3f3;
  outline-offset: 0;
  background-color: #fff;
  color: #797979;
  text-decoration: none;
}
.btn-sales-secondary.disabled::after, .btn-sales-secondary:disabled::after {
  background-color: #797979;
}
.btn-sales-secondary:not(:disabled):not(.disabled):active, .btn-sales-secondary:not(:disabled):not(.disabled).active {
  padding-top: 0.875rem;
  padding-bottom: 0.625rem;
  background-color: #ffc800;
  box-shadow: inset 0 4px 0 rgba(0, 0, 0, 0.48);
  color: #373737;
  outline: none;
}

.btn-tertiary {
  outline: 0.125rem solid transparent;
  outline-offset: -0.125rem;
  background-color: transparent;
  box-shadow: none;
  color: var(--link-color);
  line-height: 1.75;
  font-size: 1rem;
  text-decoration: underline;
}
@media (max-width: 539.98px) {
  .btn-tertiary {
    padding: 0.5rem 1rem;
    font-size: 1rem;
    font-weight: 700;
    padding: 1rem;
  }
  .btn-tertiary:not(:disabled):not(.disabled):active {
    padding-top: 0.625rem;
    padding-bottom: 0.375rem;
  }
  .btn-tertiary::after {
    transform: translateY(-5px);
  }
  .btn-tertiary::after {
    transform: translateY(1px);
  }
}
.btn-tertiary:focus, .btn-tertiary.focus {
  outline: 2px solid #1e76ce;
  outline-offset: 2px;
  background-color: transparent;
  box-shadow: none;
  color: var(--link-color);
  text-decoration: underline;
}
.btn-tertiary:focus:not(:focus-visible) {
  outline: 2px solid #1e76ce;
  outline-offset: 2px;
}
.btn-tertiary:hover, .btn-tertiary:not(:disabled):hover:active, .btn-tertiary.hover {
  background-color: rgba(0, 0, 0, 0.08);
  color: var(--link-color);
  text-decoration: underline;
}
.btn-tertiary.disabled, .btn-tertiary:disabled {
  outline: 0.125rem solid transparent;
  outline-offset: 0;
  background-color: transparent;
  color: #797979;
  text-decoration: none;
}
.btn-tertiary:not(:disabled):not(.disabled):active, .btn-tertiary:not(:disabled):not(.disabled).active {
  padding-top: 0.875rem;
  padding-bottom: 0.625rem;
  background-color: rgba(0, 0, 0, 0.08);
  box-shadow: inset 0 4px 0 rgba(0, 0, 0, 0.48);
  color: var(--link-color);
  outline: none;
  text-decoration: underline;
}

.btn-tertiary-white {
  outline: 0.125rem solid transparent;
  outline-offset: -0.125rem;
  background-color: transparent;
  box-shadow: none;
  color: #fff;
  line-height: 1.75;
  font-size: 1rem;
  text-decoration: underline;
}
@media (max-width: 539.98px) {
  .btn-tertiary-white {
    padding: 0.5rem 1rem;
    font-size: 1rem;
    font-weight: 700;
    padding: 1rem;
  }
  .btn-tertiary-white:not(:disabled):not(.disabled):active {
    padding-top: 0.625rem;
    padding-bottom: 0.375rem;
  }
  .btn-tertiary-white::after {
    transform: translateY(-5px);
  }
  .btn-tertiary-white::after {
    transform: translateY(1px);
  }
}
.btn-tertiary-white:focus, .btn-tertiary-white.focus {
  outline: 2px solid rgba(255, 255, 255, 0.64);
  outline-offset: 2px;
  background-color: transparent;
  box-shadow: none;
  color: #fff;
  text-decoration: underline;
}
.btn-tertiary-white:focus:not(:focus-visible) {
  outline: 2px solid rgba(255, 255, 255, 0.64);
  outline-offset: 2px;
}
.btn-tertiary-white:hover, .btn-tertiary-white:not(:disabled):hover:active, .btn-tertiary-white.hover {
  background-color: rgba(0, 0, 0, 0.32);
  color: #fff;
  text-decoration: underline;
}
.btn-tertiary-white.disabled, .btn-tertiary-white:disabled {
  outline: 0.125rem solid transparent;
  outline-offset: 0;
  background-color: transparent;
  color: #797979;
  text-decoration: none;
}
.btn-tertiary-white:not(:disabled):not(.disabled):active, .btn-tertiary-white:not(:disabled):not(.disabled).active {
  padding-top: 0.875rem;
  padding-bottom: 0.625rem;
  background-color: rgba(0, 0, 0, 0.32);
  box-shadow: inset 0 4px 0 rgba(0, 0, 0, 0.48);
  color: #fff;
  outline: none;
  text-decoration: underline;
}

.btn-navigation {
  outline: 0.125rem solid #1e76ce;
  outline-offset: -0.125rem;
  background-color: #fff;
  box-shadow: none;
  color: #1e76ce;
  line-height: 1.75;
  font-size: 1rem;
}
@media (max-width: 539.98px) {
  .btn-navigation {
    padding: 0.5rem 1rem;
    font-size: 1rem;
    font-weight: 700;
    padding: 1rem;
  }
  .btn-navigation:not(:disabled):not(.disabled):active {
    padding-top: 0.625rem;
    padding-bottom: 0.375rem;
  }
  .btn-navigation::after {
    transform: translateY(-5px);
  }
  .btn-navigation::after {
    transform: translateY(1px);
  }
}
.btn-navigation:focus, .btn-navigation.focus {
  outline: 2px solid #1e76ce;
  outline-offset: 2px;
  background-color: #fff;
  box-shadow: none;
  color: #1e76ce;
}
.btn-navigation:focus:not(:focus-visible) {
  outline: 2px solid #1e76ce;
  outline-offset: 2px;
}
.btn-navigation:hover, .btn-navigation:not(:disabled):hover:active, .btn-navigation.hover {
  background-color: #155492;
  color: #fff;
}
.btn-navigation.disabled, .btn-navigation:disabled {
  outline: 0.125rem solid #f3f3f3;
  outline-offset: 0;
  background-color: #fff;
  color: #797979;
  text-decoration: none;
}
.btn-navigation:not(:disabled):not(.disabled):active, .btn-navigation:not(:disabled):not(.disabled).active {
  padding-top: 0.875rem;
  padding-bottom: 0.625rem;
  background-color: #155492;
  box-shadow: inset 0 4px 0 rgba(0, 0, 0, 0.48);
  color: #fff;
  outline: none;
}

.btn.btn-icon {
  display: flex;
  width: 2.875rem;
  height: 2.875rem;
  padding: 1rem;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
}

.btn.btn-sm, .btn-group-sm > .btn {
  padding: 0.5rem 1rem;
  font-size: 1rem;
  font-weight: 700;
}
.btn.btn-sm:not(:disabled):not(.disabled):active, .btn-group-sm > .btn:not(:disabled):not(.disabled):active {
  padding-top: 0.625rem;
  padding-bottom: 0.375rem;
}
.btn.btn-sm::after, .btn-group-sm > .btn::after {
  transform: translateY(-5px);
}

.btn-block {
  display: block;
  width: 100%;
}

.ed-header-button {
  --btn-padding-y: 0.75rem;
  --btn-padding-x: 1.25rem;
  --btn-font-size: 1rem;
  --btn-border-radius: 50rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  display: inline-block;
  vertical-align: middle;
  background-color: transparent;
  border: 0 solid transparent;
  border-radius: var(--btn-border-radius);
  color: #000;
  line-height: 1.5;
  font-weight: 700;
  text-align: center;
  text-decoration: none;
  user-select: none;
  outline: 0.125rem solid #1e76ce;
  outline-offset: -0.125rem;
  background-color: #fff;
  box-shadow: none;
  color: #1e76ce;
  line-height: 1.75;
  font-size: 1rem;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  font-weight: 700;
  display: inline-flex;
  line-height: 1.875;
  gap: 10px;
  align-items: center;
  justify-content: center;
  text-wrap: nowrap;
}
@media (prefers-reduced-motion: reduce) {
  .ed-header-button {
    transition: none;
  }
}
.ed-header-button:hover {
  color: #000;
  text-decoration: none;
}
.ed-header-button:focus, .ed-header-button.focus {
  outline: 0;
}
@media (max-width: 539.98px) {
  .ed-header-button {
    padding: 0.5rem 1rem;
    font-size: 1rem;
    font-weight: 700;
    padding: 1rem;
  }
  .ed-header-button:not(:disabled):not(.disabled):active {
    padding-top: 0.625rem;
    padding-bottom: 0.375rem;
  }
  .ed-header-button::after {
    transform: translateY(-5px);
  }
  .ed-header-button::after {
    transform: translateY(1px);
  }
}
.ed-header-button:focus, .ed-header-button.focus {
  outline: 2px solid #1e76ce;
  outline-offset: 2px;
  background-color: #fff;
  box-shadow: none;
  color: #1e76ce;
}
.ed-header-button:focus:not(:focus-visible) {
  outline: 2px solid #1e76ce;
  outline-offset: 2px;
}
.ed-header-button:hover, .ed-header-button:not(:disabled):hover:active, .ed-header-button.hover {
  background-color: #155492;
  color: #fff;
}
.ed-header-button.disabled, .ed-header-button:disabled {
  outline: 0.125rem solid #f3f3f3;
  outline-offset: 0;
  background-color: #fff;
  color: #797979;
  text-decoration: none;
}
.ed-header-button:not(:disabled):not(.disabled):active, .ed-header-button:not(:disabled):not(.disabled).active {
  padding-top: 0.875rem;
  padding-bottom: 0.625rem;
  background-color: #155492;
  box-shadow: inset 0 4px 0 rgba(0, 0, 0, 0.48);
  color: #fff;
  outline: none;
}
.ed-header-button:not(:disabled):not(.disabled):active {
  padding-top: 0.625rem;
  padding-bottom: 0.375rem;
}
.ed-header-button::after {
  transform: translateY(-5px);
}
.ed-header-button:hover {
  outline-color: #155492;
}
.ed-header-button fa-icon {
  font-size: 0.75rem;
}
.ed-header-button::after {
  content: none;
  display: none;
}

.offer-highlighted-spacing {
  height: 4rem;
}

.icon--danger {
  color: #c41a1a;
}

/*
  This class is used to show an icon as a button. The class has to be placed on the
  wrapper around a fa-icon component, e.g.:

  <button class="icon-button">
    <fa-icon icon="fa-close" />
  </button>

  or

  <a href="" class="icon-button">
    <fa-icon icon="fa-close" />
  </a>
*/
.icon-button {
  width: 2rem;
  height: 2rem;
  background-color: #1e76ce;
  border-radius: 50%;
  color: #fff;
  cursor: pointer;
}

.icon-sm {
  font-size: 1rem;
}

.icon-md {
  font-size: 1.5rem;
}

.icon-lg {
  font-size: 1.725rem;
}

@media (min-width: 768px) {
  .icon-md-md {
    font-size: 1.5rem;
  }
}

@media (min-width: 768px) {
  .icon-lg-md {
    font-size: 1.725rem;
  }
}

.rte img,
.rte-content img {
  border-radius: 1.5rem;
}

.floating-label {
  position: relative;
  width: 100%;
}
.floating-label .form-control.has-value ~ label, .floating-label .form-control:focus ~ label, .floating-label label.focussed {
  top: 0.9rem;
  color: #9b9b9b;
  font-size: 0.75rem;
  font-weight: 700;
}
.floating-label label {
  position: absolute;
  top: 1.5rem;
  left: 0.6666666667rem;
  color: #9b9b9b;
  line-height: 1;
  font-size: 1rem;
  transition: all 0.2s;
  cursor: text;
  pointer-events: none;
  z-index: 3;
  user-select: none;
}
@media (min-width: 540px) {
  .floating-label label {
    left: 1rem;
  }
}
.floating-label label.fixed-padding {
  left: 1rem;
}
.floating-label .form-control {
  padding: 0.75rem 0.6666666667rem 0 0.6666666667rem;
  box-shadow: none;
  color: #5c5c5c;
  transition: all 0.2s;
}
@media (min-width: 540px) {
  .floating-label .form-control {
    padding: 0.75rem 1rem 0 1rem;
  }
}
.floating-label .form-control:focus {
  border-color: #1e76ce;
}
.floating-label .form-control:hover:not(:focus) {
  border-color: #797979;
}

.is-invalid,
.ng-dirty.ng-invalid,
.ng-touched.ng-pristine.ng-invalid {
  border-color: #c41a1a !important;
  border-width: 2px;
  color: #c41a1a;
}
.is-invalid + label,
.ng-dirty.ng-invalid + label,
.ng-touched.ng-pristine.ng-invalid + label {
  color: #c41a1a;
}

.is-valid:not([type=checkbox]):not([type=radio]),
.ng-dirty.ng-valid:not([type=checkbox]):not([type=radio]) {
  border-color: #43811a !important;
}

.is-warning {
  border-bottom-color: #ffa800 !important;
  border-bottom-width: 2px;
}

.change-input {
  width: 100%;
}
@media (min-width: 768px) {
  .change-input {
    width: 66.66%;
  }
}
.change-input-label {
  font-size: 1rem;
  font-weight: bold;
}
@media (min-width: 768px) {
  .change-input-label {
    font-size: 1.2rem;
    font-weight: bolder;
  }
}

.ng-select {
  background-color: #fff;
  color: #5c5c5c;
}
.ng-select .ng-select-container {
  padding: 0.9rem 3rem 0.94rem 0;
}
.ng-select.is-invalid .ng-select-container {
  border-color: #c41a1a !important;
}
.ng-select.ng-select-focused .ng-select-container {
  border-color: #1e76ce !important;
}
.ng-select.ng-select-opened .ng-select-container {
  border-color: #1e76ce;
  border-radius: 0.5em;
}
.ng-select.ng-select-opened .ng-dropdown-panel-items {
  margin-top: 0.25rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  border: unset;
  border-radius: 0.5em;
}
.ng-select.ng-select-opened.is-invalid .ng-dropdown-panel-items {
  border-color: #c41a1a;
  border-top-color: #9b9b9b;
}
.ng-select.is-valid .ng-select-container {
  border-color: #43811a;
}
.ng-select .ng-select-container.ng-has-value .ng-placeholder {
  display: none;
}
.ng-select .ng-option {
  color: #000;
}
.ng-select .ng-option.ng-option-selected {
  color: #410073;
  font-weight: 700;
}
.ng-select .ng-option.ng-option-marked {
  background-color: #1e76ce;
  color: #fff;
  outline: 0;
  cursor: pointer;
}

.custom-control.custom-checkbox {
  padding-left: 3rem;
}
.custom-control.custom-checkbox .custom-control-label::before {
  left: -3rem;
}
.custom-control.custom-checkbox .custom-control-label::after {
  left: -3rem;
}

.form-control.is-invalid:focus {
  box-shadow: unset;
}

.line-height-sm {
  line-height: 1.5;
}

.d-ed {
  display: inline-block;
}

.d-ed-block {
  display: block;
}

@media (min-width: 0) {
  .d-xs-ed {
    display: inline-block;
  }
}
@media (min-width: 540px) {
  .d-sm-ed {
    display: inline-block;
  }
}
@media (min-width: 768px) {
  .d-md-ed {
    display: inline-block;
  }
}
@media (min-width: 960px) {
  .d-lg-ed {
    display: inline-block;
  }
}
@media (min-width: 1140px) {
  .d-xl-ed {
    display: inline-block;
  }
}
ngb-datepicker.dropdown-menu {
  border-radius: 0.5rem;
}
ngb-datepicker ngb-datepicker-navigation {
  padding: 0.5rem;
  border-bottom: 1px solid #c6c6c6;
}
ngb-datepicker .ngb-dp-content {
  padding: 0.5rem;
}
ngb-datepicker .ngb-dp-month-name {
  color: #000;
  font-size: 0.875rem;
}
ngb-datepicker .ngb-dp-arrow-btn {
  font-size: 0.875rem;
}
ngb-datepicker .ngb-dp-months {
  justify-content: center;
}
ngb-datepicker .ngb-dp-weekdays .ngb-dp-weekday {
  font-size: 0.875rem;
  font-weight: 400 !important;
  text-transform: capitalize;
}
ngb-datepicker .ngb-dp-day,
ngb-datepicker .ngb-dp-weekday,
ngb-datepicker .ngb-dp-week-number {
  margin: 0.125rem;
}
ngb-datepicker .custom-day {
  padding: 0;
  border-radius: 0.5rem;
  color: #000 !important;
  font-size: 16px;
  font-weight: 400;
}
ngb-datepicker .custom-day:hover {
  outline: 1px solid #1e76ce;
}
ngb-datepicker .custom-day:active {
  padding: 0 !important;
}
ngb-datepicker .custom-day.bg-primary {
  background: #1e76ce !important;
  color: #fff !important;
}
ngb-datepicker .custom-day.hidden {
  color: #797979 !important;
}
ngb-datepicker .custom-day.text-body-secondary {
  color: #c6c6c6 !important;
  text-decoration: none;
}

.link:focus {
  outline: 2px solid #1e76ce;
  outline-offset: 2px;
}

.ms-ed-0 {
  margin-left: 0;
}

.mx-ed-0 {
  margin-right: 0;
  margin-left: 0;
}

.my-ed-0 {
  margin-top: 0;
  margin-bottom: 0;
}

.ms-ed-1 {
  margin-left: 0.5rem;
}

.mx-ed-1 {
  margin-right: 0.5rem;
  margin-left: 0.5rem;
}

.my-ed-1 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.ms-ed-2 {
  margin-left: 1rem;
}

.mx-ed-2 {
  margin-right: 1rem;
  margin-left: 1rem;
}

.my-ed-2 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.ms-ed-3 {
  margin-left: 1.5rem;
}

.mx-ed-3 {
  margin-right: 1.5rem;
  margin-left: 1.5rem;
}

.my-ed-3 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.ms-ed-4 {
  margin-left: 2rem;
}

.mx-ed-4 {
  margin-right: 2rem;
  margin-left: 2rem;
}

.my-ed-4 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.ms-ed-5 {
  margin-left: 2.5rem;
}

.mx-ed-5 {
  margin-right: 2.5rem;
  margin-left: 2.5rem;
}

.my-ed-5 {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}

.ms-ed-6 {
  margin-left: 3rem;
}

.mx-ed-6 {
  margin-right: 3rem;
  margin-left: 3rem;
}

.my-ed-6 {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.ms-ed-8 {
  margin-left: 4rem;
}

.mx-ed-8 {
  margin-right: 4rem;
  margin-left: 4rem;
}

.my-ed-8 {
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.ms-ed-10 {
  margin-left: 5rem;
}

.mx-ed-10 {
  margin-right: 5rem;
  margin-left: 5rem;
}

.my-ed-10 {
  margin-top: 5rem;
  margin-bottom: 5rem;
}

.p-ed-0 {
  padding: 0 !important;
}

.px-ed-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.pb-ed-0 {
  padding-bottom: 0 !important;
}

.pt-ed-0 {
  padding-top: 0 !important;
}

.p-ed-1 {
  padding: 0.5rem !important;
}

.px-ed-1 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.pb-ed-1 {
  padding-bottom: 0.5rem !important;
}

.pt-ed-1 {
  padding-top: 0.5rem !important;
}

.p-ed-2 {
  padding: 1rem !important;
}

.px-ed-2 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.pb-ed-2 {
  padding-bottom: 1rem !important;
}

.pt-ed-2 {
  padding-top: 1rem !important;
}

.p-ed-3 {
  padding: 1.5rem !important;
}

.px-ed-3 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.pb-ed-3 {
  padding-bottom: 1.5rem !important;
}

.pt-ed-3 {
  padding-top: 1.5rem !important;
}

.p-ed-4 {
  padding: 2rem !important;
}

.px-ed-4 {
  padding-right: 2rem !important;
  padding-left: 2rem !important;
}

.pb-ed-4 {
  padding-bottom: 2rem !important;
}

.pt-ed-4 {
  padding-top: 2rem !important;
}

.p-ed-5 {
  padding: 2.5rem !important;
}

.px-ed-5 {
  padding-right: 2.5rem !important;
  padding-left: 2.5rem !important;
}

.pb-ed-5 {
  padding-bottom: 2.5rem !important;
}

.pt-ed-5 {
  padding-top: 2.5rem !important;
}

.p-ed-6 {
  padding: 3rem !important;
}

.px-ed-6 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.pb-ed-6 {
  padding-bottom: 3rem !important;
}

.pt-ed-6 {
  padding-top: 3rem !important;
}

.p-ed-8 {
  padding: 4rem !important;
}

.px-ed-8 {
  padding-right: 4rem !important;
  padding-left: 4rem !important;
}

.pb-ed-8 {
  padding-bottom: 4rem !important;
}

.pt-ed-8 {
  padding-top: 4rem !important;
}

.p-ed-10 {
  padding: 5rem !important;
}

.px-ed-10 {
  padding-right: 5rem !important;
  padding-left: 5rem !important;
}

.pb-ed-10 {
  padding-bottom: 5rem !important;
}

.pt-ed-10 {
  padding-top: 5rem !important;
}

.w-ed-25 {
  width: 25%;
}

.w-ed-30 {
  width: 30%;
}

.w-ed-50 {
  width: 50%;
}

.w-ed-75 {
  width: 75%;
}

.w-ed-90 {
  width: 90%;
}

.w-ed-100 {
  width: 100%;
}

.w-ed-auto {
  width: auto;
}

wl-jss-route .breadcrumbs-container:first-of-type {
  position: relative;
  width: 100%;
  z-index: 1;
}

.form-switch .form-check-input {
  width: 3em;
}

.form-check-input:hover {
  border: 2px solid #1e76ce;
}

h1, .h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  color: var(--headings-color);
}

.display-1 {
  font-size: 1.75rem;
}
@media (min-width: 768px) {
  .display-1 {
    font-size: 3.5rem;
  }
}

@media (max-width: 539.98px) {
  h1,
  .h1 {
    font-size: 2.5rem;
  }
}
@media (min-width: 768px) {
  .h1-md {
    font-size: 3rem;
  }
}

@media (min-width: 768px) {
  .h2-md {
    font-size: 2rem;
  }
}

@media (min-width: 768px) {
  .h3-md {
    font-size: 1.75rem;
  }
}

@media (min-width: 768px) {
  .h4-md {
    font-size: 1.75rem;
  }
}

h4,
.h4,
h5,
.h5,
h6,
.h6 {
  margin-bottom: 0;
  line-height: 2rem;
}

h5,
.h5,
h6,
.h6 {
  color: var(--headings-color);
  line-height: 2rem;
}

h5,
.h5 {
  font-size: 1.25rem;
}

.sidenote {
  color: #797979;
  line-height: 1.5rem;
  font-size: 0.875rem;
}

.microcopy {
  color: #000;
  line-height: 1.5rem;
  font-size: 0.875rem;
}

.caption {
  color: #797979;
  font-style: italitc;
  font-size: 0.75rem;
}

[class*=" display-"],
[class^=display-] {
  text-transform: uppercase;
}

a:not(.btn:not(.btn-link)),
.btn-link {
  position: relative;
  border-radius: 0.25rem;
  font-weight: 700;
  text-decoration: underline;
  text-underline-position: auto;
  text-underline-offset: 0.1em;
}
a:not(.btn:not(.btn-link)):focus,
.btn-link:focus {
  outline: 2px solid #1e76ce;
  outline-offset: 2px;
}

@media (max-width: 539.98px) {
  .btn-link {
    padding: 0.5rem 1rem;
    font-size: 1rem;
    font-weight: 700;
    padding: 1rem;
  }
  .btn-link:not(:disabled):not(.disabled):active {
    padding-top: 0.625rem;
    padding-bottom: 0.375rem;
  }
  .btn-link::after {
    transform: translateY(-5px);
  }
}
.rte .a:focus,
.rte a:not(.btn:not(.btn-link)):focus,
.rte .btn-link:focus,
.rte-content .a:focus,
.rte-content a:not(.btn:not(.btn-link)):focus,
.rte-content .btn-link:focus {
  outline: 2px solid #1e76ce;
  outline-offset: 2px;
}

.popover {
  line-height: 1.5rem;
}

thead {
  color: #410073;
}

.text-brand-alt {
  color: #410073;
}