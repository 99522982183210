@mixin fontello-font($base-url: '') {
  @font-face {
    font-family: 'fontello-light';
    font-style: normal;
    font-weight: normal;
    src: url('#{$base-url}/assets/webfonts/fontello/fontello-light.eot');
    src:
      url('#{$base-url}/assets/webfonts/fontello/fontello-light.eot#iefix')
        format('embedded-opentype'),
      url('#{$base-url}/assets/webfonts/fontello/fontello-light.woff2')
        format('woff2'),
      url('#{$base-url}/assets/webfonts/fontello/fontello-light.woff')
        format('woff'),
      url('#{$base-url}/assets/webfonts/fontello/fontello-light.ttf')
        format('truetype'),
      url('#{$base-url}/assets/webfonts/fontello/fontello-light.svg#fontello')
        format('svg');
  }

  @font-face {
    font-family: 'fontello-solid';
    font-style: normal;
    font-weight: normal;
    src: url('#{$base-url}/assets/webfonts/fontello/fontello-solid.eot');
    src:
      url('#{$base-url}/assets/webfonts/fontello/fontello-solid.eot#iefix')
        format('embedded-opentype'),
      url('#{$base-url}/assets/webfonts/fontello/fontello-solid.woff2')
        format('woff2'),
      url('#{$base-url}/assets/webfonts/fontello/fontello-solid.woff')
        format('woff'),
      url('#{$base-url}/assets/webfonts/fontello/fontello-solid.ttf')
        format('truetype'),
      url('#{$base-url}/assets/webfonts/fontello/fontello-solid.svg#fontello')
        format('svg');
  }
}
