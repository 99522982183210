$brand-green-10: #fcfefb;
$brand-green-50: #eff8e9;
$brand-green-100: #cde8bb;
$brand-green-200: #b5dd9a;
$brand-green-300: #94ce6c;
$brand-green-400: #7fc550;
$brand-green-500: #5fb624;
$brand-green-600: #56a621;
$brand-green-700: #43811a;
$brand-green-800: #346414;
$brand-green-900: #284c0f;

$brand-purple-10: #fbf6ff;
$brand-purple-50: #f4e6ff;
$brand-purple-100: #dcb0ff;
$brand-purple-200: #cb8aff;
$brand-purple-300: #b354ff;
$brand-purple-400: #a533ff;
$brand-purple-500: #8e00ff;
$brand-purple-600: #7b00dc;
$brand-purple-700: #6800b9;
$brand-purple-800: #540096;
$brand-purple-900: #410073;

$yellow-10: #fffefa;
$yellow-50: #fffae6;
$yellow-100: #ffeeb0;
$yellow-200: #ffe68a;
$yellow-300: #ffda54;
$yellow-400: #ffd333;
$yellow-500: #ffc800;
$yellow-600: #e8b600;
$yellow-700: #b58e00;
$yellow-800: #8c6e00;
$yellow-900: #6b5400;

$blue-10: #f7fafd;
$blue-50: #e9f1fa;
$blue-100: #b9d5f0;
$blue-200: #98c0e8;
$blue-300: #68a3de;
$blue-400: #4b91d8;
$blue-500: #1e76ce;
$blue-600: #1b6bbb;
$blue-700: #155492;
$blue-800: #114171;
$blue-900: #0d3257;

$red-10: #fdf6f6;
$red-50: #f9e8e8;
$red-100: #edb8b8;
$red-200: #e49696;
$red-300: #d76666;
$red-400: #d04848;
$red-500: #c41a1a;
$red-600: #b21818;
$red-700: #8b1212;
$red-800: #6c0e0e;
$red-900: #520b0b;

$gray-10: #fbfbfb;
$gray-50: #f3f3f3;
$gray-100: #d8d8d8;
$gray-200: #c6c6c6;
$gray-300: #ababab;
$gray-400: #9b9b9b;
$gray-500: #797979;
$gray-600: #767676;
$gray-700: #5c5c5c;
$gray-800: #484848;
$gray-900: #373737;
$gray-1000: #1e1e1e;

$white: #fff;
$black: #000;

$color-support-warning: #ffa800;
$color-focus: $blue-500;
$color-item-divider: $gray-100;
