// Creates a wrapper for floating labels, compatible with Bootstrap 4
.floating-label {
  position: relative;
  width: 100%;

  %focussed-label {
    top: $input-padding-y * $line-height-base * 0.6;
    color: $input-placeholder-color;
    font-size: 0.75rem;
    font-weight: $font-weight-bold;
  }

  label {
    // Positions the label on top of the input.
    position: absolute;
    top: $input-padding-y * $line-height-base;
    left: $input-padding-x-md;
    color: $gray-400;
    line-height: 1;
    font-size: $font-size-base;
    transition: all 0.2s;
    cursor: text;
    pointer-events: none;
    z-index: 3;
    user-select: none;

    @include media-breakpoint-up(sm) {
      left: $input-padding-x;
    }

    &.fixed-padding {
      left: $input-padding-x;
    }

    &.focussed {
      @extend %focussed-label;
    }
  }

  .form-control {
    padding: $input-padding-y $input-padding-x-md 0 $input-padding-x-md;

    box-shadow: none;
    color: $input-color;
    transition: all 0.2s;

    @include media-breakpoint-up(sm) {
      padding: $input-padding-y $input-padding-x 0 $input-padding-x;
    }

    &.has-value ~ label,
    &:focus ~ label {
      @extend %focussed-label;
    }

    &:focus {
      border-color: $input-focus-border-color;
    }

    &:hover:not(:focus) {
      border-color: $gray-500;
    }
  }
}

// Validation
.is-invalid,
.ng-dirty.ng-invalid,
.ng-touched.ng-pristine.ng-invalid {
  border-color: $danger !important;
  border-width: 2px;
  color: $danger;

  + label {
    color: $danger;
  }
}

.is-valid,
.ng-dirty.ng-valid {
  &:not([type='checkbox']):not([type='radio']) {
    border-color: $success !important;
  }
}

.is-warning {
  border-bottom-color: $warning !important;
  border-bottom-width: $input-border-validation-width;
}

.change-input {
  width: 100%;

  @include media-breakpoint-up(md) {
    width: 66.66%;
  }

  &-label {
    font-size: 1rem;
    font-weight: bold;

    @include media-breakpoint-up(md) {
      font-size: 1.2rem;
      font-weight: bolder;
    }
  }
}

.ng-select {
  background-color: $white;
  color: $gray-700;

  .ng-select-container {
    padding: $spacer * 0.9 $spacer * 3 $spacer * 0.94 0;
  }

  &.is-invalid {
    .ng-select-container {
      border-color: $danger !important;
    }
  }

  &.ng-select-focused {
    .ng-select-container {
      border-color: $input-focus-border-color !important;
    }
  }

  &.ng-select-opened {
    .ng-select-container {
      border-color: $input-focus-border-color;
      border-radius: 0.5em;
    }

    .ng-dropdown-panel-items {
      margin-top: 0.25rem;
      padding-top: $spacing-sm;
      padding-bottom: $spacing-sm;
      border: unset;
      border-radius: 0.5em;
    }

    &.is-invalid .ng-dropdown-panel-items {
      border-color: $danger;
      border-top-color: $input-border-color;
    }
  }

  &.is-valid {
    .ng-select-container {
      border-color: $success;
    }
  }

  .ng-select-container.ng-has-value {
    .ng-placeholder {
      display: none;
    }
  }

  .ng-option {
    color: $black;

    &.ng-option-selected {
      color: $brand-purple;
      font-weight: $font-weight-bold;
    }

    &.ng-option-marked {
      background-color: $color-focus;
      color: $white;
      outline: 0;
      cursor: pointer;
    }
  }
}

.custom-control.custom-checkbox {
  padding-left: 3rem;

  .custom-control-label::before {
    left: -3rem;
  }

  .custom-control-label::after {
    left: -3rem;
  }
}

.form-control.is-invalid:focus {
  box-shadow: unset;
}
