// Buttons + Forms
//
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.

@use 'sass:math';

$input-btn-padding-y: $spacer !default;
$input-btn-padding-x: $spacer * 2 !default;
$input-btn-font-family: null !default;
$input-btn-font-size: $font-size-lg !default;
$input-btn-line-height: 1.5 !default;

$input-btn-focus-width: 0.125rem !default;
$input-btn-focus-color: $color-focus;
$input-btn-focus-box-shadow:
  0 0 0 $input-btn-focus-width $white,
  0 0 0 $input-btn-focus-width * 2 $input-btn-focus-color !default;

$input-btn-padding-y-sm: math.div($spacer, 3) !default;
$input-btn-padding-x-sm: math.div($spacer, 3) !default;
$input-btn-font-size-sm: $font-size-sm !default;
$input-btn-line-height-sm: $line-height-sm !default;

$input-btn-padding-y-lg: $spacer * 1.6725 !default;
$input-btn-padding-x-lg: $spacer * 1.6725 !default;
$input-btn-font-size-lg: $font-size-lg !default;
$input-btn-line-height-lg: $line-height-lg !default;

$input-btn-border-width: $border-width !default;
