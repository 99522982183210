$form-check-input-width: 1.875rem;
$form-check-min-height: $font-size-base * $line-height-base;
$form-check-padding-start: 3rem;
$form-check-margin-bottom: $spacing-xs;
$form-check-label-color: null;
$form-check-label-cursor: null;
$form-check-transition: null;

$form-check-input-active-filter: brightness(90%);

$form-check-input-bg: $input-bg;
$form-check-input-border: $input-border-width solid $gray-400;
$form-check-input-border-radius: $border-radius-sm;
$form-check-radio-border-radius: 50%;
$form-check-input-focus-border: $input-focus-border-color;
$form-check-input-focus-box-shadow: $input-btn-focus-box-shadow;

$form-check-input-checked-color: $component-active-bg;
$form-check-input-checked-bg-color: $white;
$form-check-input-checked-border-color: $gray-400;
$form-check-input-checked-bg-image: url("data:image/svg+xml,<?xml version='1.0' encoding='UTF-8' standalone='no'?><svg width='24' height='24' viewBox='0 0 24 24' fill='none' version='1.1' id='svg4' xmlns='http://www.w3.org/2000/svg' xmlns:svg='http://www.w3.org/2000/svg'><defs id='defs8' /><path d='m 20.607748,5.6879697 c 0.51799,0.478125 0.51799,1.3148395 0,1.7929645 L 10.407791,17.680917 c -0.4781257,0.51799 -1.3148402,0.51799 -1.7929652,0 L 3.5148216,12.580934 c -0.5179688,-0.478125 -0.5179688,-1.314839 0,-1.792964 0.4781292,-0.517965 1.3148437,-0.517965 1.7929687,0 l 4.2234375,4.183572 9.2836152,-9.2835723 c 0.478125,-0.5179688 1.314865,-0.5179688 1.792905,0 z' fill='#{$form-check-input-checked-color}' style='stroke-width:0.85' /></svg>");
$form-check-radio-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'><circle r='2.3' fill='#{$form-check-input-checked-color}'/></svg>");
$form-check-toggle-checked-bg-image: url("data:image/svg+xml,<?xml version='1.0' encoding='UTF-8' standalone='no'?><svg width='24' height='24' viewBox='-4 -4 28 28' fill='none' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:svg='http://www.w3.org/2000/svg'><path d='m 20.607748,5.6879697 c 0.51799,0.478125 0.51799,1.3148395 0,1.7929645 L 10.407791,17.680917 c -0.4781257,0.51799 -1.3148402,0.51799 -1.7929652,0 L 3.5148216,12.580934 c -0.5179688,-0.478125 -0.5179688,-1.314839 0,-1.792964 0.4781292,-0.517965 1.3148437,-0.517965 1.7929687,0 l 4.2234375,4.183572 9.2836152,-9.2835723 c 0.478125,-0.5179688 1.314865,-0.5179688 1.792905,0 z' fill='#{$form-check-input-checked-color}' style='stroke-width:0.85' /></svg>");

$form-check-input-indeterminate-color: $form-check-input-checked-color;
$form-check-input-indeterminate-bg-color: $component-active-bg;
$form-check-input-indeterminate-border-color: $form-check-input-indeterminate-bg-color;
$form-check-input-indeterminate-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'><path stroke='#{$form-check-input-indeterminate-color}' d='M0 2h4'/></svg>");

$form-check-input-disabled-opacity: 0.5;
$form-check-label-disabled-opacity: $form-check-input-disabled-opacity;
$form-check-btn-check-disabled-opacity: $btn-disabled-opacity;
