@use 'sass:map';

.accordion-header {
  border-radius: inherit;
}

.accordion-button {
  border-radius: inherit;

  &:not(.collapsed):focus {
    box-shadow: inset 0 calc(-1 * var(--accordion-border-width)) 0
      var(--accordion-border-color);
  }
}

@mixin themed-accordion-header($bg-color, $color, $icon, $icon-active) {
  border: 0;

  &:not(:last-of-type) {
    border-bottom: 1px solid $border-color;
  }

  &:last-of-type {
    .accordion-body {
      border-bottom: 1px solid $border-color;
    }
  }

  > .accordion-header > .accordion-button {
    color: $color;
  }

  .accordion-header {
    background-color: $bg-color;
    color: $color;

    > .accordion-button {
      &::after {
        background-image: $icon;
      }

      &:not(.collapsed)::after {
        background-image: $icon-active;
      }
    }
  }

  .accordion-body {
    border-right: 1px solid $border-color;
    border-left: 1px solid $border-color;
  }

  &:has(.accordion-header):has(.accordion-button:not(.collapsed)) {
    border-bottom: 0;
  }
}

.accordion-item {
  &--brand-header {
    @include themed-accordion-header(
      $brand,
      $white,
      $accordion-button-icon-light,
      $accordion-button-active-icon-light
    );
  }

  &--dark-header {
    @include themed-accordion-header(
      $dark,
      $white,
      $accordion-button-icon-light,
      $accordion-button-active-icon-light
    );
  }

  // use with <ngb-panel cardClass="accordion-item--light-header">
  &--light-header {
    @include themed-accordion-header(
      $white,
      $dark,
      $accordion-button-icon,
      $accordion-button-active-icon
    );
  }

  // extra class for a dark variant of the accordion-header, use with <ngb-panel cardClass="accordion-item--dark-header">
  &--transparent {
    background-color: transparent;
    border: 0;
    border-bottom: 1px solid $border-color;
    border-radius: 0 !important;
  }

  &--collapse-content-padding {
    --accordion-body-padding-x: 0;
    --accordion-body-padding-y: 0;

    .accordion-body {
      border-width: 0 !important;
    }
  }

  &:not(:last-of-type) {
    .accordion-item.nested-accordion {
      &:last-of-type {
        --accordion-border-radius: 0;
      }
    }
  }

  &:last-of-type {
    .accordion-item.nested-accordion {
      .accordion-button {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }

      &:last-of-type {
        --accordion-inner-border-radius: #{$accordion-border-radius};
      }
    }
  }
}

// use with <ngb-panel cardClass="nested-accordion"> (e.g. with above classes to create aom-cost-breakdown styling) [use after(!) .accordion-item--light-header]
.nested-accordion {
  --accordion-inner-border-radius: 0;

  &:not(:first-of-type) {
    border-top: 1px solid $border-color !important;
  }

  .accordion-header {
    background-color: $gray-200 !important;
  }

  .accordion-body {
    border: 0 !important;
  }
}

// https://caniuse.com/?search=%3Ahas, users with non-supporting browsers will just see rounded bottom borders :)
.accordion-header:has(.accordion-button:not(.collapsed)) {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.accordion--transparent {
  &,
  .accordion-header,
  .accordion-item,
  .accordion-button {
    background-color: transparent;
    border: 0;
    box-shadow: none !important;
  }

  .accordion-button:focus {
    outline: none !important;
  }
}

.accordion-item--small .accordion-body {
  padding: 0 map.get($spacers, 2) map.get($spacers, 2);
}
