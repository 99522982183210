// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.
$grid-breakpoints: (
  xs: 0,
  sm: $mobile-breakpoint,
  md: $tablet-portrait-breakpoint,
  lg: $tablet-landscape-breakpoint,
  xl: $desktop-breakpoint,
) !default;

@include _assert-ascending($grid-breakpoints, '$grid-breakpoints');
@include _assert-starts-at-zero($grid-breakpoints, '$grid-breakpoints');

// Set the number of columns and specify the width of the gutters.
$grid-columns: $grid-columns !default;
$grid-gutter-width: $grid-gutter-width !default;
$grid-row-columns: $grid-row-columns !default;

// Define the maximum width of `.container` for different screen sizes.
$container-max-widths: (
  sm: $mobile-container-max-width,
  md: $tablet-portrait-container-max-width,
  lg: $tablet-landscape-container-max-width,
  xl: $desktop-container-max-width,
) !default;

@include _assert-ascending($container-max-widths, '$container-max-widths');
