@mixin open-sans-font($base-url: '') {
  @font-face {
    font-family: OpenSansSubset;
    font-style: normal;
    font-weight: 400;
    src:
      url('#{$base-url}/assets/webfonts/open-sans/OpenSans-Regular-subset.woff2')
        format('woff2'),
      url('#{$base-url}/assets/webfonts/open-sans/OpenSans-Regular-subset.zopfli.woff')
        format('woff');
    unicode-range:
      U+20-23, U+26-29, U+2C-2E, U+30-3A, U+3E, U+3F, U+41-50, U+52-57, U+5A,
      U+61-7A, U+A0, U+A9, U+E9, U+EB, U+EF, U+2018, U+2019, U+2026, U+20AC,
      U+2122;
  }

  @font-face {
    font-family: 'OpenSans';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src:
      url('#{$base-url}/assets/webfonts/open-sans/OpenSans-Light.woff2')
        format('woff2'),
      url('#{$base-url}/assets/webfonts/open-sans/OpenSans-Light.woff')
        format('woff');
  }

  @font-face {
    font-family: 'OpenSans';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src:
      url('#{$base-url}/assets/webfonts/open-sans/OpenSans-Regular.woff2')
        format('woff2'),
      url('#{$base-url}/assets/webfonts/open-sans/OpenSans-Regular.woff')
        format('woff');
  }

  @font-face {
    font-family: 'OpenSans';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src:
      url('#{$base-url}/assets/webfonts/open-sans/OpenSans-Bold.woff2')
        format('woff2'),
      url('#{$base-url}/assets/webfonts/open-sans/OpenSans-Bold.woff')
        format('woff');
  }

  @font-face {
    font-family: 'OpenSans';
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src:
      url('#{$base-url}/assets/webfonts/open-sans/OpenSans-ExtraBold.woff2')
        format('woff2'),
      url('#{$base-url}/assets/webfonts/open-sans/OpenSans-ExtraBold.woff')
        format('woff');
  }

  .fonts-stage-1 body {
    font-family: OpenSansSubset, sans-serif;
    font-style: normal;
    font-weight: 400;
  }

  .fonts-stage-2 body {
    font-family: OpenSans, sans-serif;
  }
}
