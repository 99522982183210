$fontello-icons: (
  'check': (
    '\e800',
    currentColor,
    before,
  ),
  'check-success': (
    '\e800',
    $success,
    before,
  ),
  'check-white': (
    '\e800',
    $white,
    before,
  ),
  'underlined-links-angle-right': (
    '\41',
    $primary,
    after,
  ),
  'underlined-links-grey-line-blue-angle-right': (
    '\41',
    $primary,
    after,
  ),
  'cross': (
    '\54',
    $danger,
    before,
  ),
  'exclamation-circle': (
    '\0045',
    currentColor,
    before,
  ),
);

$icon-font-family: 'fontello-solid';
