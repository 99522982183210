@use 'sass:map';

@import '../focus-ring';

@mixin ed-button($theme-map) {
  @include ed-button-variant(
    map.get($theme-map, 'background'),
    map.get($theme-map, 'background:hover'),
    map.get($theme-map, 'background:disabled'),
    map.get($theme-map, 'border'),
    map.get($theme-map, 'focus-ring'),
    map.get($theme-map, 'text-color'),
    map.get($theme-map, 'text-color:hover'),
    map.get($theme-map, 'text-decoration'),
    map.get($theme-map, 'icon')
  );
}

@mixin ed-button-variant(
  $background,
  $hover-background,
  $disabled-background-override,
  $border-override,
  $focus-ring-override,
  $text-color-override,
  $text-color-hover-override,
  $text-decoration,
  $icon
) {
  $text-color: if(
    $text-color-override,
    $text-color-override,
    color-contrast($background)
  );
  $text-color-hover: if(
    $text-color-hover-override,
    $text-color-hover-override,
    color-contrast($hover-background)
  );
  $border: if($border-override, $border-override, transparent);
  $focus-ring: if($focus-ring-override, $focus-ring-override, $color-focus);
  $disabled-background: if(
    $disabled-background-override,
    $disabled-background-override,
    $gray-50
  );
  $disabled-border: if($border-override, $gray-50, transparent);

  @include focus-ring($border, -0.125rem, 0.125rem);

  background-color: $background;
  box-shadow: none;

  color: $text-color;
  line-height: 1.75;
  font-size: $btn-font-size;
  text-decoration: $text-decoration;

  @if $icon {
    display: inline-flex;
    padding-right: $spacing-md;
    justify-content: center;

    &::after {
      content: '';
      display: inline-flex;
      width: 22px;
      height: 24px;
      margin-left: $spacing-xs;
      flex: 0 0 22px;
      background-color: $text-color;
      transform: translateY(1px);
      mask-image: $icon;
      mask-size: contain;
      align-self: center;
    }
  }

  @include media-breakpoint-down(sm) {
    @include btn-sm;
    padding: $spacing-sm;

    &::after {
      transform: translateY(1px);
    }
  }

  &:focus,
  &.focus {
    @include focus-ring($focus-ring);

    background-color: $background;
    box-shadow: none;
    color: $text-color;
    text-decoration: $text-decoration;
  }

  &:focus:not(:focus-visible) {
    @include focus-ring($focus-ring);
  }

  &:hover,
  &:not(:disabled):hover:active,
  &.hover {
    background-color: $hover-background;
    color: $text-color-hover;
    text-decoration: $text-decoration;
  }

  &.disabled,
  &:disabled {
    @include focus-ring($disabled-border, 0, 0.125rem);

    background-color: $disabled-background;
    color: $gray-500;
    text-decoration: none;

    @if $icon {
      &::after {
        background-color: $gray-500;
      }
    }
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    padding-top: calc($btn-padding-y + $btn-active-text-displacement);
    padding-bottom: calc($btn-padding-y - $btn-active-text-displacement);
    background-color: $hover-background;
    box-shadow: $btn-box-shadow;
    color: $text-color-hover;
    outline: none;
    text-decoration: $text-decoration;
  }
}

@mixin btn {
  @include button-size(
    $btn-padding-y,
    $btn-padding-x,
    $btn-font-size,
    $btn-border-radius
  );
  @include transition($btn-transition);
  display: inline-block;
  vertical-align: middle;
  background-color: transparent;
  border: $btn-border-width solid transparent;

  border-radius: var(--btn-border-radius);
  color: $body-color;
  line-height: 1.5;
  font-family: $btn-font-family;
  font-weight: $btn-font-weight;
  text-align: center;
  text-decoration: if($link-decoration == none, null, none);
  white-space: $btn-white-space;
  user-select: none;

  &:hover {
    color: $body-color;
    text-decoration: none;
  }

  &:focus,
  &.focus {
    outline: 0;
  }
}

@mixin btn-sm {
  padding: $spacing-xs $spacing-sm;
  font-size: $font-size-base;
  font-weight: $font-weight-bold;

  &:not(:disabled):not(.disabled):active {
    padding-top: calc($spacing-xs + $btn-active-text-displacement);
    padding-bottom: calc($spacing-xs - $btn-active-text-displacement);
  }

  &::after {
    transform: translateY(-5px);
  }
}

@mixin ed-header-button {
  @include btn;
  @include ed-button(map.get($button-theme-colors, 'navigation'));
  @include btn-sm;
  display: inline-flex;

  line-height: $line-height-lg;
  gap: 10px;
  align-items: center;
  justify-content: center;
  text-wrap: nowrap;

  &:hover {
    outline-color: $blue-700;
  }

  fa-icon {
    font-size: 0.75rem;
  }

  &::after {
    content: none;
    display: none;
  }
}
