.btn,
.nav-link,
.dropdown-item,
.page-link {
  text-decoration: none;
}

.link,
.link:not([href]):not([tabindex]) {
  background-color: transparent;
  color: $link-color;
  text-decoration: $link-decoration;

  &:hover {
    color: $link-hover-color;
    text-decoration: $link-hover-decoration;
    cursor: pointer;
  }
}

a:focus {
  z-index: 10;
}
