@use 'sass:list';

/**
 * We need `:root` (or some type of element + class/pseudo-class/attribute selector) to get
 * the CSS specificity high enough to be able to override styles set in the rich-text-wrapper
 * component (because the ::ng-deep pseudo-class selector" is used there).
 * Using `app-root[ng-version]` instead of `:root` works too, or `html[lang]`.
 */
:root {
  [class^='rich-text-icon-']::before,
  [class*=' rich-text-icon-']::before,
  [class^='rich-text-list-icon-'] li::before,
  [class*=' rich-text-list-icon-'] li::before,
  [class^='rich-text-icon-']::after,
  [class*=' rich-text-icon-']::after,
  [class^='rich-text-list-icon-'] li::after,
  [class*=' rich-text-list-icon-'] li::after {
    display: inline-block;
    width: 1em;
    margin-right: 0.2em;

    /* Animation center compensation - margins should be symmetric */
    margin-left: 0.2em;

    // As generated by fontello in /styles/generated/fontello.css, extended with the option to support lists.
    font-family: $icon-font-family;
    font-style: normal;
    font-weight: normal;

    /* Font smoothing. That was taken from TWBS */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    /* For safety - reset parent styles, that can break glyph codes*/
    font-variant: normal;
    text-align: center;
    text-decoration: inherit;
    text-transform: none;
    speak: never;
  }

  [class^='rich-text-list-icon-'],
  [class*=' rich-text-list-icon-'] {
    padding-left: 0;
    list-style-type: none;

    li {
      display: block;
      position: relative;
      margin-left: $spacer * 2;

      &::before {
        position: absolute;
        left: -($spacer * 2);
      }
    }
  }

  [class^='rich-text-list-icon-check'],
  [class*=' rich-text-list-icon-check'] {
    margin-left: 0.5rem;

    // This combination of positive padding and negative text-indent makes
    // it so that the second line of text to align with the first one
    padding-left: $list-indentation;
    text-indent: -$list-indentation;

    li {
      display: block;
      margin-left: 0;

      &::before {
        position: relative;
        left: 0;
      }
    }
  }

  @each $name, $icon, $placement in $fontello-icons {
    $glyph: list.nth($icon, 1);
    $color: list.nth($icon, 2);
    $placement: list.nth($icon, 3);

    @if $placement == before {
      .rich-text-list-icon-#{$name} li::before,
      .rich-text-icon-#{$name}::before {
        content: $glyph;
        color: $color;
      }
    } @else if $placement == after {
      .rich-text-list-icon-#{$name} li::after,
      .rich-text-icon-#{$name}::after {
        content: $glyph;
        color: $color;
      }
    } @else {
      @error "For rich-text-icon placement, please choose 'before' or 'after'";
    }
  }

  ul,
  ol {
    &.rich-text-list-icon-underlined-links-angle-right,
    &.rich-text-list-icon-underlined-links-grey-line-blue-angle-right {
      padding: 0;
      list-style-type: none;

      > li {
        /* normal lists in the rich-text editor have a margin-bottom of 1em (to comply with designs),
           it's removed here because the spacing is added as padding within the <a> tag
           (so that the space around the link will also be clickable) */
        margin-bottom: 0;

        margin-left: 0;
        border-bottom: 1px solid currentColor;

        // always keep the text in this list aligned left, even if the surrounding content is centered
        text-align: left !important;

        &::after {
          display: flex;
          position: absolute;
          top: 0;
          right: 0;
          height: 100%;
          justify-content: center;
          align-items: center;
          margin: 0;
          color: currentColor;
          font-size: 2em;
          pointer-events: none;
        }

        a {
          display: inline-block;
          /**
           * A fixed width is being placed on the <a> tag in certain situations by the rich-text
           * editor, important is used here to always overwrite it.
           */
          width: 100% !important;
          padding: 1em 0;
          color: currentColor;
          text-decoration: none;
          cursor: pointer;
        }
      }

      &.rich-text-list-icon-underlined-links-angle-right > li:hover a {
        text-decoration: underline;
      }

      &.rich-text-list-icon-underlined-links-grey-line-blue-angle-right > li {
        border-color: $gray-300;

        &:hover a {
          color: $primary;
        }

        &::after {
          color: $primary;
        }
      }
    }

    // The :not(...) will make sure nested lists will still have indentation
    // prettier-ignore
    &.rich-text-list-remove-indentation:not(ul ul):not(ol ul):not(ul ol):not(ol ol) {
      // Add padding so multiple lines of text will still be aligned (and not continue below the bullet-point)
      padding-left: $list-indentation;
      // Remove indentation
      list-style-position: inside;
      // Pull all the text back (including the bullet-points) so it starts at the initial position (without indentation)
      text-indent: -$list-indentation;
    }
  }
}
