.modal {
  --modal-margin: 1rem;
}

.mijn {
  .card {
    &--fixed-overlay {
      @include media-breakpoint-down(sm) {
        position: fixed;
        top: 0;
        left: 0;
        overflow-y: scroll;
        width: 100%;
        height: 100vh;
        border: 0;
        border-radius: 0 !important;
        z-index: 1100; // more than header nav
      }
    }
  }
}

.modal-header .close:focus {
  outline: 0;
}

.modal--left {
  .modal-dialog {
    height: 100%;
    max-height: 100%;
    margin: 0;

    .modal-content {
      height: 100%;
      max-height: 100%;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;

      @include media-breakpoint-down(sm) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }
}

.modal--right {
  .modal-dialog {
    height: 100%;
    max-height: 100%;
    margin: 0 0 0 auto;

    .modal-content {
      height: 100%;
      max-height: 100%;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
}

.modal-backdrop--hidden {
  overflow-y: auto !important;
  padding-right: 0 !important;
}

.modal.fade.modal--bottom .modal-dialog {
  transform: translate(0, 50px);
}

.modal.show.modal--bottom .modal-dialog {
  transform: none;
}

.modal--bottom {
  display: flex !important;
  align-items: flex-end;
  justify-content: center;
  overflow: hidden;

  .modal-dialog {
    margin-bottom: 2rem;

    @include media-breakpoint-down(lg) {
      width: 100%;
      max-width: 100%;
      margin-bottom: 0;

      .modal-content {
        border-radius: 0;
      }

      .modal-header {
        border-radius: 0;
      }
    }
  }

  .modal-shadow .modal-content {
    box-shadow: $box-shadow-lg;
  }
}
