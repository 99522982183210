// Base
$font-size-base: 1rem; // assuming browser default 16px
$line-height-base: 2;

// Fonts
$font-family-body: OpenSans, sans-serif;
$font-family-brand: FilsonSoft, Tahoma, Verdana, sans-serif;

// Font weights
$font-weight-normal: 400;
$font-weight-medium: 600;
$font-weight-bold: 700;
$font-weight-extra-bold: 800;

// Heading
$font-weight-heading: $font-weight-extra-bold;
$line-height-heading: 1.25;
$line-height-display: 0.89285;
$margin-bottom-heading: 0.5rem;
$font-size-heading-small: $font-size-base * 1.25; // 20px
$font-size-heading-medium: $font-size-base * 1.75; // 28px
$font-size-heading-large: $font-size-base * 2; // 32px;
$font-size-heading-xl-desktop: $font-size-base * 3; // 48px;
$font-size-heading-xl-mobile: $font-size-base * 2.5; // 40px;
$font-size-heading-brand-desktop: $font-size-base * 3.5; // 56px
$font-size-heading-brand-mobile: $font-size-base * 2.5; // 40px

// Intro
$font-intro-color: $black;
$font-weight-intro: $font-weight-normal;
$font-size-intro: $font-size-base;
$line-height-intro: 2;
$margin-bottom-intro: 0.5rem;

// Body
$font-body-color: $black;
$font-weight-body: 400;
$line-height-body: 2rem; // 32px;
$font-size-body-copy: $font-size-base; // 16px;
$font-size-body-intro: $font-size-base * 1.25; // 20px
$font-size-caption: $font-size-base * 0.75; // 12px
$font-style-caption: italitc;
$font-color-caption: $gray-500;
$font-size-microcopy: $font-size-base * 0.875; // 14px
$line-height-microcopy: 1.5rem; // 24px

// Links
$link-color: $blue-500;
$link-hover-color: $blue-500;
$link-decoration: underline;
$link-underline-offset: 0.1em;
$link-focus-outline-color: rgba($link-hover-color, 0.48);
$link-focus-outline-offset: 6px;

// Rich text
$margin-top-headings: 1rem;
$margin-top-content-elements: 0.5rem;

// Lists
$list-indentation: 1.4rem;
$list-item-spacing: 0.5rem;
$list-spacing-between-marker-and-content: 0.5rem;

:root {
  --font-intro-color: #{$font-intro-color};
}
