:not(ngb-carousel, ngb-pagination) {
  > ul,
  > ol {
    &:not(.list-no-default-spacing) > li {
      &:not(:last-of-type):not(.list-group-item) {
        margin-bottom: $list-item-spacing;
      }

      // Any <ul>/<ol> placed directly within a <li> is missing the spacing between itself and the text in that <li>
      > ul,
      > ol {
        margin-top: $list-item-spacing;
      }
    }
  }

  @supports (counter-increment: any) {
    > ol:not(.list-no-default-spacing) {
      // padding is reduced because the new :before marker is placed next to
      // this padding while the initial ::marker was placed before it.
      padding-left: $list-indentation;

      > li {
        counter-increment: listItemCounter;
        // We remove the default markers (numbers)
        list-style-type: none;

        // And re add them with :before so that we can add some margin on the right
        &::before {
          content: counter(listItemCounter) '.';
          margin-right: $list-spacing-between-marker-and-content;
        }
      }
    }
  }
}

ul.reset-list-styles {
  margin: 0;
  padding: 0;
  list-style: none;

  li {
    margin: 0 !important;
    padding: 0;
    list-style: none;
  }
}

.list-unstyled--no-spacing {
  > li {
    margin-bottom: 0 !important;
  }
}
