@each $index, $value in $spacers {
  .p-ed-#{$index} {
    padding: $value !important;
  }

  .px-ed-#{$index} {
    padding: {
      right: $value !important;
      left: $value !important;
    }
  }

  .pb-ed-#{$index} {
    padding-bottom: $value !important;
  }

  .pt-ed-#{$index} {
    padding-top: $value !important;
  }
}
