// Typography
//
// Font, line-height, and color for body text, headings, and more.

// stylelint-disable value-keyword-case
$font-family-sans-serif: $font-family-body !default;
$font-family-serif: $font-family-brand !default;
$font-family-base: $font-family-sans-serif !default;
// stylelint-enable value-keyword-case

$font-size-base: $font-size-base !default; // Assumes the browser default, typically `16px`
$font-size-lg: $font-size-body-intro !default;
$font-size-sm: $font-size-caption !default;

$font-weight-light: $font-weight-normal !default;
$font-weight-bolder: $font-weight-bold !default;

$font-weight-base: $font-weight-normal !default;
$line-height-base: $line-height-base !default;

$h1-font-size: $font-size-heading-xl-desktop !default;
$h2-font-size: $font-size-heading-large !default;
$h3-font-size: $font-size-heading-medium !default;
$h4-font-size: $font-size-heading-small !default;
$h5-font-size: $font-size-base !default;
$h6-font-size: $font-size-base !default;

// scss-docs-start headings-variables
$headings-font-weight: $font-weight-heading !default;
$headings-margin-bottom: $spacing-xs !default;
$headings-line-height: $line-height-heading !default;
$headings-color: $brand-purple-900;
// scss-docs-end headings-variables

// scss-docs-start display-headings
$display-mobile-font-sizes: (
  1: $font-size-heading-medium,
);
$display-font-sizes: (
  1: $font-size-heading-brand-desktop,
);

$display-font-family: $font-family-brand !default;
$display-font-weight: $font-weight-heading !default;
$display-line-height: $line-height-display !default;
// scss-docs-end display-headings

// scss-docs-start type-variables
$lead-font-size: $font-size-body-intro !default;
$lead-font-weight: $font-weight-body !default;

$small-font-size: $font-size-microcopy !default;

$body-secondary-color: $gray-500 !default;

$list-inline-padding: $spacer * 0.5 !default;

$mark-bg: $yellow-300 !default;
// scss-docs-end type-variables

// Links
//
// Style anchor elements.
$link-color: $link-color !default;
$link-hover-color: $link-hover-color !default;
$link-decoration: $link-decoration !default;
$link-hover-decoration: $link-decoration !default;

// Body
//
// Settings for the `<body>` element
$body-color: $font-body-color;

:root {
  --headings-color: #{$headings-color};
}

%intro {
  margin-bottom: $margin-bottom-intro;
  color: var(--font-intro-color);
  line-height: $line-height-intro;
  font-size: $font-size-intro;
  font-weight: $font-weight-intro;
}
